import React, { useEffect, useState } from 'react';

import get from 'lodash/get';

import { HorizontalRule, NavBarList } from '@spotify-internal/encore-web';
import * as Styled from './TabbedCuratedList.styled';
import { ContentCardData } from '../curated-list/ContentCuratedList';
/**
 * TabbedCuratedList Component
 * @param {Object} props - The list of items to be rendered
 * @returns {ReactElement}
 */
const TabbedCuratedList = (props: TabbedCuratedListProps) => {
  const { listsCollection } = props.data;
  const cards: ContentCardData[] = get(listsCollection, 'items', []);
  const tabNames = cards.map(card => card.name);

  const [activeTabIndex, setActiveTab] = useState(0);

  const [activeCards, setActiveCards] = useState(cards[activeTabIndex]);
  useEffect(() => {
    setActiveCards(cards[activeTabIndex]);
  }, [activeTabIndex]);

  return (
    <Styled.Container>
      <Styled.TabBar
        list={
          <nav aria-label="Main">
            <NavBarList>
              {tabNames.map((item, index) => (
                <Styled.StyledNavBarListItem
                  key={`${item}-index`}
                  id={`${item.toLowerCase()}Link`}
                  label={item}
                  active={index === activeTabIndex}
                  href={`#${item}`}
                  onClick={() => setActiveTab(index)}
                />
              ))}
            </NavBarList>
            <HorizontalRule />
          </nav>
        }
      />
      <Styled.CuratedList
        key={`${activeCards.name}-${activeTabIndex}`}
        data={activeCards}
      />
    </Styled.Container>
  );
};

interface TabbedCuratedListProps {
  data: {
    listsCollection?: {
      items: {
        cardsCollection?: ContentCardData[];
        name: string;
        title: string | undefined;
      }[];
    }[];
    name: string;
  };
}

export default TabbedCuratedList;
