import { css } from 'styled-components';
import { getScaledImageUrl } from 'utils/get-scaled-image-url';
import {
  AD_FORMAT_IMG_DIMENSIONS,
  GET_STARTED_IMG_DIMENSIONS,
  IMG_DIMENSIONS,
} from 'constants/image-attributes';
import { gray15 } from '@spotify-internal/encore-web';

/* Typography */
export const fontFamily = 'Spotify Mix Text, Helvetica, Arial, sans-serif';
export const fontWeights = {
  light: 300, // SpotifyMix-Light
  normal_p: 500, // SpotifyMixMedium
  normal: 400, // SpotifyMixRegular
  bold: 700, // SpotifyMix-Bold
  extra_bold: 800, // SpotifyMix-Extrabold
  black: 900, // SpotifyMix-Black
};

/* Breakpoints */

/* Used as @media min-width: */
export const minBreakpoints = {
  xs: 320,
  sm: 641,
  md: 769,
  lg: 1025,
  ml: 1280, // Breakpoint used to avoid tight text links in large Global Nav
  xl: 1441,
  xxl: 1681,
};

/* Used as @media max-width: */
export const maxBreakpoints = {
  xs: 640,
  sm: 768,
  md: 1024,
  lg: 1440,
  ml: 1279, // Breakpoint used to avoid tight text links in large Global Nav
  xl: 1520,
};

/* Defined ratios percentages */
export const ratioPercentage = {
  oneOne: 100,
  threeTwo: 66.66,
  fourThree: 75,
  eightFive: 62.5,
  sixteenNine: 56.25,
  fiveFour: 80,
};

/* Animations */
export const animations = {
  defaultTransition: '0.3s ease-in-out',
};

export const visibilityThreshold = {
  oneThird: 0.33,
  oneHalf: 0.5,
  threeFourth: 0.75,
};

export const navHeight = {
  smToLg: 7.1,
  mlUp: 7.1,
};

export const sideNavHeight = {
  sm: 4.8,
};

export const cssGlobals = {
  antiFlicker: 'anti-flicker',
};

export const cssFragments = {
  defaultFocusState: css`
    &:focus {
      /* Fallback for browsers that don't support :focus-visible */
      outline: 1px dotted ${gray15};
      outline: -webkit-focus-ring-color auto 1px;
    }

    &:focus:not(:focus-visible) {
      /* Remove the focus indicator on mouse-focus for browsers that do support :focus-visible */
      outline: none;
    }

    &:focus-visible {
      /* Focus indicator style for keyboard-focus on browsers that do support :focus-visible */
      outline: 1px dotted ${gray15};
      outline: -webkit-focus-ring-color auto 1px;
    }
  `,
  backgroundScaledImage: css`
    background-image: ${props =>
      `image-set(${IMG_DIMENSIONS.map(
        dimension =>
          `url(${getScaledImageUrl(props['data-src'], dimension)})  ${
            dimension.d
          }`,
      ).toString()})`};
    background-image: ${props =>
      `url(${getScaledImageUrl(
        props['data-src'],
        IMG_DIMENSIONS[0],
      )})`}; /* Firefox fallback due to image-set not supported */
  `,
  backgroundScaledImageGetStarted: css`
    background-image: ${props =>
      `image-set(${GET_STARTED_IMG_DIMENSIONS.map(
        dimension =>
          `url(${getScaledImageUrl(props['data-src'], dimension)})  ${
            dimension.d
          }`,
      ).toString()})`};
    background-image: ${props =>
      `url(${getScaledImageUrl(
        props['data-src'],
        GET_STARTED_IMG_DIMENSIONS[0],
      )})`}; /* Firefox fallback due to image-set not supported */
  `,
  backgroundScaledImageAdFormats: css`
    background-image: ${props =>
      `image-set(${AD_FORMAT_IMG_DIMENSIONS.map(
        dimension =>
          `url(${getScaledImageUrl(props['data-src'], dimension)})  ${
            dimension.d
          }`,
      ).toString()})`};
    background-image: ${props =>
      `url(${getScaledImageUrl(
        props['data-src'],
        AD_FORMAT_IMG_DIMENSIONS[0],
      )})`}; /* Firefox fallback due to image-set not supported */
  `,
};
