import { useEffect, useState } from 'react';

const useIsMobile = () => {
  const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  const [isMobile, setIsMobile] = useState();

  useEffect(() => {
    const isMobileOnMount = regex.test(navigator.userAgent);

    setIsMobile(isMobileOnMount);
  }, []);

  return isMobile;
};

export default useIsMobile;
