import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes, { any } from 'prop-types';

import get from 'lodash/get';

import { ICONS } from 'constants/icons';
import { getLinkProps } from 'utils/get-link-props';
import { MODULES_MULTI_CTA } from 'utils/google-tag-manager';

import { useComponentSize } from 'utils/use-component-size';
import classNames from 'classnames';
import mParticle from '@mparticle/web-sdk';
import Link from 'next/link';
import * as Styled from './Navigation.styled';

/**
 * List with the type of components for SubNav
 */
const COMPONENTS = {
  DROPDOWN: 'Dropdown',
};

/**
 * Creates a DropdownMenu instance for SubNav
 * @param {object} item Navigation Item data
 * @param {string} locale Current Locale
 * @param {function} onClick On Click handler for links
 * @param {number} listHeight - The list height
 * @param level
 * @param {function} onLayout - The layout change listener
 * @param {boolean} isMobile - boolean to determine the layout of the submenu
 * @param {boolean} isCurrentPath - determines the color/styling of the text link
 * @param className
 */
const Dropdown = ({
  item,
  locale,
  onClick,
  listHeight,
  level,
  onLayout = () => {},
  isMobile,
  isCurrentPath,
  className,
}) => {
  const [subNavFocused, setSubNavFocused] = useState(false);
  const navUrl =
    get(item, 'rootLink.slug', null) || get(item, 'rootLink.url', '');
  const { href, asLink } = getLinkProps(navUrl, locale);
  const containerRef = useRef(null);
  const { height } = useComponentSize(containerRef);

  const onMenuClick = useCallback(() => {
    mParticle.logEvent('action', mParticle.EventType.Other, {
      action_text: 'menu click',
      action_location: MODULES_MULTI_CTA.dropdown,
    });
  }, [href, asLink]);

  useEffect(() => {
    if (height !== 0 && typeof height === 'number') {
      onLayout(height);
    }
  }, [height]);

  const SubNavComponent = isMobile ? Styled.SubNav : Styled.SubNavLarge;
  const SubNavContainerComponent = isMobile
    ? Styled.MobileSubNavContainer
    : Styled.SubNavContainerLarge;

  return (
    <Styled.NavTextLinkContainer
      subNavFocused={subNavFocused}
      listHeight={listHeight}
      className={classNames(level, `${className}-dropdown`)}
      level={level}
    >
      <SubNavContainerComponent
        subNavFocused={subNavFocused}
        setSubNavFocused={setSubNavFocused}
        isMobile={isMobile}
      >
        <Styled.NavTextLinkDrop
          onClick={() => {
            setSubNavFocused(!subNavFocused);
            onMenuClick();
          }}
          isCurrentPath={isCurrentPath}
          isMobile={isMobile}
        >
          {item.rootLink.title}
          <Styled.Caret
            name={ICONS.CARET_DOWN_SMALL}
            className={isMobile && 'mobile-nav-caret'}
          />
        </Styled.NavTextLinkDrop>
        <SubNavComponent
          className={subNavFocused && 'open'}
          isMobile={isMobile}
          subNavOpen={subNavFocused}
        >
          {isMobile && (
            <button
              onClick={() => setSubNavFocused(false)}
              aria-label="back to main navigation"
            >
              <Styled.Caret name={ICONS.CARET_DOWN} className="subnav-caret" />
            </button>
          )}
          <ul ref={containerRef}>
            {item.subLinksCollection.items.map(({ sys, title, url }, index) => {
              const { href: subHref, asLink: subAsLink } = getLinkProps(url);

              return (
                <Link href={subAsLink || subHref} passHref>
                  <Styled.SubNavItem
                    key={sys.id}
                    index={index}
                    className={classNames(level, `${className}-dropdown`)}
                    isMobile={isMobile}
                    onClick={event => {
                      mParticle.logEvent('action', mParticle.EventType.Other, {
                        action_text: 'sub navigation link click',
                        action_location: MODULES_MULTI_CTA.dropdown,
                      });
                      onClick(event);
                    }}
                  >
                    <Styled.SubNavTextLink
                      isCurrentPath={isCurrentPath}
                      isMobile={isMobile}
                    >
                      {title}
                    </Styled.SubNavTextLink>
                  </Styled.SubNavItem>
                </Link>
              );
            })}
          </ul>
        </SubNavComponent>
      </SubNavContainerComponent>
    </Styled.NavTextLinkContainer>
  );
};

/**
 * Creates a regular navigation item instance with no dropdown for subnav
 * @param {object} item Navigation Item data
 * @param {string} locale Current Locale
 * @param {function} onClick On Click handler for links
 * @param {string} level
 * @param {boolean} isCurrentPath
 * @param {boolean} isMobile - boolean to determine the layout of the submenu
 * @param isBackButton
 * @param lowerNavgationSlug
 * @returns {ReactElement}
 */
const NavItem = ({
  item,
  locale,
  onClick,
  level,
  isCurrentPath,
  isBackButton,
  lowerNavigationSlug,
  isHomepage,
  className,
  isMobile,
}) => {
  const landingLinks =
    typeof lowerNavigationSlug === 'string' &&
    lowerNavigationSlug.startsWith('landing');
  const asLink =
    level === 'lower' && !isHomepage && !landingLinks
      ? `/${locale}/${lowerNavigationSlug}/${item.slug}`
      : `/${locale}/${item.slug}`;

  const onMenuClick = event => {
    mParticle.logEvent('action', mParticle.EventType.Other, {
      action_text: 'navigation link click',
      action_location: MODULES_MULTI_CTA.navigationBar,
    });
    onClick(event);
  };

  return (
    <Styled.NavTextLinkContainer
      className={classNames(level, className)}
      isBackButton={isBackButton}
      isMobile={isMobile}
    >
      <Link
        href={`${asLink}`}
        className={classNames(level, className)}
        passHref
      >
        <Styled.NavTextLink
          onClick={onMenuClick}
          isCurrentPath={isCurrentPath}
          isMobile={isMobile}
        >
          {isBackButton ? `Back to All ${item.title}` : item.title}
        </Styled.NavTextLink>
      </Link>
    </Styled.NavTextLinkContainer>
  );
};

/**
 * Creates a dropdown or a Navigation Link depending on the data.
 * @param {object} item Navigation Item data
 * @param {string} locale Current Locale
 * @param {function} onClick On Click handler for links
 * @param {number} listHeight - The list height
 * @param {string} level determines the styling and type of sub nav dropdown
 * @param {function} onLayout - The layout change listener
 * @param {boolean} isMobile used to determine which dropdown type to use
 * @param {boolean} isCurrentPath used for nav link styling
 * @param {boolean} isBackButton used for nav link styling
 * @param lowerNavigationSlug
 * @param style
 * @param isHomepage
 * @returns {ReactElement}
 */
export const SubNav = ({
  item,
  locale,
  onClick,
  listHeight,
  level,
  onLayout = () => {},
  isMobile,
  isCurrentPath,
  isBackButton,
  lowerNavigationSlug,
  isHomepage,
  className,
}) => {
  const { type } = item;

  switch (type) {
    case COMPONENTS.DROPDOWN:
      return (
        <Dropdown
          item={item}
          locale={locale}
          onClick={onClick}
          listHeight={listHeight}
          onLayout={onLayout}
          level={level}
          isMobile={isMobile}
          isCurrentPath={isCurrentPath}
          className={className}
        />
      );
    default:
      return (
        <NavItem
          item={item}
          locale={locale}
          onClick={onClick}
          level={level}
          isCurrentPath={isCurrentPath}
          isBackButton={isBackButton}
          isMobile={isMobile}
          lowerNavigationSlug={lowerNavigationSlug}
          isHomepage={isHomepage}
          className={className}
        />
      );
  }
};

SubNav.propTypes = {
  /**
   * Navigation Item data
   */
  item: PropTypes.objectOf(any).isRequired,
  /**
   * Current Locale
   */
  locale: PropTypes.string.isRequired,
  /**
   * On Click handler for links
   */
  onClick: PropTypes.func.isRequired,
};
