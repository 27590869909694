import styled from 'styled-components';

import { minWidth } from 'styles/media-queries';
import { NavBar, NavBarListItem } from '@spotify-internal/encore-web';

import { container } from 'styles/grid';
import ContentCuratedList from '../curated-list/ContentCuratedList';

export const Container = styled.div``;

export const TabBar = styled(NavBar)`
  ${container}
`;

export const StyledNavBarListItem = styled(NavBarListItem)`
  font-size: 1.2rem;
`;

export const CuratedList = styled(ContentCuratedList)`
  padding-bottom: 2rem;
  padding-top: 2rem;

  &:first-child {
    padding-top: 6.4rem;
  }

  &:last-child {
    padding-bottom: 8rem;
  }

  ${minWidth.lg`
    padding-bottom: 4rem;
    padding-top: 4rem;

    &:first-child {
      padding-top: 11.2rem;
    }

    &:last-child {
      padding-bottom: 12rem;
    }
  `}
`;
