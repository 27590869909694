import styled from 'styled-components';

import { minWidth } from 'styles/media-queries';

import { container } from 'styles/grid';
import { spacer6 } from 'styles/spacers';
import { black } from 'styles/colors';

export const Gallery = styled.section`
  background-color: ${black};
`;

export const GridContainer = styled.section`
${props => !props.fullWidth && container}
  margin-top: ${spacer6};
  display: grid;
  grid-gap: 24px;

  ${minWidth.lg`
    grid-template-columns: repeat(${props => props.columns}, 1fr);
    grid-template-rows: repeat(${props => props.rows}, 1fr);
  `}
`;
