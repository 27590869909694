import styled from 'styled-components';

import { Cta as CtaComponent, Paragraph } from 'components/atoms';
import { container, columnsGutter } from 'styles/grid';
import { maxWidth, minWidth } from 'styles/media-queries';
import { animations, fontWeights } from 'styles/variables';
import { white, black, darkGray } from 'styles/colors';
import { spacer16, spacer32, spacer64, sectionMargins } from 'styles/spacers';

export const SliderTab = styled.section`
  color: ${white};
  ${sectionMargins}
`;

export const Container = styled.div`
  ${container}

  ${minWidth.md`
    ${columnsGutter}
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  `}
`;

export const HeaderContainer = styled.div`
  width: 100%;

  ${({ theme }) =>
    !theme.hasCta &&
    `
    display: flex;
    flex-direction: column;
    text-align: center;
    ${minWidth.md`
      grid-column: 3 / span 8;
    `}
  `}
`;

export const TitleContainer = styled.div`
  ${minWidth.md`
    ${({ theme }) =>
      theme.hasCta
        ? `
      grid-column: 1 / span 6;
    `
        : `
      grid-column: 3 / span 8;
      text-align: center;
    `}
  `}
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 3rem 0rem;

  ${maxWidth.sm`
    padding-bottom: 4.8rem;
  `}

  ${minWidth.md`
    ${({ theme }) =>
      theme.hasCta
        ? `
      grid-column: 7 / span 6;
      justify-content: start;
      margin: unset;
      padding-bottom: 8rem;
    `
        : `
      grid-column: 3 / span 8;
      text-align: center;
    `}
  `}
`;

export const CardDescription = styled(Paragraph)`
  margin-top: ${spacer16};
`;

export const Cta = styled(CtaComponent)`
  width: fit-content;
  margin-top: 3rem;
`;

export const SliderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 0;
  height: 5rem;
  grid-column: 1 / span 12;
`;

export const Slider = styled.div`
  border-radius: 4rem;
  padding: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 0.8rem;
  width: unset;

  ${minWidth.md`
    background-color: ${darkGray};
    gap: 1rem;
  `}
`;

export const Tab = styled.div`
  border-radius: 4rem;
  padding: 1rem 2rem;
  text-align: center;
  cursor: pointer;
  transition: color ${animations.defaultTransition};
  background-color: ${darkGray};
  height: 4.2rem;
  font-weight: ${fontWeights.extra_bold};
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.active &&
    `
    background: ${white};
    color: ${black};
  `}

  ${minWidth.md`
    padding: 1rem ${spacer32};
    font-size: 1.6rem;
  `}
`;

export const CardsContainer = styled.div`
  border-radius: 8px;
  background-color: inherit;
  ${container}
  display: grid;
  grid-template-rows: 1fr auto;
  padding-bottom: 2rem;

  ${minWidth.md`
    padding: 0 ${spacer64};
    grid-column: span 12;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(12, 1fr);
    background-color: ${darkGray};
  `}
`;

export const GalleryContainer = styled.div`
  ${minWidth.md`
    grid-column: span 12;
  `}

  .gallery-card {
    aspect-ratio: 4 / 5;
  }
`;

export const CardTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  grid-row: 2;

  ${minWidth.md`
    grid-row: 1;
    grid-column: ${({ theme }) =>
      theme.imageRight ? `1 / span 5` : `8 / span 5`};
  `}
`;

export const CardImageSpacer = styled.div`
  padding: 3rem 0;
  display: flex;
  justify-content: center;

  ${minWidth.md`
    padding: 5rem;
    grid-column: ${({ theme }) =>
      !theme.imageRight ? `1 / span 6` : `7 / span 6`};
  `}
`;

export const CardImageContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 327px;
  min-height: 327px;
  ${minWidth.xl`
    width: 632px;
    height: 632px;
  `}
`;

export const Image = styled.img`
  position: absolute;
  max-width:100%;
  width: auto;
  height: 100%;
  border-radius: 1rem;
  z-index: 1;
  transition: transform 1s ease-in-out;
  will-change: transform;

  ${props =>
    props.position === 'center' &&
    `
    z-index: 2;
  `}

  ${props =>
    props.position === 'right-center' &&
    `
    animation: rightToCenter 0.5s ease-in-out;
    animation-fill-mode: forwards;
  `}

  ${props =>
    props.position === 'left-center' &&
    `
    animation: leftToCenter 0.5s ease-in-out;
    animation-fill-mode: forwards;
  `}

  ${props =>
    props.position === 'right' &&
    `
    animation: centerToRight 0.5s ease-in-out;
    animation-fill-mode: forwards;
  `}

  ${props =>
    props.position === 'left' &&
    `
    animation: centerToLeft 0.5s ease-in-out;
    animation-fill-mode: forwards;
  `}

  @keyframes rightToCenter {
  0% {
    transform: rotate(10deg) translate(35%, -2%) scale(0.9);
    z-index: 1;
  }
  40% {
    z-index: 0;
    transform: rotate(10deg) translateX(20%) scale(0.95);
  }
  50% {
    transform: rotate(10deg) translateX(18%) scale(0.95);
  }
  100% {
    transform: rotate(0deg) translateX(0) scale(1);
    z-index: 2;
  }
}

  @keyframes leftToCenter {
    0% {
      transform: rotate(-10deg) translate(-35%, -2%) scale(0.9);
      z-index: 0;
    }
    40% {
      z-index: 0;
      transform: rotate(-10deg) translateX(-20%) scale(0.95);
    }
    50% {
      transform: rotate(-10deg) translateX(-18%) scale(0.95);
    }
    100% {
      transform: rotate(0deg) translateX(0) scale(1);
      z-index: 2;
    }
  }


  @keyframes centerToRight {
    0% {
      transform: rotate(0deg) translateX(0) scale(1);
      z-index: 1;
    }
    40% {
      z-index: 0;
    }
    50% {
      transform: rotate(5deg) translateX(7.5%) scale(0.95);
    }
    100% {
      transform: rotate(10deg) translate(35%, -2%) scale(0.9);
      z-index: 0;
    }
  }

  @keyframes centerToLeft {
    0% {
      transform: rotate(0deg) translateX(0) scale(1);
      z-index: 1;
    }
    40% {
      z-index: 0;
    }
    50% {
      transform: rotate(-5deg) translateX(-7.5%) scale(0.95);
    }
    100% {
      transform: rotate(-10deg) translate(-35%, -2%) scale(0.9);
      z-index: 0;
    }
  }

`;
