import React, { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { eventTrack, AUTO_SCROLL_SCROLLED } from 'utils/google-tag-manager';
import { isXSmallToMedium } from 'styles/media-queries';
import { navHeight } from 'styles/variables';

import * as Styled from './AutoScroll.styled';

const RESIZE_DEBOUNCE_DELAY = 250;

/**
 * A button that automatically scrolls to another element on the screen
 * @param {React.MutableRefObject<any>} scrollRef The ref of the element of which to scroll.
 * @param {string} scrollFrom The react component which uses the auto scroll button.
 * @returns {ReactElement}
 */

export interface AutoScrollProps {
  scrollRef: React.MutableRefObject<any>;
  scrollFrom: string;
}

const AutoScroll = ({ scrollRef, scrollFrom }: AutoScrollProps) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(isXSmallToMedium());

    const onResize = debounce(() => {
      setIsMobile(isXSmallToMedium());
    }, RESIZE_DEBOUNCE_DELAY);

    window.addEventListener('resize', onResize);

    return () => {
      onResize.cancel();
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const navigationHeight = (isMobile ? navHeight.smToLg : navHeight.mlUp) * 10;
  const y =
    scrollRef &&
    scrollRef.current &&
    scrollRef.current.offsetTop - navigationHeight;

  const executeScroll = () => {
    eventTrack(AUTO_SCROLL_SCROLLED, { scrollFrom });
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  return (
    <Styled.Container onClick={executeScroll} data-testid="auto-scroll">
      <Styled.ScrollArrow />
    </Styled.Container>
  );
};

export default AutoScroll;
