import { AUTH_CODE } from 'constants/auth';
import { SPOTIFY_AUTHORIZE } from 'constants/urls';
import { stringify } from 'querystring';

const popUpWidth = 480;
const popUpHeight = 800;

const vibeWords = [
  'art deco',
  'mulled cider',
  'downtown vibes',
  'floaty',
  'mysterious',
  'liminal',
  'vanilla girl',
  'calm before the storm',
  'fanficore',
  'villain',
  'rock star',
  'vampiric',
  'romantasy',
  'goblincore',
  'vintage hollywood',
  'hiest',
  'fairy tale',
  'Speedrun',
  'gym hardstyle',
  "let 'em cook",
  'let em cook',
  'shower',
  'reading',
  'hanging out',
  'basketball',
];

const AVG_BLEND_PLAYLISTS = 2.3;

export class SpotifyWebApiClient {
  constructor(config, redirectURI, state) {
    this.config = config;
    this.redirectURI = redirectURI;
    this.state = state;
    this.trendData = [{ name: '1' }, { name: '2' }, { name: '3' }];
  }

  async getTrendReport() {
    const authCode = await this.getAuthCodeFromCenterPopUp();
    const authToken = await this.getAccessTokenFromAuthCode(authCode);

    await Promise.all(
      this.trendData.map(async (trend, index) => {
        try {
          const fetcherName = `trend${index + 1}Fetcher`;
          if (typeof this[fetcherName] === 'function') {
            const resolvedRank = await this[fetcherName](authToken);
            this.trendData[index].rank = resolvedRank;
          } else {
            throw new Error(`Fetcher for trend ${index + 1} does not exist`);
          }
        } catch (error) {
          throw new Error(
            `Error fetching trend ${trend.name}: ${error.message}`,
          );
        }
      }),
    );
    // get the index of the trend with the highest rank
    const maxRankIndex = this.trendData.reduce(
      (maxIndex, current, index, array) => {
        return current.rank > array[maxIndex].rank ? index : maxIndex;
      },
      0,
    );
    // return the index of the trend with the highest rank
    return maxRankIndex;
  }

  async trend1Fetcher() {
    // fetch user's top 50 tracks
    const res = await fetch(`https://api.spotify.com/v1/me/playlists`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.authToken}`,
      },
    });
    if (!res.ok) {
      throw new Error(`${res.status}: ${res.statusText}`);
    }
    const responseData = await res.json();
    const playlistNames = responseData.items.filter(playlist =>
      playlist.description?.includes('A Blend of'),
    );

    const blendRank = Math.min(
      (playlistNames.length * 50) / AVG_BLEND_PLAYLISTS,
      100,
    );
    return blendRank;
  }

  async trend2Fetcher() {
    // fetch user's top 50 tracks
    const res = await fetch(`https://api.spotify.com/v1/me/top/tracks`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.authToken}`,
      },
    });
    if (!res.ok) {
      throw new Error(`${res.status}: ${res.statusText}`);
    }
    const trackData = await res.json();
    // Find the average of track popularity
    const sum = trackData.items.reduce((totalSum, track) => {
      return totalSum + track.popularity;
    }, 0);
    const averagePopularity = sum / trackData.items.length;
    return averagePopularity;
  }

  async trend3Fetcher() {
    // fetch user's playlists
    const res = await fetch(`https://api.spotify.com/v1/me/playlists`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.authToken}`,
      },
    });
    if (!res.ok) {
      throw new Error(`${res.status}: ${res.statusText}`);
    }
    const responseData = await res.json();

    // Find daylist in playlist list
    const daylistData = responseData.items.filter(playlist =>
      playlist.name?.includes('daylist'),
    );
    if (!daylistData || !daylistData[0]) {
      return 0;
    }
    const daylistName = daylistData[0].name;

    // Check daylist for vibey words
    const daylistRank = vibeWords.some(word =>
      daylistName.toLowerCase().includes(word.toLowerCase()),
    )
      ? 100
      : 0;
    return Math.min(daylistRank, 100);
  }

  /**
   * Opens a centered browser pop up and returns user auth code
   * @returns {string} authentication code
   */
  async getAuthCodeFromCenterPopUp() {
    return new Promise((resolve, reject) => {
      localStorage.removeItem(AUTH_CODE);
      // build auth URL
      const scope =
        'user-read-private user-read-email user-top-read playlist-read-private';
      const authURL = `${SPOTIFY_AUTHORIZE}?${stringify({
        response_type: 'code',
        client_id: this.config.SPOTIFY_TIL_CLIENT_ID,
        scope,
        redirect_uri: this.redirectURI,
        state: this.state,
        show_dialog: true,
      })}`;

      const left = window.screenLeft + (window.innerWidth - popUpWidth) * 0.5;
      const right =
        window.screenTop + (window.innerHeight - popUpHeight) * 0.25;
      const authWindow = window.open(
        authURL,
        'Spotify Auth',
        `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${popUpWidth},height=${popUpHeight},left=${left},top=${right}`,
      );
      const checkForCode = () => {
        try {
          if (authWindow.closed) {
            reject(new Error('Authentication window closed'));
            return;
          }
          const codeMatch = localStorage.getItem(AUTH_CODE);
          if (codeMatch) {
            authWindow.close();
            localStorage.removeItem(AUTH_CODE);
            resolve(codeMatch);
          } else {
            localStorage.removeItem(AUTH_CODE);
            setTimeout(checkForCode, 500);
          }
        } catch (err) {
          reject(err);
        }
      };
      checkForCode();
    });
  }

  /**
   * Use Access token to fetch auth code
   * @param {string} props.authCode The received authCode
   * @returns {string} authentication token
   */
  async getAccessTokenFromAuthCode(authCode) {
    try {
      const response = await fetch('https://accounts.spotify.com/api/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Basic ${Buffer.from(
            `${this.config.SPOTIFY_TIL_CLIENT_ID}:${this.config.WEB_API_SECRET}`,
          ).toString('base64')}`,
        },
        body: new URLSearchParams({
          grant_type: 'authorization_code',
          code: authCode,
          redirect_uri: this.redirectURI,
        }).toString(),
      });

      if (!response.ok) {
        throw new Error(`Failed to exchange code: ${response.statusText}`);
      }

      const data = await response.json();
      this.authToken = data.access_token;
      return this.authToken;
    } catch (error) {
      console.error('Error exchanging code for token:', error);
      throw error;
    }
  }
}
