import styled from 'styled-components';

import { minWidth } from 'styles/media-queries';

import { spacer48, spacer64, spacer80 } from 'styles/spacers';

export const Container = styled.section`
  position: relative;
  margin-top: ${spacer48};
  ${minWidth.lg`
    margin-top: ${spacer80};
  `}
`;

export const ArticleGrid = styled.div`
  display: grid;
  grid-gap: 16px;
  ${minWidth.lg`
    grid-gap: 24px;
  `}
`;

export const GridPage = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;

  ${minWidth.md`
    grid-template-columns: repeat(3, 1fr);
  `}
  ${minWidth.lg`
    grid-gap: 24px;
  `}
`;

export const GridCol = styled.div`
  grid-column: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .longest,
  .long {
    aspect-ratio: 3 / 4;
  }
  .small,
  .medium {
    aspect-ratio: unset;
  }

  ${minWidth.md`
    grid-column: ${props => props.colNumber};
    .small {
      aspect-ratio: 2 / 2;
    }
    .medium {
      aspect-ratio: 2 / 2.75;
    }
    .long {
      aspect-ratio 2 / 4;
    }
    .longest {
      aspect-ratio: 2 / 4.5;
    }
    .tile-title {
      font-size: 2rem;
    }
  `}

  ${minWidth.lg`
    gap: 24px;
    .small {
      aspect-ratio: 3 / 2;
    }
    .medium {
      aspect-ratio: 3 / 2.75;
    }
    .long {
      aspect-ratio 3 / 4;
    }
    .longest {
      aspect-ratio: 3 / 4.5;
    }
    .tile-title {
      font-size: 2.4rem;
    }
  `}

  ${minWidth.xl`
    .tile-title {
      font-size: 3.2rem;
    }
  `}
`;

export const CtaContainer = styled.div`
  display: flex;
  align-content: center;
  margin-top: ${spacer48};
  justify-content: left;

  ${minWidth.md`
    margin-top: ${spacer64};
    justify-content: center;
  `}
`;
