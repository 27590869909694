/**
 * Project defined icons.
 */
export const ICONS = {
  ARROW: 'arrow',
  ARROW_LEFT: 'arrow-left',
  ARROW_RIGHT: 'arrow-right',
  ARROW_RIGHT_SMALL: 'arrow-small-right',
  ARROW_DOWN: 'arrow-down',
  ARROW_DOWN_SMALL: 'arrow-down-small',
  ARROW_UP: 'arrow-up',
  ARROW_UP_SMALL: 'arrow-up-small',
  ARROW_RIGHT_NO_CONTAINER: 'arrow-right-no-container',
  ARROW_LEFT_NO_CONTAINER: 'arrow-left-no-container',
  LOCALE_GLOBE: 'locale-globe',
  SPOTIFY_LOGO: 'spotify-logo',
  CARET_DOWN: 'caret-down',
  CARET_DOWN_SMALL: 'caret-down-small',
  WHITE_ARROW: 'white-arrow-right',
  CLOSE_MARK: 'close-mark',
  FACEBOOK: 'facebook',
  FACEBOOK_SMALL: 'facebook-small',
  FORM_ERROR: 'form-error',
  FORM_SUCCESS: 'form-success',
  HAMBURGER: 'hamburger',
  ROUND_HAMBURGER: 'round-hamburger',
  ROUND_CLOSE: 'round-close',
  INSTAGRAM: 'instagram',
  INSTAGRAM_SMALL: 'instagram-small',
  LINKEDIN: 'linkedin',
  SPOTIFY_ADVERTISING: 'spotify-advertising',
  WHITE_SPOTIFY_ADVERTISING: 'white-spotify-advertising',
  BLACK_SPOTIFY_ADVERTISING: 'black-spotify-advertising',
  TWITTER: 'twitter',
  TWITTER_SMALL: 'twitter-small',
  PLUS: 'plus',
  MINUS: 'minus',
  LINK_ARROW: 'link-arrow',
  LINK_ARROW_SMALL: 'link-arrow-small',
  PLAY: 'play',
  PLAY_SMALL: 'play-small',
  PAUSE: 'pause',
  PAUSE_SMALL: 'pause-small',
  FILTER: 'filter',
  FILTER_SMALL: 'filter-small',
  SEARCH: 'search',
  SEARCH_SMALL: 'search-small',
  SEARCH_WITH_CONTAINER: 'search-with-container',
  SEARCH_WITH_CONTAINER_SMALL: 'search-with-container-small',
  DOWNLOAD: 'download',
  DOWNLOAD_SMALL: 'download-small',
  ACCOUNT: 'account',
  ACCOUNT_SMALL: 'account-small',
  CARET_CLOSE: 'caret-close',
  CARET_CLOSE_SMALL: 'caret-close-small',
  CARET_OPEN: 'caret-open',
  CARET: 'caret',
  CARET_SMALL: 'caret-small',
  CLOSE_MARK_SMALL: 'caret-mark-small',
  COLLAPSE: 'collapse',
  COLLAPSE_SMALL: 'collapse-small',
  EXPAND: 'expand',
  EXPAND_SMALL: 'expand-small',
};
