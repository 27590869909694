import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import withApollo from 'next-with-apollo';
import errorLink from './errorLink';
import previewLink from './previewLink';
import httpLink from './httpLink';

const link = ApolloLink.from([errorLink, previewLink, httpLink]);

export default withApollo(
  // You can get headers and ctx (context) from the callback params
  // e.g. ({ headers, ctx, initialState })
  ({ initialState }) =>
    new ApolloClient({
      link,
      cache: new InMemoryCache()
        // Rehydrates the cache using the initial data passed from the server:
        .restore(initialState || {}),
    }),
);
