import styled from 'styled-components';
import { Button as ButtonComponent, Cta, Paragraph } from 'components/atoms';
import { darkGray, white, white20OpacityOnBlack } from 'styles/colors';

export const CtaCard = styled.div`
  background-color: ${darkGray};
  color: ${white};
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
`;

export const ImageContainer = styled.div`
  flex-grow: 1;
  ${props =>
    props.theme.hasImage && props['data-src']
      ? `background-image: url(${props['data-src']}); background-size: cover; background-position: center;`
      : `background-color: ${darkGray};`}
`;

export const IconContainer = styled.div`
  width: 4.5rem;
  height: 4.5rem;
  margin-bottom: 3rem;
  ${props =>
    props['data-src']
      ? `background-image: url(${props['data-src']}); background-size: cover; background-position: center;`
      : `background-color: ${darkGray};`}
`;

export const Description = styled(Paragraph)`
  margin-top: 1rem;
`;

export const TitleContainer = styled.div`
  background-color: ${darkGray};
  padding: 3rem 1.5rem;
`;

export const CtasContainer = styled.div`
  font-weight: 800;
  ${props => !props.theme.titleOnly && `margin-top: 3rem;`}
`;

export const CtaContainer = styled(Cta)`
  display: flex;
  ${props => !props.theme.titleOnly && `padding: 1rem 0rem;`}
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  text-decoration: none;
  color: ${white};
  ${props =>
    !props.theme.turnArrow && `border-top: 1px solid ${white20OpacityOnBlack};`}
`;

export const Button = styled(ButtonComponent)`
  ${props => props.theme.turnArrow && `transform: rotate(-45deg);`}
`;
