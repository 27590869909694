import { TYPES, CATEGORY } from './event-types';

/**
 * Joins common attributes to auto scroll events with custom data
 * @param {object} data
 * @returns {object} joined data
 */
const autoScrollEvent = (data: {
  eventAction: string;
  eventLabel: string;
}) => ({
  event: TYPES.trackEvent,
  eventCategory: CATEGORY.autoScroll,
  ...data,
});

/**
 * The AUTO_SCROLL_SCROLLED event map function
 * @param {string} scrollFrom - react component from where auto scroll was clicked
 */
export const AUTO_SCROLL_SCROLLED = ({ scrollFrom }: { scrollFrom: string }) =>
  autoScrollEvent({
    eventAction: 'Auto Scroll Down',
    eventLabel: `Auto scrolled from: ${scrollFrom}`,
  });

export const AUTO_SCROLL_FROM_COMPONENTS = {
  HERO_BUBBLE: 'hero-bubble',
};
