import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { getThemeKey, THEMES } from 'utils/get-theme-color';
import { CTA_TYPES } from 'constants/cta-types';
import kebabCase from 'lodash/kebabCase';
import { ThemeProvider } from 'styled-components';
import { Markdown } from 'components/molecules';
import {
  eventTrack,
  MULTI_CTA_CLICK,
  MODULES_MULTI_CTA,
} from 'utils/google-tag-manager';
import getMultiCtaAction from 'utils/google-tag-manager/getMultiCtaAction';
import { getLinkProps } from 'utils/get-link-props';
import { VIDEO_TYPES } from 'components/atoms';
import { Video } from 'components/atoms/video';
import debounce from 'lodash/debounce';
import { isXSmallToMedium } from 'styles/media-queries';
import AudioToggle from '../../atoms/audio-toggle/AudioToggle';
import * as Styled from './VideoFeatureCard.styled';
import { VideoSection } from '../video-section';

const RESIZE_DEBOUNCE_DELAY = 250;

export interface VideoFeatureCardPropsType {
  /**
   * Default className prop
   */
  className?: string;
  /**
   * The component background color.
   */
  backgroundColor: string;
  /**
   * The component description.
   */
  description: string;
  /**
   * The component eyebrow.
   */
  eyebrow?: string;
  /**
   * The component video, composed by the url and description.
   */
  video?: {
    videoId: string;
    videoName: string; // The video name set by author
    type: string; // The video player <Youtube | Vimeo>
    caption?: string; // The video caption set by author
    controls?: boolean; // Shows or hide video player controls
    autoplay?: boolean; // Video play automatically
    verticalVideo?: {
      url: string;
    };
  };
  /**
   * True if the video is right positioned, false if is left positioned.
   */
  videoPosition: boolean;
  /**
   * True if the theme is light, false if it is dark.
   */
  theme: boolean;
  /**
   * The component title.
   */
  title: string;
  /**
   * The component title color.
   */
  titleColor?: string;
  /**
   * A list of CTAs
   */
  ctasCollection?: {
    items: Array<any>;
  };
  /**
   * An unordered list
   */
  columnList?: string;
  /**
   * style selector for article page
   */
  isArticleMargin?: boolean;
}

/**
 * Renders a Feature Card with a video, in different variations like video left/right, CTA, etc.
 */
export const VideoFeatureCard = ({
  className = '',
  backgroundColor,
  description,
  eyebrow = '',
  video = {
    videoId: '',
    videoName: '',
    type: VIDEO_TYPES.VIMEO,
    verticalVideo: { url: '' },
  },
  videoPosition,
  theme,
  title,
  titleColor = '',
  ctasCollection,
  columnList,
  isArticleMargin = false,
}: VideoFeatureCardPropsType) => {
  const hasVideo = !!video && video.videoId;
  const hasVerticalVideo = !!video && video.verticalVideo;
  const videoRight =
    (hasVideo && !videoPosition) || (hasVerticalVideo && !videoPosition);
  const themeKey = getThemeKey(theme);
  const isDark = themeKey === THEMES.DARK;
  const isEyebrowVisible = hasVideo && !!eyebrow;
  const ctaItems = get(ctasCollection, 'items', []);
  const { SECONDARY, TERTIARY } = CTA_TYPES;

  const [videoIsMuted, setVideoIsMuted] = useState(true);
  const [isXSmallToMediumWindow, setIsXSmallToMediumWindow] = useState(false);

  const toggleVideoIsMuted = () => {
    setVideoIsMuted(!videoIsMuted);
  };

  useEffect(() => {
    setIsXSmallToMediumWindow(isXSmallToMedium());

    const onResize = debounce(() => {
      setIsXSmallToMediumWindow(isXSmallToMedium());
    }, RESIZE_DEBOUNCE_DELAY);

    window.addEventListener('resize', onResize);

    return () => {
      onResize.cancel();
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <ThemeProvider
      theme={{
        backgroundColor,
        titleColor,
        hasVideo,
        videoRight,
        themeKey,
        hasVerticalVideo,
        isArticleMargin,
      }}
    >
      <Styled.VideoFeatureCard className={className}>
        <Styled.Container>
          <Styled.Content>
            <Styled.HeadlineContainer>
              {isEyebrowVisible && (
                <Styled.TitleEyebrow>{eyebrow}</Styled.TitleEyebrow>
              )}
              {/* @ts-ignore */}
              <Styled.Title tag="h2" styling="h2">
                {title}
              </Styled.Title>
            </Styled.HeadlineContainer>
            <Styled.DescriptionContainer>
              {/* @ts-ignore */}
              <Markdown body={description} theme={theme} />
              {columnList && (
                <Styled.ColumnList body={columnList} theme={theme} />
              )}
              <Styled.CtasContainer>
                {ctaItems.map(
                  (
                    { title: ctaTitle, url, type, overrideFunctionality },
                    index,
                  ) => {
                    const { href, asLink } = getLinkProps(url);
                    const ctaClickTrack = (
                      event: React.MouseEvent<HTMLDivElement>,
                    ) => {
                      const { isModalFormOpen } = get(event, 'data', {});
                      const actionText = getMultiCtaAction(
                        { overrideFunctionality, url },
                        isModalFormOpen,
                      );

                      eventTrack(MULTI_CTA_CLICK, {
                        event,
                        module: MODULES_MULTI_CTA.featureCard,
                        actionText,
                        headerText: title,
                        eyebrowText: eyebrow,
                      });
                    };

                    return (
                      // @ts-ignore
                      <Styled.Cta
                        key={kebabCase(`${ctaTitle}-${index}`)}
                        type={type === SECONDARY ? TERTIARY : type}
                        overrideFunctionality={overrideFunctionality}
                        href={href}
                        asLink={asLink}
                        onClick={ctaClickTrack}
                        isDarkTheme={isDark}
                      >
                        {ctaTitle}
                      </Styled.Cta>
                    );
                  },
                )}
              </Styled.CtasContainer>
            </Styled.DescriptionContainer>
          </Styled.Content>
          {hasVerticalVideo && (
            <Styled.VideoContainer>
              <VideoSection verticalVideo={video.verticalVideo} />
            </Styled.VideoContainer>
          )}
          {hasVideo && (
            <Styled.VideoContainer>
              <Video
                videoId={video.videoId}
                type={video.type}
                videoName={video.videoName}
                playing={video.autoplay}
                controls
                muted={videoIsMuted}
                loop
                showCaptions
              />
            </Styled.VideoContainer>
          )}
          {!hasVerticalVideo && (
            <Styled.AudioToggleContainer>
              <AudioToggle
                hideUnmuteText={!isXSmallToMediumWindow}
                // @ts-ignore
                hideMuteButton={isXSmallToMediumWindow}
                onClick={toggleVideoIsMuted}
                muted={videoIsMuted}
                hideTextOnUnmute={false}
              />
            </Styled.AudioToggleContainer>
          )}
        </Styled.Container>
      </Styled.VideoFeatureCard>
    </ThemeProvider>
  );
};
