import styled from 'styled-components';

import { minWidth, maxWidth } from 'styles/media-queries';

import { container } from 'styles/grid';
import { Headline, Paragraph, Cta as CtaComponent } from 'components/atoms';
import {
  sectionMargins,
  spacer2,
  spacer3,
  spacer48,
  spacer80,
} from 'styles/spacers';
import { white } from 'styles/colors';

export const Container = styled.section`
  ${container}
  position: relative;
`;

export const TitleContainer = styled.div`
  text-align: left;
  color: ${white};
  ${minWidth.lg`
    text-align: center;
  `}

  ${props => props.theme.hasTitle && sectionMargins}
`;

export const TrendTitle = styled(Headline)`
  ${minWidth.lg`
    width: 45%;
    margin: auto;
  `}
`;

export const TrendDescription = styled(Paragraph)`
  margin-top: ${spacer2};

  ${minWidth.lg`
    width: 50%;
    margin: ${spacer2} auto 0 auto;
  `}
`;

export const Cta = styled(CtaComponent)`
  margin-top: ${spacer3};
`;

export const TrendGrid = styled.div`
  display: grid;
  grid-gap: 24px;
  margin-bottom: ${spacer48};
  ${props =>
    props.theme.scrollVertical
      ? `
        grid-template-columns: 1fr;
        .short:not(.trend-tile) {
          aspect-ratio: unset;
        }
      `
      : `
      grid-template-rows: 1fr;
      grid-auto-columns: max-content;
      grid-auto-flow: column;
      overflow-x: scroll;
       -ms-overflow-style: none; /* for Internet Explorer, Edge /
      scrollbar-width: none; / for Firefox */
      overflow-y: clip;

      &::-webkit-scrollbar {
        display: none;
      }
      .short, .long {
        width: 80vw;
      }
      ${minWidth.md`
        .short, .long {
          width: 30vw;
        }
      `}
    `}
  

  ${minWidth.lg`
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: ${spacer80};
    .short {
      aspect-ratio: 4 / 3;
      width: unset;
    }
    .long {
      aspect-ratio: 4 / 5;
      width: unset;
    }
    ${maxWidth.lg`
      .tile-title {
        font-size: 2.5rem;
      }
    `}
  `}

  ${minWidth.xl`
    .short {
      aspect-ratio: 4 / 2.5;
      width: unset;
    }
    .long {
      aspect-ratio: 4 / 5.5;
      width: unset;
    }
  `}
`;

export const TrendGridCol = styled.div`
  display: flex;
  grid-gap: 24px;
  ${props =>
    props.theme.scrollVertical
      ? `
    flex-direction: column;
    `
      : `
    flex-direction: row;
  `}

  ${minWidth.lg`
    flex-direction: column;
    grid-column: ${props => props.colNumber};
  `}
`;
