// @ts-nocheck
// this is not preferred when using TS but as this is just an example, we are
// using it to avoid need to convert styles/media-queries to TS
import styled from 'styled-components';

import { ratioPercentage } from 'styles/variables';
import { gray50 } from '@spotify-internal/encore-web';

export const Root = styled.div``;

export const VideoContainer = styled.div`
  height: 0;
  overflow: hidden;
  padding-top: ${ratioPercentage.sixteenNine}%;
  position: relative;
  width: 100%;
`;

export const Video = styled.div`
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const Caption = styled.span`
  color: ${gray50};
  display: block;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-top: 1.9rem;
`;
