import styled from 'styled-components';
import { white } from 'styles/colors';
import { spacer16, spacer24 } from 'styles/spacers';
import { minWidth } from 'styles/media-queries';
import { Paragraph } from 'components/atoms';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${spacer16};
`;

export const CardContainer = styled.div`
  perspective: 1000px;
  margin-top: ${spacer16};
  z-index: 2;
`;

export const FlipCard = styled.div`
  transform-style: preserve-3d;
  transform: rotate(4deg)
    ${({ $isFlipped }) => ($isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)')};
  transition: transform 0.5s ease-in-out;
  width: 20rem;
  height: 26.6rem;
  position: relative;
  cursor: pointer;

  ${minWidth.md`
    width: 25rem;
    height: 33.33rem;
  `}

  ${minWidth.lg`
    width: 30rem;
    height: 40rem;
  `}
`;

export const BorderImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: center / contain no-repeat url('/culture-next/cardoutline.png');
  z-index: 2;
`;

export const CardSide = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  overflow: hidden;
  z-index: 2;
`;

export const CardFront = styled(CardSide)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardBack = styled(CardSide)`
  transform: rotateY(180deg);
  text-align: left;
  display: flex;
  justify-content: center;
  border-radius: 0 0 2rem 2rem;
  overflow: hidden;

  ${minWidth.md`
    border-radius: 0 0 2.5rem 2.5rem;
  `}

  ${minWidth.lg`
    border-radius: 0 0 3rem 3rem;
  `}
`;

export const BackText = styled(Paragraph)`
  padding: ${spacer16};
  text-align: left;

  &.back-text {
    line-height: 1.5rem;
    font-size: 1.5rem;

    ${minWidth.md`
      font-size: 1.8rem;
      line-height: 1.9rem;
    `}

    ${minWidth.lg`
      padding: ${spacer24};
      font-size: 2rem;
      line-height: 3rem;
    `}
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  height: 100%;
  width: 80%;
`;

export const BackImageContainer = styled.div`
  position: absolute;
  bottom: 0.3rem;
  width: 98%;
  height: 50%;

  img {
    z-index: 1;
  }
`;

export const FlipTextButton = styled.button`
  p {
    color: ${white};
  }
`;
