import styled from 'styled-components';
import { container } from 'styles/grid';
import { white } from 'styles/colors';
import { maxWidth, minWidth } from 'styles/media-queries';
import { spacer16, spacer24 } from 'styles/spacers';

export const Container = styled.div`
  color: ${white};

  ${props => !props.theme.isSplitView && container}

  ${minWidth.lg`
     ${props =>
       props.theme.isSplitView &&
       `
      position: relative;
      height: 100%;
      width: 100%;
      align-self: start;
    `};
  `}


  display:grid;
  grid-gap: ${spacer24};
  grid-template-columns: ${props =>
    props.theme.isSplitView ? '1fr' : '1fr 1fr'};

  ${maxWidth.sm`
    grid-template-columns: 1fr;
    grid-gap: ${spacer16};
  `}
`;
