import styled from 'styled-components';

import { fontWeights } from 'styles/variables';
import { minWidth } from 'styles/media-queries';
import { Icon } from 'components/atoms/icon';
import { brightRed100, gray50 } from '@spotify-internal/encore-web';
import { darkGray, spotifyGreen } from 'styles/colors';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const ValidationIcon = styled(Icon)`
  position: absolute;
  right: 2rem;
  margin: auto;

  svg {
    height: 1.6rem;
    width: 1.6rem;
  }

  ${minWidth.lg`
    svg {
      height: 2.4rem;
      width: 2.4rem;
    }
  `}
`;

export const Input = styled.input`
  background: transparent;
  border-radius: 3.5rem;
  padding: 2rem 2.5rem;
  color: inherit;
  filter: none;
  font-size: 1.8rem;
  font-weight: ${fontWeights.extra_bold};
  height: 6rem;
  margin-bottom: 1rem;
  width: 100%;

  ${props =>
    props.theme.isDark
      ? `background-color: ${darkGray};
    border: 1px solid ${darkGray};
    `
      : `background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0);
    `}

  &:focus {
    border: 1px solid ${spotifyGreen};
    outline: none;
  }

  &.invalid {
    border: 1px solid ${brightRed100};
  }

  &:invalid {
    box-shadow: none;
  }

  &::placeholder,
  ::-ms-input-placeholder,
  :-moz-placeholder,
  ::-moz-placeholder {
    color: ${gray50};
    opacity: 1 !important;
  }
`;

export const InvalidMessage = styled.div`
  color: ${brightRed100};
`;
