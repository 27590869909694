import React, { useState } from 'react';
import PropTypes, { any } from 'prop-types';

import get from 'lodash/get';

import { getLinkProps } from 'utils/get-link-props';
import { eventTrack, LINK_LIST_CTA_CLICK } from 'utils/google-tag-manager';

import { ThemeProvider } from 'styled-components';
import { Headline, Paragraph, Eyebrow } from 'components/atoms';
import { useImageSupport } from 'utils/use-image-support';
import { kebabCase } from 'lodash';
import { MODULES } from 'constants/modules';
import * as Styled from './SliderTab.styled';
import Gallery from '../gallery/Gallery';

const onClickCta = event => eventTrack(LINK_LIST_CTA_CLICK, { event });

/**
 * Creates a list of links.
 * @param {string} title
 * @param {string} eyebrow
 * @param {string} description
 * @param {object} cta
 * @param {boolean} imagePosition
 * @param {Array} cards
 * @returns {ReactElement}
 */
const SliderTab = ({
  title = '',
  eyebrow = '',
  description = '',
  cta = null,
  imagePosition = true,
  cards = [],
}) => {
  const leftAlign = true;
  const numTabs = cards.length;
  const [activeIndex, setActiveIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(0);
  const { queryUrl } = useImageSupport();
  const cardCtaItems = get(cards[activeIndex].ctasCollection, 'items', []);
  const { title: cardTitle, description: cardDescription } = cards[activeIndex];
  // eslint-disable-next-line no-underscore-dangle
  const isFeatureCard = cards[activeIndex].__typename === MODULES.FEATURE_CARD;
  const imageRight = imagePosition;
  const hasCta = !!cta;

  const renderCards = () => {
    return (
      <Styled.CardsContainer>
        <Styled.CardImageSpacer>
          <Styled.CardImageContainer>
            {cards.map(({ image: cardImage }, index) => (
              <Styled.Image
                key={kebabCase(`${getCardPosition(index)}-${index}`)}
                src={cardImage[queryUrl]}
                position={getCardPosition(index)}
              />
            ))}
          </Styled.CardImageContainer>
        </Styled.CardImageSpacer>
        <Styled.CardTitleContainer>
          <Headline tag="h2" styling="h2">
            <strong>{cardTitle}</strong>
          </Headline>
          <Styled.CardDescription>{cardDescription}</Styled.CardDescription>
          {cardCtaItems && cardCtaItems[0] && (
            <Styled.Cta
              type="secondary"
              isDarkTheme
              overrideFunctionality={cardCtaItems[0].overrideFunctionality}
              {...getLinkProps(cardCtaItems[0].url)}
              onClick={onClickCta}
            >
              {cardCtaItems[0].title}
            </Styled.Cta>
          )}
        </Styled.CardTitleContainer>
      </Styled.CardsContainer>
    );
  };

  const renderGallery = () => {
    return (
      <Styled.GalleryContainer>
        <Gallery
          title={cards[activeIndex].title}
          columns={cards[activeIndex].columns}
          rows={cards[activeIndex].rows}
          items={get(cards[activeIndex], 'itemsCollection.items', [])}
          fullWidth
        />
      </Styled.GalleryContainer>
    );
  };

  const setIndex = index => {
    setPrevIndex(activeIndex);
    setActiveIndex(index);
  };

  const getCardPosition = index => {
    if (index === activeIndex && prevIndex < activeIndex) {
      return 'right-center';
    }

    if (index === activeIndex && prevIndex > activeIndex) {
      return 'left-center';
    }

    if (index === activeIndex) {
      return 'center';
    }
    if (activeIndex === 0) {
      return index - activeIndex === 1 ? 'right' : 'left';
    }
    return activeIndex - index === 1 ? 'left' : 'right';
  };

  return (
    <ThemeProvider
      theme={{
        leftAlign,
        numTabs,
        imageRight,
        hasCta,
      }}
    >
      <Styled.SliderTab>
        <Styled.Container>
          {title && (
            <Styled.TitleContainer>
              <Eyebrow>{eyebrow}</Eyebrow>
              <Headline as="h2" text={title} />
            </Styled.TitleContainer>
          )}
          {description && (
            <Styled.DescriptionContainer>
              {description && <Paragraph>{description}</Paragraph>}
              {hasCta && cta && (
                <Styled.Cta
                  type={cta.type ? cta.type : 'secondary'}
                  isDarkTheme
                  overrideFunctionality={cta.overrideFunctionality}
                  {...getLinkProps(cta.url)}
                  onClick={onClickCta}
                >
                  {cta.title}
                </Styled.Cta>
              )}
            </Styled.DescriptionContainer>
          )}
          <Styled.SliderContainer>
            <Styled.Slider>
              {cards.map((card, index) => (
                <Styled.Tab
                  key={kebabCase(`tab-title-${index}`)}
                  active={activeIndex === index}
                  onClick={() => setIndex(index)}
                >
                  {isFeatureCard ? card.eyebrow : card.title}
                </Styled.Tab>
              ))}
            </Styled.Slider>
          </Styled.SliderContainer>
          {isFeatureCard ? renderCards() : renderGallery()}
        </Styled.Container>
      </Styled.SliderTab>
    </ThemeProvider>
  );
};

SliderTab.propTypes = {
  /**
   * The title of the component
   */
  title: PropTypes.string,
  /**
   * The eyebrow of the component
   */
  eyebrow: PropTypes.string,
  /**
   * The description of the component
   */
  description: PropTypes.string,
  /**
   * The cta
   */
  cta: PropTypes.objectOf(any),
  /**
   * True if the image is right positioned, false if is left positioned.
   */
  imagePosition: PropTypes.bool,
  /**
   * The component cards of feature cards.
   */
  cards: PropTypes.arrayOf(PropTypes.objectOf(any)).isRequired,
};

export default SliderTab;
