/**
 * Image attributes commonly used in queries
 */

export const IMG_QUALITY = 75;

export const IMG_SCALE = 'scale';

// The values are tied to values created in the CMS
export const IMG_ASPECT_RATIO = {
  FOUR_THREE: '4:3',
  ONE_ONE: '1:1',
  TWO_THREE: '2:3',
  THREE_TWO: '3:2',
  FOUR_FIVE: '4:5',
  FIVE_FOUR: '5:4',
  NINE_SIXTEEN: '9:16',
  SIXTEEN_NINE: '16:9',
  FREE: 'Free',
};

export const IMG_DIMENSIONS = [
  { w: 800, h: 600, d: '1x' },
  { w: 1600, h: 1200, d: '2x' },
];

export const GET_STARTED_IMG_DIMENSIONS = [
  { w: 900, h: 600, d: '`1x' },
  { w: 1800, h: 1200, d: '2x' },
];

export const AD_FORMAT_IMG_DIMENSIONS = [
  { w: 1200, h: 1200, d: '`1x' },
  { w: 1600, h: 1200, d: '2x' },
];
