import styled from 'styled-components';
import {
  Dropdown,
  semanticColors,
  cssColorValue,
  spacer12,
} from '@spotify-internal/encore-web';

import { maxWidth, minWidth } from 'styles/media-queries';
import { black, darkGray, gray, spotifyGreen, white } from 'styles/colors';
import { fontWeights } from 'styles/variables';
import { container } from 'styles/grid';
import { spacer24 } from 'styles/spacers';

export const Container = styled.div`
  ${container}

  ${minWidth.lg`
    padding-bottom: ${spacer24};
  `}
`;

export const FilterDropdown = styled(Dropdown)`
  font-size: 1.6rem;
  font-weight: 450;
  line-height: 2.2rem;
  letter-spacing: 0em;
  text-align: left;
  text-overflow: ellipsis;
  color: ${cssColorValue(semanticColors.textSubdued)};

  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;

  &:focus {
    box-shadow: inset 0 0 0 1px #878787;
  }
`;

export const StyledType = styled.p`
  font-weight: ${fontWeights.extra_bold};
  font-size: 1.6rem;
  padding-bottom: 0.8rem;
`;

export const DropdownSection = styled.span`
  display: inline-block;
  margin-right: ${spacer12};
  width: 100%;

  ${maxWidth.sm`
    width: unset;
  `}

  ${minWidth.lg`
    width: 22%;
  `}
`;

export const FilterLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  font-size: 1.6rem;
  cursor: pointer;
  font-weight: ${fontWeights.normal_p};

  div {
    margin-right: ${spacer12};
  }

  input + label {
    border: 2px solid ${white};
    border-radius: 2px;
  }

  label {
    span {
      background-color: inherit;
      border: 1px solid ${gray};
    }
  }
`;

export const ClearAllButton = styled.p`
  color: ${white};
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 1rem;
  width: 100%;
  margin-left: 2.4rem;
  font-size: 1.6rem;
  font-weight: ${fontWeights.bold};
  padding: 0;
  transition: color 0.25s ease-in-out;

  &:hover {
    color: ${spotifyGreen};
  }

  ${maxWidth.sm`
    text-align: center;
    margin-left: unset;
    width: unset;
    margin-top: 2.4rem;
  `}

  ${minWidth.lg`
    display: inline;
    width: 12.2rem;
    border: none;
   `}
`;

export const ResultsButton = styled.p`
  background-color: ${white};
  cursor: pointer;
  color: ${black};
  font-size: 1.6rem;
  font-weight: ${fontWeights.extra_bold};
  width: 100%;
  padding: 1.2rem 2.4rem;
  border-radius: 12rem;
  display: flex;
  justify-content: center;

  ${minWidth.lg`
    width: max-content;
  `}
`;

export const FilterBarSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  padding-bottom: 3rem;

  ${maxWidth.sm`
    width: 100%;
    padding: unset;
  `}

  ${minWidth.md`
    justify-content: center;
    align-items: center;
  `}

  ${minWidth.lg`
    padding: 4.8rem 0rem;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
  `}
`;

export const FilterSelection = styled.div`
  padding-bottom: 2.4rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: start;

  ${maxWidth.sm`
    padding-bottom: 2rem;
    padding-top: 2rem;
    margin: 1rem 0;
    border-top: 1px solid ${darkGray};

  `}
`;

export const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2.4rem 0;
  border-top: 1px solid ${darkGray};

  ${maxWidth.sm`
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`;
