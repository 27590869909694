// @ts-nocheck
// this is not preferred when using TS but as this is just an example, we are
// using it to avoid need to convert styles/media-queries to TS

import styled from 'styled-components';

import { fontWeights } from 'styles/variables';
import { minWidth } from 'styles/media-queries';
import { Icon } from 'components/atoms/icon';
import { gray50, solidBlack0 } from '@spotify-internal/encore-web';
import { white20OpacityOnBlack, spotifyGreen, white } from 'styles/colors';

export const Label = styled.label`
  align-items: center;
  color: inherit;
  display: inline-block;
  position: relative;
`;

export const Span = styled.span`
  margin-right: 1rem;
`;

export const Dropdown = styled.select`
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  filter: none;
  font-weight: inherit;
  max-width: 100%;
  overflow: hidden;
  padding-right: 3rem;
  text-overflow: ellipsis;
  white-space: nowrap;

  &::-ms-expand {
    display: none;
  }

  // Override for firefox and edge
  option {
    color: ${solidBlack0};
    font-size: initial;
    font-weight: initial;
    letter-spacing: initial;
    line-height: initial;
  }
`;

export const DropdownAsInput = styled(Dropdown)`
  ${props =>
    props.isDark
      ? `background-color: ${white20OpacityOnBlack};
      border: 1px solid ${white20OpacityOnBlack};
      `
      : `background-color: rgba(0, 0, 0, 0.1);
      border: 1px solid rgba(0, 0, 0, 0);
      `}
  border-radius: 3.5rem;
  padding: 1rem 5rem 1rem 2.5rem;
  font-size: 1.8rem;
  font-weight: ${fontWeights.extra_bold};
  height: 6rem;
  ${minWidth.lg`
    padding: 2rem 5rem 2rem 2.5rem;
  `}

  &:required:invalid {
    box-shadow: none;
    color: ${props => props.placeHolderColor || gray50};
    opacity: 1;
    outline: none;
  }

  &:focus {
    border: 1px solid ${spotifyGreen};
    outline: none;
  }
`;

export const Caret = styled(Icon)<{ asInput?: boolean }>`
  height: 100%;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;

  div {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  svg {
    position: absolute;
    ${props =>
      props.asInput
        ? `
        right: 2rem;
        color: ${white};
        width: 1.5rem;
        height: 1.5rem;
    `
        : `right: 0;`}
  }
`;

export const DropdownContainer = styled.div`
  display: inline-block;
  position: relative;
`;
