import styled from 'styled-components';

import { minWidth } from 'styles/media-queries';
import { container } from 'styles/grid';
import { sectionMargins, spacer16 } from 'styles/spacers';
import { FEATURE_CARD_CONTAINER_THEMES } from 'utils/get-theme-selector';

export const Container = styled.section`
  ${container}
  ${sectionMargins}
  display: flex;
  flex-direction: column;
  gap: ${spacer16};

  ${({ theme }) =>
    theme.themeSelectorType === FEATURE_CARD_CONTAINER_THEMES.GET_STARTED &&
    `
    padding-top: 0;
  `}
  ${minWidth.lg`
    ${({ theme }) =>
      theme.themeSelectorType === FEATURE_CARD_CONTAINER_THEMES.GET_STARTED &&
      `
      padding-top: 0;
    `}
  `}
`;
