import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import kebabCase from 'lodash/kebabCase';
import * as Styled from './List.styled';
import ListItem from '../../molecules/list-item/ListItem';

/**
 * Creates a list of list item components
 * @param {Array} listItems a list of all list items to display
 * @param {boolean} isSplitView split view style
 * @returns {ReactElement}
 */
const List = ({ listItems = [], isSplitView }) => {
  const [items, setItems] = useState(listItems);

  useEffect(() => {
    if (isSplitView) {
      setItems(listItems.slice(0, 4));
    }
  }, []);

  return (
    <ThemeProvider
      theme={{
        isSplitView,
      }}
    >
      <Styled.Container>
        {items.map((listItem, index) => {
          return (
            <ListItem
              key={kebabCase(`${listItem.title} ${index}`)}
              eyebrow={listItem.eyebrow}
              title={listItem.title}
              url={listItem.url}
              image={listItem.image}
              showBackgroundColor={listItem.showBackgroundColor}
              textColor={listItem.textColor}
            />
          );
        })}
      </Styled.Container>
    </ThemeProvider>
  );
};

List.propTypes = {
  /**
   * The collection of list items
   */
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      eyebrow: PropTypes.string,
      title: PropTypes.string.isRequired,
      url: PropTypes.string,
      image: PropTypes.object,
      showBackgroundColor: PropTypes.bool,
      textColor: PropTypes.string,
    }),
  ).isRequired,
  /**
   * if being used in split view module
   */
  isSplitView: PropTypes.bool,
};

export default List;
