import styled from 'styled-components';

import { fontWeights } from 'styles/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Info = styled.span`
  font-weight: ${fontWeights.black};
`;
