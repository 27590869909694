import React from 'react';

import * as Styled from './SectionDivider.styled';

/**
 * Defines a Section Divider Line
 * @returns {ReactElement}
 */
const SectionDivider = () => {
  return (
    <Styled.SectionDivider>
      <Styled.Line />
    </Styled.SectionDivider>
  );
};

export default SectionDivider;
