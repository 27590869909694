import { css } from 'styled-components';

/**
 * Media query for rules targeting only safari browsers.
 * eg usage:
 *  ${safariOnly`<style rules here>`}
 */
export const safariOnly = (...args) => css`
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      ${css(...args)}
    }
  }
`;

/**
 * Media query for rules targeting only firefox browsers.
 * eg usage:
 *  ${firefoxOnly`<style rules here>`}
 */
export const firefoxOnly = (...args) => css`
  @-moz-document url-prefix() {
    ${css(...args)}
  }
`;
