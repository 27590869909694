/**
 * Inline Card themes definition
 */
export const INLINE_CARD_TILE_THEMES = {
  INDUSTRY_SELECTOR: 'industry selector',
  TEXT_CENTER: 'text center',
  TEXT_SPLIT: 'text split',
  HEADLINE: 'headline',
};

export function getInlineCardTheme(type) {
  switch (type) {
    case 'industry selector':
      return INLINE_CARD_TILE_THEMES.INDUSTRY_SELECTOR;
    case 'text center':
      return INLINE_CARD_TILE_THEMES.TEXT_CENTER;
    case 'text split':
      return INLINE_CARD_TILE_THEMES.TEXT_SPLIT;
    case 'headline':
      return INLINE_CARD_TILE_THEMES.HEADLINE;
    default:
      return null;
  }
}
/**
 * Content Tile themes definition
 */
export const CONTENT_TILE_THEMES = {
  QUOTE: 'quote',
  IMAGE: 'image',
  CONTENT: 'content',
  TREND: 'trend',
  TOPIC: 'topic',
};

export function getContentTileTheme(type) {
  switch (type) {
    case 'quote':
      return CONTENT_TILE_THEMES.QUOTE;
    case 'image':
      return CONTENT_TILE_THEMES.IMAGE;
    case 'content':
      return CONTENT_TILE_THEMES.CONTENT;
    case 'trend':
      return CONTENT_TILE_THEMES.TREND;
    case 'topic':
      return CONTENT_TILE_THEMES.TOPIC;
    default:
      return null;
  }
}

/**
 * Stat Card themes definition
 */
export const STAT_CARD_THEMES = {
  HOMEPAGE_CAROUSEL: 'homepage carousel',
  IMAGE: 'image',
  TEXT_ONLY: 'text only',
  TEXT_WITH_BOLD_STAT: 'text with bold stat',
};

export function getStatCardTheme(type) {
  switch (type) {
    case 'homepage carousel':
      return STAT_CARD_THEMES.HOMEPAGE_CAROUSEL;
    case 'image':
      return STAT_CARD_THEMES.IMAGE;
    case 'text only':
      return STAT_CARD_THEMES.TEXT_ONLY;
    case 'text with bold stat':
      return STAT_CARD_THEMES.TEXT_WITH_BOLD_STAT;
    default:
      return null;
  }
}

/**
 * CTA Card themes definition
 */
export const CTA_CARD_THEMES = {
  MULTILINK_IMAGE: 'multilink with image',
  MULTILINK_ICON: 'multilink with icon',
  TEXT_IMAGE: 'text and image',
  TEXT_ONLY: 'text only',
  TITLE_ONLY: 'title only',
};

export function getCTACardTheme(type) {
  switch (type) {
    case 'multilink with image':
      return CTA_CARD_THEMES.MULTILINK_IMAGE;
    case 'multilink with icon':
      return CTA_CARD_THEMES.MULTILINK_ICON;
    case 'text and image':
      return CTA_CARD_THEMES.TEXT_IMAGE;
    case 'text only':
      return CTA_CARD_THEMES.TEXT_ONLY;
    case 'title only':
      return CTA_CARD_THEMES.TITLE_ONLY;
    default:
      return null;
  }
}

/**
 * Feature Card Container themes defintiion
 */
export const FEATURE_CARD_CONTAINER_THEMES = {
  GET_STARTED: 'get started',
};

export function getFeatureCardContainerTheme(type) {
  switch (type) {
    case 'get started':
      return FEATURE_CARD_CONTAINER_THEMES.GET_STARTED;
    default:
      return null;
  }
}
