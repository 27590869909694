import styled, { css } from 'styled-components';

import { container, columnsGutter } from 'styles/grid';
import { minWidth } from 'styles/media-queries';
import { fontWeights } from 'styles/variables';
import { Headline, Paragraph, Cta as CtaComponent } from 'components/atoms';
import { getModifierStyles } from 'utils/get-modifier-styles';
import {
  fadeContentSlideUp,
  ACTIVATE_ANIMATION_CLASS,
} from 'styles/animations';
import { gray50 } from '@spotify-internal/encore-web';
import { black } from 'styles/colors';

// Modifier styles
const ROOT_LESS_PADDING = css`
  margin: 4rem auto; // for transparent background we are allowing collapsing margins as per designs
  padding-bottom: 0;
  padding-top: 0;

  ${minWidth.lg`
    margin: 8rem auto; // for transparent background we are allowing collapsing margins as per designs
    padding-bottom: 0;
    padding-top: 0;
  `}
`;

export const MODIFIERS = {
  lessPadding: 'lessPadding',
};

// Style modifier map
const STYLE_MAP = {
  Root: {
    [MODIFIERS.lessPadding]: ROOT_LESS_PADDING,
  },
};

export const Root = styled.div`
  background-color: ${props => props.backgroundColor || 'transparent'};
  padding-bottom: 8.8rem;
  padding-top: 7.2rem;

  ${minWidth.lg`
    padding-bottom: 10rem;
    padding-top: 10rem;

    ${props =>
      props.displaySplitView &&
      `
    padding-top: 1rem;
    padding-bottom: 1rem;
    `}
  `}

  ${props => props.modifier && getModifierStyles(props, STYLE_MAP.Root)}
`;

export const Wrapper = styled.div`
  ${container}
  ${columnsGutter}
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  ${minWidth.lg`
    grid-column: 3 / 12;

    ${props =>
      props.displaySplitView &&
      `
       padding-right:0;
      padding-left: 0;
    `}
  `}
`;

export const Quote = styled.div`
  grid-column: 1 / 13;
  ${fadeContentSlideUp.setup}

  ${minWidth.lg`
    grid-column: 3 / 11;

    ${props =>
      props.isArticleMargin &&
      `
      grid-column: 4  / 10;
    `}

    ${props =>
      props.displaySplitView &&
      `
      grid-column: 1 / 12;
      justify-items: center;
      align-items: center;
    `}
  `}

  .${ACTIVATE_ANIMATION_CLASS} & {
    ${fadeContentSlideUp.play}
  }
`;

export const TextQuote = styled(Headline).attrs(({ quoteStyling }) => ({
  tag: 'p',
  styling: quoteStyling,
}))`
  color: ${props => props.color || black};
  margin-bottom: 2.2rem;

  ${props =>
    props.centered &&
    `
      text-align: center;
    `}

  &::before {
    content: '\\201C'; // left double quotation mark
    display: block;
  }

  &::after {
    content: '\\201D'; // left double quotation mark
    display: inline-block;
  }

  ${minWidth.lg`
    margin-bottom: 5.3rem;
  `}
`;

export const Author = styled(Paragraph)`
  color: ${props => props.theme.color || black};
  font-weight: ${fontWeights.black};
  letter-spacing: -0.025rem;

  ${props =>
    props.theme.centered &&
    `
    text-align: center;
  `}

  ${minWidth.lg`
    letter-spacing: -0.05rem;
  `}
`;

export const AuthorInfo = styled(Author)`
  color: ${gray50};
  font-weight: ${fontWeights.light};
  letter-spacing: normal;

  ${minWidth.lg`
    letter-spacing: -0.05rem;
  `}
`;

export const Blockquote = styled.blockquote`
  margin: 0;
`;

export const Cta = styled(CtaComponent).attrs({
  type: 'textLink',
})`
  display: inline-block;
  color: ${props => props.theme.color || black};
  margin-top: 1.6rem;

  ${minWidth.lg`
    margin-top: 2.4rem;
  `}
`;
