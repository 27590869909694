import styled from 'styled-components';

import { minWidth } from 'styles/media-queries';
import { fontWeights } from 'styles/variables';

export const P1 = styled.p`
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.56rem;
  font-weight: ${fontWeights.normal_p};

  ${minWidth.lg`
    font-size: 2rem;
    line-height: 3.2rem;
  `}
`;

export const P1_BOLD = styled(P1)`
  font-weight: ${fontWeights.extra_bold};
`;

export const P1_CAPTION = styled.p`
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 2.24rem;
  font-weight: ${fontWeights.normal_p};

  ${minWidth.lg`
    font-size: 1.6rem;
    line-height: 2.24rem;
  `}
`;
