import styled from 'styled-components';

import {
  Cta as CtaComponent,
  Eyebrow as EyebrowComponent,
  Headline as HeadlineComponent,
  Paragraph,
} from 'components/atoms';
import { hexToRGB } from 'utils/hex-to-rgb';

import { levels } from 'styles/z-index';
import { container, columnsGutter } from 'styles/grid';
import { animations, fontWeights } from 'styles/variables';
import { minWidth } from 'styles/media-queries';
import { black, darkGray, white } from 'styles/colors';

export const Root = styled.div`
  background-color: ${props => props.backgroundColor};
  padding: 5.6rem 0 8rem;
`;

export const Wrapper = styled.div`
  ${container}
  ${columnsGutter}

  display: grid;
  grid-template-columns: repeat(12, 1fr);
`;

export const Container = styled.div`
  grid-column: 1 / 13;
  background-color: ${black};
  color: ${white};
  padding: 4rem;
  border-radius: 2rem;

  ${minWidth.lg`
    grid-column: 4 / 10;
  `}
`;

export const Eyebrow = styled(EyebrowComponent)`
  color: ${props => props.textColor};
  margin-bottom: 1.6rem;

  ${minWidth.lg`
    margin-bottom: 2.4rem;
  `}
`;

export const Title = styled(HeadlineComponent).attrs({
  tag: 'h5',
  styling: 'h5',
})`
  color: ${props => props.textColor};
  margin-bottom: 2.4rem;
  font-weight: ${fontWeights.bold};

  ${minWidth.lg`
    margin-bottom: 4rem;
  `}
`;

export const Description = styled(Paragraph)`
  color: ${props => props.textColor};
  margin-bottom: 2.4rem;

  ${minWidth.lg`
    margin-bottom: 6rem;
  `}
`;

export const Audio = styled.audio``;

export const CAPTIONS_BOX_SIZE = 150;

export const CaptionsWrapper = styled.div`
  height: ${props =>
    props.hasCaptions ? `${CAPTIONS_BOX_SIZE / 4}rem` : '0rem'};
  min-height: 4rem;
  overflow: hidden;
  position: relative;
  transition: height ${animations.defaultTransition};
  z-index: ${levels.base};
  margin-top: 3rem;

  &:before {
    background: linear-gradient(
      180deg,
      ${props => props.backgroundColor} 26.56%,
      ${props => hexToRGB(props.backgroundColor, 0)} 100%
    );
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: ${levels.highlight};
  }

  &:after {
    background: linear-gradient(
      0deg,
      ${black} 26.56%,
      ${hexToRGB(black, 0)} 100%
    );
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 45%;
    z-index: ${levels.highlight};
  }
`;

export const Captions = styled.div`
  color: ${white};
  display: flex;
  flex-direction: column;
  transform: translateY(${props => props.translateY}px);
  transition: transform ${animations.defaultTransition};
`;

export const Caption = styled(HeadlineComponent).attrs({
  tag: 'div',
  styling: 'h5',
})`
  height: ${props => (props.outerHeight ? `${props.outerHeight}px;` : 'auto')};
  font-weight: ${fontWeights.bold};
  margin-bottom: 2.4rem;

  ${minWidth.lg`
    margin-bottom: 3.2rem;
  `}

  & pre {
    margin: 0;
    white-space: pre-wrap;
  }
`;

export const Cta = styled(CtaComponent).attrs({
  type: 'textlink',
})`
  color: ${props => props.textColor};
`;

export const ImageTitleContainer = styled.div`
  background-color: ${darkGray};
  color: ${white};
  border-radius: 2rem;
  padding: 2rem;
  display: flex;

  img {
    height: 11rem;
    width: 11rem;
  }
`;

export const TitleCaptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-left: 3rem;
`;

export const Player = styled.div`
  align-items: center;
  background-color: ${darkGray};
  border-radius: 0.6rem;
  display: flex;
  height: 9rem;

  ${minWidth.lg`
    border-radius: 0.8rem;
  `}
`;

export const PlayerControls = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-left: 1.6rem;
  padding-right: 1.6rem;

  ${minWidth.lg`
    padding-left: 2rem;
    padding-right: 2rem;
  `}
`;
