import styled from 'styled-components';
import { Headline, Icon, Paragraph } from 'components/atoms';
import {
  StatCard,
  CtaBanner as CtaBannerComponent,
  Footnotes as FootnotesComponent,
} from 'components/molecules';
import { columnsGutter, container } from 'styles/grid';
import { minWidth, maxWidth } from 'styles/media-queries';
import { opacityBlack50 } from '@spotify-internal/encore-web';
import { spacer2, spacer4, spacer5, spacer64 } from 'styles/spacers';
import { black, white20OpacityOnBlack } from 'styles/colors';

// Styled components
export const SectionLine = styled.div`
  border-top: 1px solid ${white20OpacityOnBlack};
  margin: ${spacer64} 0;
`;

export const TrendDetailsOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 20;
  background-color: ${opacityBlack50};
  overflow-y: scroll;
`;

export const Container = styled.div`
  ${container}
  ${columnsGutter}

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  position: static;
  top: 5%;
`;

export const ButtonContainer = styled.div`
  grid-column: 12;
  padding: ${spacer2};
  position: sticky;
  top: 0;
`;

export const DetailsContainer = styled.div`
  ${container}

  background-color: ${props => props.theme.accentColor || black};
  grid-column: span 12;
  border-radius: 24px;
  padding-bottom: ${spacer4};
  margin-bottom: 5%;
`;

export const Navigation = styled.div`
  display: flex;
`;

export const NavigationContainer = styled.section`
  margin-top: ${spacer4};
  svg {
    width: 3rem;
    height: 3rem;
  }
`;

export const NavItem = styled.button`
  display: flex;
  align-items: center;
  text-decoration: underline;
  color: ${black};
  font-weight: 500;
  line-height: 1.6rem;
  width: 50%;

  &.noshow {
    visibility: hidden;
  }

  &.next {
    justify-content: right;
  }
`;

export const Arrow = styled(Icon)`
  color: ${black};
  margin: 0px ${spacer2};

  &.left {
    svg {
      transform: rotate(-180deg);
    }
  }
`;

export const TitleContainer = styled.section`
  text-align: center;
  color: ${black};
  margin-top: ${spacer5};
`;

export const SectionContainer = styled.section`
  margin-top: ${spacer5};
`;

export const Image = styled.div`
  background-image: ${props => `url(${props['data-src']})`};
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  height: 50vh;
`;

export const DescriptionContainer = styled.section`
  margin-top: ${spacer5};
  text-align: left;
  color: ${black};

  ${minWidth.lg`
    margin: ${spacer5};
    text-align: center;
  `}
`;

export const InsightTitle = styled(Paragraph)`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  color: ${black};
`;

export const InsightsGrid = styled.div`
  margin: ${spacer4} 0;
  display: grid;
  grid-gap: 24px;

  ${minWidth.md`
    grid-template-columns: repeat(${props =>
      props.length < 2 ? `${props.length}` : `2`}, 1fr);
  `}
  ${minWidth.lg`
    grid-template-columns: repeat(${props =>
      props.length < 3 ? `${props.length}` : `3`}, 1fr);
  `}
`;

export const InsightsStatCard = styled(StatCard)`
  flex-direction: column;
  aspect-ratio: 3 / 4;
  width: 100%;
  h3 {
    ${maxWidth.xl`
      font-size: 2.4rem;
      line-height: 3rem;
    `}
    &.bold-stat {
      font-size: 8rem;
      line-height: 6rem;
      letter-spacing: -0.2rem;

      ${minWidth.xl`
        font-size: 10rem;
        line-height: 7.5rem;
        letter-spacing: -0.17rem;
      `}
    }
  }
`;

export const CtaTitle = styled(Headline)`
  text-align: center;
  color: ${black};
`;

export const CtaGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: ${spacer5};
  .cta-banner {
    padding: 0;
  }
`;

export const CtaBanner = styled(CtaBannerComponent)`
  color: ${props => props.theme.accentColor || black};
`;

export const Footnotes = styled(FootnotesComponent)`
  padding-top: 2rem;
`;
