import styled from 'styled-components';

import { container, columnsGutter } from 'styles/grid';
import { minWidth } from 'styles/media-queries';
import { levels } from 'styles/z-index';
import {
  gray50,
  solidBlack0,
  solidWhite200,
} from '@spotify-internal/encore-web';

export const Banner = styled.section`
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: ${levels.prompt};

  ${minWidth.lg`
    ${container}
    ${columnsGutter}

    bottom: 8rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    left: 0;
    pointer-events: none;
    right: 0;
  `}
`;

export const Content = styled.div`
  background-color: ${solidBlack0};
  border-top: 0.1rem solid ${gray50};
  color: ${solidWhite200};
  padding: 3.2rem;
  position: relative;

  ${minWidth.lg`
    border: 0.1rem solid ${gray50};
    grid-column: span 5;
    padding: 4rem;
    pointer-events: all;
  `}
`;

export const Copy = styled.div.attrs({ tabIndex: 0 })`
  div {
    a {
      color: ${solidWhite200};
    }

    & > p {
      margin-bottom: 0.8rem;

      &:last-child {
        margin-bottom: 0;
      }

      ${minWidth.lg`
      margin-bottom: 1.6rem;
    `}
    }
  }
`;

export const Close = styled.button`
  font-size: 0;
  height: 2.4rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 2.4rem;

  div,
  svg {
    color: ${solidWhite200};
    height: inherit;
    width: inherit;
  }

  ${minWidth.lg`
    right: 2rem;
    top: 2rem;
  `}
`;
