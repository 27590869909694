import React, { useState, useEffect } from 'react';

import kebabCase from 'lodash/kebabCase';

import { Card } from 'components/molecules/card';
import { eventTrack, CURATED_LIST_CTA_CLICK } from 'utils/google-tag-manager';
import { getPageEyebrow } from 'utils/get-page-eyebrow';
import { useImageSupport } from 'utils/use-image-support';

import { ForegroundImageType } from 'constants/common-proptypes';

import CuratedList, { getDefaultBackground } from './CuratedList';
import * as Styled from './CuratedList.styled';

const VARIATIONS = {
  1: 'oneUp',
  2: 'twoUp',
};

type VARIATIONS_KEY = keyof typeof VARIATIONS;

const PageCuratedList = ({
  data = [],
  title,
  isFilterable,
  removeTopPadding = false,
  backgroundColor,
}: PageCuratedListProps) => {
  const pages = data;

  if (!pages.length) return null;

  // For filter bars, cards should maintain 3x3 style
  const modifier = isFilterable
    ? null
    : VARIATIONS[pages.length as VARIATIONS_KEY] || null;
  const [currentPages, setCurrentPages] = useState<DisplayPage[]>([]);
  const { queryUrl, fileExt } = useImageSupport();

  useEffect(() => {
    if (fileExt) {
      const pagesWithDefaultImage = pages.map((page: any) => {
        const { url, description } = getDefaultBackground(fileExt);
        const cardImage = page.meta?.image
          ? {
              description: page.meta?.image.description,
              url: page.meta?.image[queryUrl],
            }
          : {
              description,
              url: url!,
            };

        return { ...page, cardImage };
      });

      setCurrentPages(pagesWithDefaultImage);
    }
  }, [fileExt, data]);

  return (
    <CuratedList
      title={title}
      modifier={modifier || ''}
      removeTopPadding={removeTopPadding}
      backgroundColor={backgroundColor}
    >
      {currentPages.map(page => {
        const {
          navigationTag: { slug: urlTag },
          cardImage,
          hero,
          slug,
        } = page;
        const eyebrow = getPageEyebrow(page);
        const onCardClick: React.MouseEventHandler<HTMLButtonElement> = event => {
          eventTrack(CURATED_LIST_CTA_CLICK, {
            event,
            title,
            eyebrow,
            length: pages.length,
          });
        };

        return (
          <Styled.CardContainer
            isFilterPageType={isFilterable}
            key={kebabCase(`${hero?.title} ${eyebrow}`)}
          >
            <Card
              image={cardImage}
              title={hero?.title}
              tag={urlTag}
              slug={slug || ''}
              modifier={modifier || ''}
              onClick={onCardClick}
              type="curatedList"
            />
          </Styled.CardContainer>
        );
      })}
    </CuratedList>
  );
};

interface PageCuratedListProps {
  data: PageProps[];
  title?: string;
  isFilterable?: boolean;
  removeTopPadding?: boolean;
  backgroundColor?: string;
}

interface PageProps {
  title: string;
  meta: {
    image: ForegroundImageType;
    metaTitle: string;
    metaDescription: string;
  };
  hero: {
    title: string;
  };
  navigationTag: {
    slug: string;
  };
  slug: string;
}

interface DisplayPage {
  cardImage: {
    url: string;
    description: string;
  };
  title: string;
  meta: {
    image: ForegroundImageType;
    metaTitle: string;
    metaDescription: string;
  };
  hero: {
    title: string;
  };
  navigationTag: {
    slug: string;
  };
  slug: string;
}

export default PageCuratedList;
