import styled, { css } from 'styled-components';
import { Eyebrow, Headline, ArrowCta as CtaComponent } from 'components/atoms';
import { getThemeColor } from 'utils/get-theme-color';
import { minWidth, maxWidth } from 'styles/media-queries';
import { Markdown } from 'components/molecules';
import { animations, cssFragments, ratioPercentage } from 'styles/variables';
import { getScaledImageUrl } from 'utils/get-scaled-image-url';

import { columnsGutter, container, totalWidth } from 'styles/grid';
import { multiple } from 'components/atoms/arrow-cta/ArrowCta.styled';

// Styled components
export const FeatureCard = styled.section`
  background-color: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => getThemeColor(theme.themeKey)};
  overflow: hidden;

  ${maxWidth.md`
    padding: 4rem 0;
  `}

  ${minWidth.lg`
    padding-top: 7.2rem;
  `}
`;

export const Container = styled.div`
  ${container}
  ${minWidth.lg`
    ${columnsGutter}

    display: grid;
    grid-template-columns: repeat(12, 1fr);

    ${({ theme }) =>
      theme.hasImage &&
      `
      align-items: center;
    `}
  `}
`;

/* Mobile container definition */
const mobileContainer = css`
  margin: auto;

  ${minWidth.lg`
    width: ${totalWidth.lg / 3}px;
    height: ${totalWidth.lg / 2.75}px;
 `}

  ${minWidth.xl`
      width: ${totalWidth.xl / 3}px;
      heigt: ${totalWidth.xl / 2.75}px;
  `}
`;

export const MobileWrapper = styled.div`
  ${maxWidth.md`

    display: block;
    margin-bottom: 4rem;
    overflow: hidden;
    padding-top: 125%;
    position: relative;

    &::before {
      background-image: ${props =>
        `url(${getScaledImageUrl(
          props['data-src'],
          { w: 1200, h: 1600 },
          'Free',
        )})`};


      background-position: 50% center;
      background-repeat: no-repeat;
      background-size: cover;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: transform ${animations.defaultTransition};
      will-change: transform;
    }
    `}

  ${minWidth.lg`
    ${mobileContainer}
    position: relative;
    margin-top: 10%;
    overflow: hidden;
    grid-column: span 5;
    ${({ theme }) =>
      theme.imageRight &&
      `
      grid-column: 8 / span 5;
    `}
  `}
`;

export const MobileChrome = styled.img.attrs(props => ({
  role: props.alt ? 'img' : 'presentation',
  'aria-label': props.alt,
}))`
  display: block;
  margin-bottom: 4rem;
  height: 120%;
  width: 100%;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  &.hovering {
    &::before {
      transform: scale(1.1);
    }
  }
`;

/* Desktop container definition */
const desktopContainer = css`
  margin: auto;

  ${minWidth.lg`
    width: ${totalWidth.lg / 2.5}px;
    height: ${totalWidth.lg / 2.5}px;
  `}

  ${minWidth.xl`
    width: ${totalWidth.xl / 2.5}px;
    height: ${totalWidth.xl / 2.5}px;
  `}
`;

export const DesktopWrapper = styled.div`
  ${maxWidth.md`

    display: block;
    margin-bottom: 4rem;
    overflow: hidden;
    padding-top: ${ratioPercentage.fourThree}%;
    position: relative;

    &::before {
      ${cssFragments.backgroundScaledImage}

      background-position: 50% center;
      background-repeat: no-repeat;
      background-size: cover;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: transform ${animations.defaultTransition};
      will-change: transform;
    }
  `}

  ${minWidth.lg`
    ${desktopContainer}
    position: relative;
    margin: 10% 0% 5% -35%;

    ${({ theme }) =>
      theme.imageRight &&
      `
      margin: 10% -35% 5% 0%;
      grid-column: 8 / span 5;
    `}
  `}
`;

export const DesktopChrome = styled.img.attrs(props => ({
  role: props.alt ? 'img' : 'presentation',
  'aria-label': props.alt,
}))`
  display: block;
  margin-bottom: 4rem;
  height: 100%;
  width: 130%;
  position: absolute;

  &.hovering {
    &::before {
      transform: scale(1.1);
    }
  }

  ${minWidth.lg`
    grid-column: span 2;
    margin-bottom: 0;
  `}
`;

export const Content = styled.div`
  ${minWidth.lg`
    ${columnsGutter}

    display: flex;
    grid-template-columns: repeat(12, 1fr);
    flex-direction: column;
    grid-column: 7 / span 5;

    ${({ theme }) =>
      !theme.imageType &&
      `
      margin-left: -10rem;
      grid-column: 4 / span 5;
    `}

    ${({ theme }) =>
      theme.imageRight &&
      `
      margin-left: -10rem;
      grid-column: 3 / span 5;
      grid-row: 1;
    `}
  `}
`;

export const HeadlineContainer = styled.div`
  margin-bottom: 1.6rem;

  ${minWidth.lg`
    grid-column: span 5;
    margin-bottom: 0;

    ${({ theme }) =>
      theme.hasImage &&
      `
      margin-bottom: 2.4rem;
    `}
  `}
`;

export const TitleEyebrow = styled(Eyebrow)`
  margin-bottom: 0.8rem;

  ${minWidth.lg`
    margin-bottom: 2.4rem;
  `}
`;

export const Title = styled(Headline)`
  color: ${({ theme }) => theme.titleColor || getThemeColor(theme.themeKey)};
`;

export const DescriptionContainer = styled.div`
  ${minWidth.lg`
    grid-column: 7 / span 5;
  `}

  li {
    font-weight: bold;
  }
`;

export const CtasContainer = styled.div`
  margin: 3.2rem 0 2rem 0;
`;

export const Cta = styled(CtaComponent)`
  ${multiple};
`;

export const ColumnList = styled(Markdown)`
  margin: 2.4rem 0 5rem 0;
`;
