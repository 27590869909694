import React from 'react';
import PropTypes, { any } from 'prop-types';
import { ThemeProvider } from 'styled-components';
import Link from 'next/link';
import { Eyebrow } from 'components/atoms';
import * as Styled from './ListItem.styled';

/**
 * List Item component
 * @param {String} eyebrow eyebrow of list item
 * @param {String} title title of list item
 * @param {String} url url of list item
 * @param {boolean} showBackgroundColor background color or transparent
 * @param {String} textColor color of text
 * @param {Object} image image details for list item
 * @returns {ReactElement}
 */
const ListItem = ({
  eyebrow = '',
  title = '',
  url = '',
  showBackgroundColor = true,
  textColor = '',
  image = {},
}) => {
  return (
    <ThemeProvider
      theme={{
        showBackgroundColor,
        textColor,
        image,
      }}
    >
      <Link href={(url && url) || '/'} passHref>
        <Styled.Container className="list-item">
          {image && image.url && (
            <Styled.ImageContainer
              image={image && image.url}
              className="image-container"
            />
          )}
          <Styled.TitleContainer>
            {eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
            <Styled.Title styling="h4" tag="h4">
              {title}
            </Styled.Title>
          </Styled.TitleContainer>
        </Styled.Container>
      </Link>
    </ThemeProvider>
  );
};

ListItem.propTypes = {
  /**
   * eyebrow for list item
   */
  eyebrow: PropTypes.string,
  /**
   * title of list item
   */
  title: PropTypes.string,
  /**
   * article url to direct to
   */
  url: PropTypes.string,
  /**
   * optional background color
   */
  showBackgroundColor: PropTypes.bool,
  /**
   * image to use in list item
   */
  image: PropTypes.objectOf(any),
  /**
   * optional text color
   */
  textColor: PropTypes.string,
};

export default ListItem;
