import React, { useState } from 'react';
import { kebabCase } from 'lodash';
import { getLinkProps } from 'utils/get-link-props';
import ContentTile from 'components/molecules/content-tile/ContentTile';
import PropTypes, { any } from 'prop-types';
import { ICONS } from 'constants/icons';
import {
  CONTENT_TILE_THEMES,
  getContentTileTheme,
} from 'utils/get-theme-selector';
import { ThemeProvider } from 'styled-components';
import TrendDetails from '../trend-details/TrendDetails';
import * as Styled from './TrendGrid.styled';

/**
 * Renders the Trend Grid.
 * @param {Array} trends
 * @param {Object} cta
 * @param {string|undefined} title
 * @param {string|undefined} description
 * @param {boolean} scrollVertical
 * @returns {ReactElement}
 */
const TrendGrid = ({
  trends = [],
  cta = null,
  title = '',
  description = '',
  scrollVertical = true,
}) => {
  const [activeTrendIndex, setActiveTrendIndex] = useState(undefined);

  const handlePrevious = currIndex => {
    if (currIndex > 0) {
      setActiveTrendIndex(currIndex - 1);
    } else {
      setActiveTrendIndex(undefined);
    }
  };

  const handleNext = currIndex => {
    if (currIndex < trends.length - 1) {
      setActiveTrendIndex(currIndex + 1);
    } else {
      setActiveTrendIndex(undefined);
    }
  };

  const renderTile = (trend, index, className = '') => {
    return (
      <ContentTile
        tile={trend}
        key={kebabCase(`${title} ${index}`)}
        handleClick={
          getContentTileTheme(trend.themeSelector?.toLowerCase()) ===
          CONTENT_TILE_THEMES.TREND
            ? () => setActiveTrendIndex(index)
            : null
        }
        className={className}
      />
    );
  };

  const renderTrendGridColumns = () => {
    // create list of trends in 3 columns for the offset grid
    const col1 = trends.filter((_, index) => index % 3 === 0);
    const col2 = trends.filter((_, index) => index % 3 === 1);
    const col3 = trends.filter((_, index) => index % 3 === 2);
    return (
      <>
        <Styled.TrendGridCol key={1} colNumber="1">
          {col1.map((trend, index) =>
            renderTile(
              trend,
              index * 3,
              (index * 3 + 1) % 2 ? 'long' : 'short',
            ),
          )}
        </Styled.TrendGridCol>
        <Styled.TrendGridCol key={2} colNumber="2">
          {col2.map((trend, index) =>
            renderTile(
              trend,
              index * 3 + 1,
              (index * 3 + 2) % 2 ? 'long' : 'short',
            ),
          )}
        </Styled.TrendGridCol>
        <Styled.TrendGridCol key={3} colNumber="3">
          {col3.map((trend, index) =>
            renderTile(
              trend,
              index * 3 + 2,
              (index * 3 + 3) % 2 ? 'long' : 'short',
            ),
          )}
        </Styled.TrendGridCol>
      </>
    );
  };

  return (
    <ThemeProvider
      theme={{
        hasTitle: title,
        scrollVertical,
      }}
    >
      <Styled.Container>
        <Styled.TitleContainer>
          {title && <Styled.TrendTitle tag="h2" styling="h1" text={title} />}
          {description && (
            <Styled.TrendDescription styling="p1">
              {description}
            </Styled.TrendDescription>
          )}
          {cta && (
            <Styled.Cta
              type={cta.type}
              isDarkTheme
              overrideFunctionality={cta.overrideFunctionality}
              {...getLinkProps(cta.url)}
              iconName={
                cta.title.toLowerCase().includes('download')
                  ? ICONS.DOWNLOAD
                  : null
              }
            >
              {cta.title}
            </Styled.Cta>
          )}
        </Styled.TitleContainer>
        <Styled.TrendGrid scrollVertical={scrollVertical}>
          {renderTrendGridColumns()}
        </Styled.TrendGrid>
        {activeTrendIndex !== undefined && (
          <TrendDetails
            trend={trends[activeTrendIndex].trendInfo}
            index={activeTrendIndex}
            previousTitle={
              activeTrendIndex > 0
                ? trends[activeTrendIndex - 1].title
                : undefined
            }
            handlePrevious={() => handlePrevious(activeTrendIndex)}
            nextTitle={
              activeTrendIndex < trends.length - 1
                ? trends[activeTrendIndex + 1].title
                : undefined
            }
            handleNext={() => handleNext(activeTrendIndex)}
            handleClose={() => setActiveTrendIndex(undefined)}
          />
        )}
      </Styled.Container>
    </ThemeProvider>
  );
};

TrendGrid.propTypes = {
  /**
   * List of trend cards
   */
  trends: PropTypes.arrayOf(any),
  /**
   * optional cta object
   */
  cta: PropTypes.objectOf(any),
  /**
   * title of the trend grid
   */
  title: PropTypes.string,
  /**
   * description of the trend grid
   */
  description: PropTypes.string,
  /**
   * true if we want to scroll vertically on mobile
   */
  scrollVertical: PropTypes.bool,
};

export default TrendGrid;
