import styled from 'styled-components';
import { Cta, Paragraph } from 'components/atoms';
import { minWidth } from 'styles/media-queries';
import { black } from 'styles/colors';
import {
  sectionMargins,
  spacer3,
  spacer16,
  spacer24,
  spacer80,
  spacer64,
  articleMargins,
} from 'styles/spacers';
import { container } from 'styles/grid';

export const CtaBanner = styled.div`
  ${props => !props.theme.fullWidth && container}
  ${props => (props.theme.isArticleMargin ? articleMargins : sectionMargins)}
  ${minWidth.lg`
    ${props =>
      props.theme.isArticleMargin &&
      `
      display: grid;
      grid-template-columns: repeat(12, 1fr);
    `}
  `}
`;

export const CtaContent = styled.div`
  border-radius: 12px;
  text-align: left;

  ${minWidth.lg`
    ${props =>
      props.theme.isArticleMargin &&
      `
      grid-column: 3 / 11;
    `}
  `}

  ${props =>
    props.theme.backgroundColor &&
    `
    background-color: ${props.theme.backgroundColor};
    padding: ${spacer24} ${spacer16};
  `}
  ${props =>
    props.theme.textColor &&
    `
    color: ${props.theme.textColor};
  `}
  ${minWidth.lg`
    text-align: ${props => (props.theme.centered ? `center` : `left`)};
    ${props =>
      props.theme.backgroundColor &&
      `
      padding: ${spacer80} ${spacer64};
    `}
  `}
`;

export const CtaDescription = styled(Paragraph)`
  margin: ${spacer3} ${props => (props.theme.centered ? `auto` : `0`)};

  ${minWidth.lg`
    width: 80%;
  `}
`;

export const CtasContainer = styled.div`
  margin-top: 1.6rem;
  display: flex;
  gap: 1.2rem;
  ${props => props.theme.centered && `justify-content: center;`}

  ${minWidth.lg`
    margin-top: 2.4rem;
  `}
`;

export const CtaButton = styled(Cta)`
  margin-top: ${spacer3};
  border-color: ${black};
  ${props =>
    props.theme.textColor &&
    `
    background-color: ${props.theme.textColor};
  `}
  ${props =>
    props.theme.backgroundColor &&
    `
    color: ${props.theme.backgroundColor};
  `}
`;
