// default env

let env = {
  // access tokens gives access to *only* the development Contentful environment so are not a security risk
  CONTENTFUL_ACCESS_TOKEN: 'TyVFOJKiWSqtDrb7A-IDPmE__dUZ_b4kKq0f9Uzi5jQ',
  CONTENTFUL_ENVIRONMENT: 'staging',
  CONTENTFUL_PREVIEW: 'true',
  CONTENTFUL_SPACE_ID: 'tvhwpwv117no',
  CACHE_ENABLED: 'false',
  SPOTIFY_CLIENT_ID: '4e02d7195f814c8996abc710e9a5bc60',
  SPOTIFY_TIL_CLIENT_ID: 'fbb99b06d4854d9685cdc7b8920acbc5',
  GA_TRACK_ID: 'UA-5784146-82',
  RECAPTCHA_SITE_KEY: '6LdbOMcgAAAAAMDzxYshcq8zp-JiBD28E5zPqt5f',
  RECAPTCHA_SECRET: '6LdbOMcgAAAAAMs_5NGrwkr4XUKPthAA0ahS-mvI',
  MPARTICLE_API_KEY: 'us1-4e7de0a30a31c547a93fdd338cd23472',
  SENTRY_DNS:
    'https://63802abf196144bf8a4d527463f313e8@o22381.ingest.sentry.io/5924516',
};

if (process.env.CONTENTFUL_ENV === 'staging') {
  env = {
    CONTENTFUL_ACCESS_TOKEN: process.env.CONTENTFUL_ACCESS_TOKEN,
    CONTENTFUL_ENVIRONMENT: 'staging',
    CONTENTFUL_SPACE_ID: 'tvhwpwv117no',
    CACHE_ENABLED: 'false',
    SPOTIFY_CLIENT_ID: '4e02d7195f814c8996abc710e9a5bc60',
    SPOTIFY_TIL_CLIENT_ID: 'fbb99b06d4854d9685cdc7b8920acbc5',
    GA_TRACK_ID: 'UA-5784146-82',
    RECAPTCHA_SITE_KEY: process.env.RECAPTCHA_SITE_KEY,
    RECAPTCHA_SECRET: process.env.RECAPTCHA_SECRET,
    WEB_API_SECRET: process.env.WEB_API_SECRET,
    SENTRY_DNS:
      'https://63802abf196144bf8a4d527463f313e8@o22381.ingest.sentry.io/5924516',
  };
}

if (process.env.CONTENTFUL_ENV === 'preview') {
  env = {
    CONTENTFUL_ACCESS_TOKEN: process.env.CONTENTFUL_ACCESS_TOKEN,
    CONTENTFUL_ENVIRONMENT: 'master',
    CONTENTFUL_PREVIEW: 'true',
    CONTENTFUL_SPACE_ID: 'tvhwpwv117no',
    CACHE_ENABLED: 'false',
    SPOTIFY_CLIENT_ID: '4e02d7195f814c8996abc710e9a5bc60',
    SPOTIFY_TIL_CLIENT_ID: 'fbb99b06d4854d9685cdc7b8920acbc5',
    GA_TRACK_ID: 'UA-5784146-82',
    RECAPTCHA_SITE_KEY: process.env.RECAPTCHA_SITE_KEY,
    RECAPTCHA_SECRET: process.env.RECAPTCHA_SECRET,
    WEB_API_SECRET: process.env.WEB_API_SECRET,
    SENTRY_DNS:
      'https://63802abf196144bf8a4d527463f313e8@o22381.ingest.sentry.io/5924516',
  };
}

if (process.env.CONTENTFUL_ENV === 'production') {
  env = {
    CONTENTFUL_ACCESS_TOKEN: process.env.CONTENTFUL_ACCESS_TOKEN,
    CONTENTFUL_ENVIRONMENT: 'master',
    CONTENTFUL_SPACE_ID: 'tvhwpwv117no',
    CACHE_ENABLED: 'true',
    SPOTIFY_CLIENT_ID: '4e02d7195f814c8996abc710e9a5bc60',
    SPOTIFY_TIL_CLIENT_ID: 'fbb99b06d4854d9685cdc7b8920acbc5',
    GA_TRACK_ID: 'UA-5784146-56',
    RECAPTCHA_SITE_KEY: process.env.RECAPTCHA_SITE_KEY,
    RECAPTCHA_SECRET: process.env.RECAPTCHA_SECRET,
    MPARTICLE_API_KEY: process.env.MPARTICLE_API_KEY,
    MPARTICLE_SECRET: process.env.MPARTICLE_SECRET,
    WEB_API_SECRET: process.env.WEB_API_SECRET,
    SENTRY_DNS:
      'https://63802abf196144bf8a4d527463f313e8@o22381.ingest.sentry.io/5924516',
  };
}

Object.assign(process.env, env);

module.exports = env;
