import styled from 'styled-components';

import { container } from 'styles/grid';
import { white20OpacityOnBlack } from 'styles/colors';

export const SectionDivider = styled.section`
  ${container}
`;

export const Line = styled.div`
  border-top: 1px solid ${white20OpacityOnBlack};
`;
