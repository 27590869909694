import styled from 'styled-components';
import { Icon, Paragraph } from 'components/atoms';
import { gray } from 'styles/colors';
import { container } from 'styles/grid';
import { spacer1 } from 'styles/spacers';
import {
  ACTIVATE_ANIMATION_CLASS,
  fadeContentSlideBackUp,
  fadeContentSlideDown,
} from 'styles/animations';
import { animations } from 'styles/variables';

export const Container = styled.section`
  ${props => !props.theme.fullWidth && container};
`;

export const DropdownContainer = styled.div`
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  gap: ${spacer1};
`;

export const ArrowDown = styled(Icon)`
  transform: ${props => (props.theme.open ? `rotate(-180deg)` : `unset`)};
  transition: transform ${animations.defaultTransition};
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  path {
    stroke: ${props =>
      props.theme.textColor ? props.theme.textColor : `${gray}`};
  }
`;

export const Footnotes = styled(Paragraph)`
  color: ${props =>
    props.theme.textColor ? props.theme.textColor : `${gray}`};
  font-size: 1.5rem;
  line-height: 2rem;
`;

export const FootnotesDropdown = styled(Footnotes)`
  ${props =>
    props.theme.open
      ? fadeContentSlideDown.setup
      : fadeContentSlideBackUp.setup}
  
  &.${ACTIVATE_ANIMATION_CLASS} {
    ${props =>
      props.theme.open
        ? fadeContentSlideDown.play
        : fadeContentSlideBackUp.play}
  }
`;
