import styled from 'styled-components';
import { Icon } from 'components/atoms';
import { animations, fontWeights } from 'styles/variables';
import { darkGray, white } from 'styles/colors';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const CategoryTitle = styled.p`
  color: ${white};
  display: block;
  font-size: 1.6rem;
  font-weight: ${fontWeights.bold};
`;

export const Caret = styled(Icon)`
  color: ${white};
  margin-left: auto;
  transform: ${({ isOpen }) => `${isOpen ? 'rotate(-180deg)' : 'unset'}`};
  transition: transform ${animations.defaultTransition};
  height: 1.6rem;
  width: 1.6rem;

  div,
  svg {
    height: inherit;
    width: inherit;
  }
`;

export const Subcategories = styled.div`
  margin-bottom: 2rem;
  margin-left: 0.8rem;
  transition: transform ${animations.defaultTransition};
`;

export const CategoryGroupTitle = styled.button`
  width: 100%;
  display: flex;
  border-top: 1px solid ${darkGray};
  padding-top: 1.5rem;
  margin-bottom: 2.4rem;
`;
