import React, { useState } from 'react';
import PropTypes, { any } from 'prop-types';
import kebabCase from 'lodash/kebabCase';
import { getLinkProps } from 'utils/get-link-props';
import { ICONS } from 'constants/icons';
import { eventTrack, FOOTER_LINK_CLICK } from 'utils/google-tag-manager';
import * as Styled from './Footer.styled';

export const FooterDropdown = ({ links, groupTitle }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Styled.FooterLinkGroupTitle onClick={() => setIsOpen(!isOpen)}>
        <Styled.LinkDrop styling="h5" tag="h5" text={`__${groupTitle}__`} />
        <Styled.Caret name={ICONS.CARET_DOWN} isOpen={isOpen} />
      </Styled.FooterLinkGroupTitle>
      {isOpen && links && (
        <Styled.FooterLinkGroup>
          <Styled.LinkCollection>
            {links.map(({ title: linkTitle, url }) => {
              const { href, asLink } = getLinkProps(url);
              return (
                <Styled.LinkContainer key={kebabCase(`${linkTitle} ${url}`)}>
                  <Styled.Link
                    onClick={event => {
                      eventTrack(FOOTER_LINK_CLICK, {
                        event,
                        href: url,
                        columnName: groupTitle,
                      });
                    }}
                    href={href}
                    asLink={asLink}
                  >
                    {linkTitle}
                  </Styled.Link>
                </Styled.LinkContainer>
              );
            })}
          </Styled.LinkCollection>
        </Styled.FooterLinkGroup>
      )}
    </div>
  );
};

FooterDropdown.propTypes = {
  /**
   * Links in the link group
   */
  links: PropTypes.arrayOf(any).isRequired,
  /**
   * Link group title
   */
  groupTitle: PropTypes.string.isRequired,
};
