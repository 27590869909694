import styled from 'styled-components';

import { minWidth } from 'styles/media-queries';

import { container } from 'styles/grid';
import {
  articleMargins,
  sectionMargins,
  spacer16,
  spacer24,
  spacer48,
  spacer8,
  spacer80,
} from 'styles/spacers';
import { Headline, Cta as CtaComponent } from 'components/atoms';
import { white } from 'styles/colors';

export const TileCarousel = styled.section`
  ${props => (props.fullWidth ? `width: 100%` : container)}
  ${props => props.isArticleMargin && articleMargins}
  ${props => !props.fullWidth && !props.isArticleMargin && sectionMargins}

  ${minWidth.lg`
    ${props =>
      props.isArticleMargin &&
      `
      display: grid;
      grid-template-columns: repeat(12, 1fr);
    `}
  `}
`;

export const Container = styled.section`
  position: relative;

  ${minWidth.lg`
    ${props =>
      props.isArticleMargin &&
      `
      grid-column: 3 / 11;
    `}
  `}
`;

export const TitleContainer = styled.div`
  padding-bottom: ${spacer48};
  color: ${props => (props.$titleColor ? props.$titleColor : white)};
  ${minWidth.lg`
    padding-bottom: ${spacer80};
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  `}
`;

export const Title = styled(Headline)`
  ${minWidth.lg`
    grid-column: span 6;
  `}
`;

export const DescriptionContainer = styled.div`
  ${minWidth.lg`
    grid-column: 8 / span 5;
  `}

  ${props =>
    props.hasDescription &&
    `
    display: flex;
    gap: ${spacer24};
    flex-direction: column;
    align-items: flex-start;
  `}
`;

export const Cta = styled(CtaComponent)`
  ${minWidth.lg`
    ${props =>
      !props.hasDescription &&
      `
      float: right;
    `}
  `}
`;

export const CtaContainer = styled.div`
  ${minWidth.lg`
    grid-column: 8 / span 5;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: ${spacer8};
  margin: ${spacer16} 0;
  ${props =>
    !props.$scrollHorizontalMobile &&
    `
    display: none;
  `}
  ${minWidth.lg`
    gap: ${spacer16};
    margin: ${spacer24} 0;
    display: flex;
    ${props =>
      !props.$scrollHorizontal &&
      `
      display: none;
    `}
  `}
  p {
    color: ${props => (props.$titleColor ? props.$titleColor : white)};
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    path {
      fill: ${props => (props.$titleColor ? props.$titleColor : white)};
    }
  }
`;

export const TileContainer = styled.div`
  display: grid;
  grid-gap: ${spacer24};
  ${props =>
    props.scrollHorizontalMobile &&
    `
      grid-template-rows: 1fr;
      grid-auto-columns: max-content;
      grid-auto-flow: column;
      overflow-x: scroll;
      -ms-overflow-style: none; /* for Internet Explorer, Edge /
      scrollbar-width: none; / for Firefox */
      overflow-y: clip;

      &::-webkit-scrollbar {
        display: none;
      }
  `}

  ${minWidth.lg`
    ${props =>
      props.scrollHorizontal
        ? `
        grid-template-rows: 1fr;
        grid-auto-columns: max-content;
        grid-auto-flow: column;
        overflow-x: scroll;
        -ms-overflow-style: none; /* for Internet Explorer, Edge /
        scrollbar-width: none; / for Firefox */
        overflow-y: clip;

        &::-webkit-scrollbar {
          display: none;
        }
    `
        : `grid-template-columns: repeat(${props.length}, 1fr);`}
  `}

  .carousel-card {
    ${props =>
      props.isArticleMargin &&
      `
      .bold-stat {
        font-size: 8rem;
        line-height: 6rem;
        letter-spacing: -0.2rem;
      }
    `}
    ${props =>
      props.scrollHorizontalMobile &&
      `
      width: 80vw;
    `}

    ${minWidth.sm`
      ${props =>
        props.scrollHorizontal
          ? `height: 55rem; width: unset; aspect-ratio: 4 / 5;`
          : `width: unset; height: unset;`}
    `}
  }
`;
