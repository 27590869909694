import styled, { css } from 'styled-components';

import { minWidth } from 'styles/media-queries';
import { solidBlack0, solidWhite200 } from '@spotify-internal/encore-web';
import { fontWeights } from 'styles/variables';

export const introTypographyXsToMd = css`
  font-size: 2.4rem;
  letter-spacing: -0.05rem;
  line-height: 3.2rem;
`;
export const introTypographyLgToXl = css`
  font-size: 3rem;
  font-weight: ${fontWeights.light};
  letter-spacing: -0.1rem;
  line-height: 4rem;
`;

export const SuccessState = styled.div`
  background-color: ${({ theme }) =>
    theme.modifier === 'footer' ? `transparent` : solidBlack0};
  color: ${solidWhite200};
`;

export const Copy = styled.p`
  margin-bottom: 3.2rem;

  &:last-child {
    margin-bottom: 0;
  }
  ${minWidth.lg`
    margin-bottom: 4rem;
  `}

  ${({ theme }) =>
    theme.modifier === 'footer'
      ? `
        font-weight: ${fontWeights.normal_p};
      `
      : `
      ${introTypographyXsToMd}

      ${minWidth.lg`
        ${introTypographyLgToXl}

        margin-bottom: 4rem;
      `}
  `}
`;
