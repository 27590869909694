// @ts-nocheck
// this is not preferred when using TS but as this is just an example, we are
// using it to avoid need to convert styles/media-queries to TS

import styled from 'styled-components';
import ScrollDownArrow from 'assets/svg/scroll-down-arrow.svg';
import { minWidth } from 'styles/media-queries';
import { gray50 } from '@spotify-internal/encore-web';

export const Container = styled.div``;

export const ScrollArrow = styled(ScrollDownArrow)`
  cursor: pointer;
  width: 4.7rem;
  ${minWidth.md`
    width: 7rem;
  `}

  &:hover {
    circle {
      stroke: ${gray50};
    }
    path {
      fill: ${gray50};
    }
  }
`;
