import React from 'react';
import PropTypes, { any } from 'prop-types';
import kebabCase from 'lodash/kebabCase';
import { eventTrack, FOOTER_LINK_CLICK } from 'utils/google-tag-manager';
import * as Styled from './Footer.styled';

export const FooterLinkGroup = ({ links, groupTitle }) => {
  return (
    <Styled.LinkCollectionItem>
      <Styled.LinkCollectionTitle
        styling="h4"
        tag="h4"
        text={`__${groupTitle}__`}
      />
      <Styled.LinkCollection>
        {links &&
          links.map(({ title: linkTitle, url }) => (
            <Styled.LinkContainer key={kebabCase(`${linkTitle} ${url}`)}>
              <Styled.Link
                href={url}
                asLink={url}
                onClick={event => {
                  eventTrack(FOOTER_LINK_CLICK, {
                    event,
                    href: url,
                    columnName: groupTitle,
                  });
                }}
              >
                {linkTitle}
              </Styled.Link>
            </Styled.LinkContainer>
          ))}
      </Styled.LinkCollection>
    </Styled.LinkCollectionItem>
  );
};

FooterLinkGroup.propTypes = {
  /**
   * Links in the link group
   */
  links: PropTypes.arrayOf(any).isRequired,
  /**
   * Link group title
   */
  groupTitle: PropTypes.string.isRequired,
};
