import { track } from '@spotify-internal/track-js';

import { GTM_ID } from '../google-tag-manager/googleTagManager';

const ONE_TRUST_ID = '50da44be-0564-43df-b139-329aedcf267b';

export const initTrackJs = ({ language, market }) => {
  track({
    dataLayerPushInitial: true,
    language,
    market,
    oneTrust: true,
    oneTrustDomainScript: ONE_TRUST_ID,
    googleTagManagerId: GTM_ID,
  });
};
