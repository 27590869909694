// @ts-nocheck
// this is not preferred when using TS but as this is just an example, we are
// using it to avoid need to convert styles/media-queries to TS
import styled from 'styled-components';

import {
  Button,
  Headline,
  Paragraph,
  Eyebrow as EyebrowComponent,
  Cta as CtaComponent,
} from 'components/atoms';
import { maxWidth, minWidth } from 'styles/media-queries';
import { spacer16, spacer24, spacer5 } from 'styles/spacers';
import { black, white, white20OpacityOnBlack } from 'styles/colors';
import { fontWeights } from 'styles/variables';
import { CONTENT_TILE_THEMES } from 'utils/get-theme-selector';

export const Cta = styled(CtaComponent)`
  position: absolute;
  height: 100% !important;
  width: 100%;
  opacity: 0;
`;

// Styled components
export const ContentTile = styled.div`
  ${props =>
    props['data-src'] &&
    `background-image: url(${props['data-src']}); background-size: cover; background-position: center; background-repeat: no-repeat;`}
  border-radius: 8px;
  ${props => props.onClick && `cursor: pointer;`}
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  ${props => !props.noAspectLock && `aspect-ratio: 4 / 5;`}
  background-color: ${props => props.theme.backgroundColor || black};

  ${minWidth.lg`
    width: 100%;
  `}

  ${minWidth.sm`
    ${maxWidth.md`
      ${props =>
        props.themeType === CONTENT_TILE_THEMES.TOPIC &&
        `
        display: grid;
        grid-template-columns: repeat(12, 1fr);
      `}
    `}
  `}

  &:hover .button-arrow {
    background-color: ${white20OpacityOnBlack};
    svg {
      path {
        fill: ${white};
      }
    }
  }
`;

export const QuoteTitleContainer = styled.div`
  position: relative;
  margin-bottom: auto;
  padding: 2rem;
`;

export const QuotationMark = styled(Headline)`
  color: ${props => props.theme.textColor || black};
  font-style: italic;
  font-weight: ${fontWeights.black};
`;

export const QuoteText = styled(Headline)`
  color: ${props => props.theme.textColor || white};
  font-size: 2.5rem;
  line-height: 110%;

  ${maxWidth.sm`
    font-size: 2rem;
  `}
`;

export const QuoteSpeaker = styled(Paragraph)`
  padding: 2rem;
  font-size: 2rem;
  color: ${props => props.theme.textColor || white};

  ${maxWidth.sm`
    font-size: 1.6rem;
  `}
`;

export const FixedImageContainer = styled.div`
  padding: 2.5rem;
  border-radius: 8px;
  aspect-ratio: 3 / 2;
  ${props =>
    props['data-src']
      ? `background-image: url(${props['data-src']}); background-size: cover; background-position: center; background-repeat: no-repeat;`
      : `background-color: ${props.theme.backgroundColor}`}
  ${minWidth.sm`
    ${maxWidth.md`
      grid-column: 1 / span 6;
    `}
  `}
`;

export const EyebrowContainer = styled.div`
  padding: 1.2rem;
  flex-grow: 1;

  ${props =>
    props['data-src']
      ? `background-image: url(${props['data-src']}); background-size: cover; background-position: center; background-repeat: no-repeat;`
      : `background-color: ${props.theme.backgroundColor}; display: flex;`}

  ${props => !props.theme.hasImage && `flex-grow: 0; padding: ${spacer24};`}
  ${minWidth.lg`
    ${props => !props.theme.hasImage && `padding-bottom: 0;`}
  `}

`;

export const Eyebrow = styled(EyebrowComponent)`
  font-size: 1.6rem;
  ${props =>
    props.theme.hasImage ? `padding: 1.2rem ${spacer24}` : `padding: 0;`};
  border-radius: ${spacer5};
  background-color: ${props => props.theme.backgroundColor || black};
  color: ${props => props.theme.textColor || black};
`;

export const TitleContainer = styled.div`
  background-color: ${props => props.theme.backgroundColor || black};
  padding: ${spacer24};
  ${props => !props.theme.hasImage && `padding-top: ${spacer16}; flex-grow: 1;`}
  ${minWidth.lg`
    ${props => !props.theme.hasImage && `padding-top: 0;`}
  `}
`;

export const TopicContainer = styled.div`
  background-color: ${props => props.theme.backgroundColor || black};
  padding: 3rem 1rem;
  ${minWidth.sm`
    ${maxWidth.md`
      grid-column: 7 / span 6;
      padding: 3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
    `}
  `}
`;

export const TitleText = styled(Headline)`
  color: ${props => props.theme.textColor || white};

  strong {
    font-weight: 800;
  }
`;

export const Description = styled(Paragraph)`
  padding-top: 1.5rem;
  color: ${props => props.theme.textColor || white};
`;

export const ButtonCta = styled(Button)`
  &.button-arrow {
    margin-top: ${spacer16};
    background-color: ${props => (props.light ? white : black)};
    path {
      fill: ${props => (props.light ? black : white)};
    }

    ${minWidth.lg`
      margin-top: ${spacer24};
    `}
  }
`;
