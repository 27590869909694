import styled from 'styled-components';
import { minWidth } from 'styles/media-queries';
import { black } from 'styles/colors';
import { levels } from 'styles/z-index';
import {
  ACTIVATE_ANIMATION_CLASS,
  fadeContentSlideDown,
  fadeContentSlideBackUp,
} from 'styles/animations';

/* Styled Components */
export const Container = styled.div`
  position: relative;
  width: 100%;
  z-index: ${levels.highlight};
`;

export const ExpandedFilterBar = styled.div`
  position: absolute;
  width: 100%;
  overflow: hidden;
  height: max-content;
  background-color: ${black};

  /* animation styles */
  ${props => props.theme.resizing && `transition: none;`}

  ${props =>
    props.theme.open
      ? fadeContentSlideDown.setup
      : fadeContentSlideBackUp.setup}
  
  &.${ACTIVATE_ANIMATION_CLASS} {
    ${props =>
      props.theme.open
        ? fadeContentSlideDown.play
        : fadeContentSlideBackUp.play}
  }
`;

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;

  ${minWidth.lg`
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    transition: none;
  `}
`;

export const FilterElement = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;
