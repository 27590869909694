import LOCALE_COUNTRIES from 'constants/localeCountries';

export const countries = [
  { value: '', text: 'contactForm.country' },
  { value: 'Afghanistan', text: 'Afghanistan' },
  { value: 'Aland Islands', text: 'Aland Islands' },
  { value: 'Albania', text: 'Albania' },
  { value: 'Algeria', text: 'Algeria' },
  { value: 'American Samoa', text: 'American Samoa' },
  { value: 'Andorra', text: 'Andorra' },
  { value: 'Angola', text: 'Angola' },
  { value: 'Anguilla', text: 'Anguilla' },
  { value: 'Antarctica', text: 'Antarctica' },
  { value: 'Antigua and Barbuda', text: 'Antigua and Barbuda' },
  { value: 'Argentina', text: 'Argentina' },
  { value: 'Armenia', text: 'Armenia' },
  { value: 'Aruba', text: 'Aruba' },
  { value: LOCALE_COUNTRIES.AUSTRALIA, text: LOCALE_COUNTRIES.AUSTRALIA },
  { value: 'Austria', text: 'Austria' },
  { value: 'Azerbaijan', text: 'Azerbaijan' },
  { value: 'Bahamas', text: 'Bahamas' },
  { value: 'Bahrain', text: 'Bahrain' },
  { value: 'Bangladesh', text: 'Bangladesh' },
  { value: 'Barbados', text: 'Barbados' },
  { value: 'Belarus', text: 'Belarus' },
  { value: 'Belgium', text: 'Belgium' },
  { value: 'Belize', text: 'Belize' },
  { value: 'Benin', text: 'Benin' },
  { value: 'Bermuda', text: 'Bermuda' },
  { value: 'Bhutan', text: 'Bhutan' },
  { value: 'Bolivia', text: 'Bolivia' },
  {
    value: 'Bonaire Sint Eustatius and Saba',
    text: 'Bonaire Sint Eustatius and Saba',
  },
  { value: 'Bosnia and Herzegovina', text: 'Bosnia and Herzegovina' },
  { value: 'Botswana', text: 'Botswana' },
  { value: 'Bouvet Island', text: 'Bouvet Island' },
  { value: LOCALE_COUNTRIES.BRAZIL, text: LOCALE_COUNTRIES.BRAZIL },
  {
    value: 'British Indian Ocean Territory',
    text: 'British Indian Ocean Territory',
  },
  { value: 'Brunei Darussalam', text: 'Brunei Darussalam' },
  { value: 'Bulgaria', text: 'Bulgaria' },
  { value: 'Burkina Faso', text: 'Burkina Faso' },
  { value: 'Burundi', text: 'Burundi' },
  { value: 'Cambodia', text: 'Cambodia' },
  { value: 'Cameroon', text: 'Cameroon' },
  { value: LOCALE_COUNTRIES.CANADA, text: LOCALE_COUNTRIES.CANADA },
  { value: 'Canary Islands', text: 'Canary Islands' },
  { value: 'Cape Verde', text: 'Cape Verde' },
  { value: 'Cayman Islands', text: 'Cayman Islands' },
  {
    value: 'Central African Republic',
    text: 'Central African Republic',
  },
  { value: 'Chad', text: 'Chad' },
  { value: 'Chile', text: 'Chile' },
  { value: 'China', text: 'China' },
  { value: 'Christmas Island', text: 'Christmas Island' },
  { value: 'Cocos (Keeling) Islands', text: 'Cocos (Keeling) Islands' },
  { value: 'Colombia', text: 'Colombia' },
  { value: 'Comoros', text: 'Comoros' },
  {
    value: 'Congo (Democratic Republic of the)',
    text: 'Congo (Democratic Republic of the)',
  },
  { value: 'Congo (Republic of)', text: 'Congo (Republic of)' },
  { value: 'Cook Islands', text: 'Cook Islands' },
  { value: 'Costa Rica', text: 'Costa Rica' },
  { value: "Cote d'Ivoire", text: "Cote d'Ivoire" },
  { value: 'Croatia', text: 'Croatia' },
  { value: 'Cuba', text: 'Cuba' },
  { value: 'Curacao', text: 'Curacao' },
  { value: 'Cyprus', text: 'Cyprus' },
  { value: 'Czech Republic', text: 'Czech Republic' },
  { value: 'Denmark', text: 'Denmark' },
  { value: 'Djibouti', text: 'Djibouti' },
  { value: 'Dominica', text: 'Dominica' },
  { value: 'Dominican Republic', text: 'Dominican Republic' },
  { value: 'Ecuador', text: 'Ecuador' },
  { value: 'Egypt', text: 'Egypt' },
  { value: 'El Salvador', text: 'El Salvador' },
  { value: 'Equatorial Guinea', text: 'Equatorial Guinea' },
  { value: 'Eritrea', text: 'Eritrea' },
  { value: 'Estonia', text: 'Estonia' },
  { value: 'Ethiopia', text: 'Ethiopia' },
  { value: 'Falkland Islands', text: 'Falkland Islands' },
  { value: 'Faroe Islands', text: 'Faroe Islands' },
  { value: 'Fiji', text: 'Fiji' },
  { value: 'Finland', text: 'Finland' },
  { value: LOCALE_COUNTRIES.FRANCE, text: LOCALE_COUNTRIES.FRANCE },
  { value: 'French Guiana', text: 'French Guiana' },
  { value: 'French Polynesia', text: 'French Polynesia' },
  {
    value: 'French Southern Territories',
    text: 'French Southern Territories',
  },
  { value: 'Gabon', text: 'Gabon' },
  { value: 'Gambia', text: 'Gambia' },
  { value: 'Georgia', text: 'Georgia' },
  { value: LOCALE_COUNTRIES.GERMANY, text: LOCALE_COUNTRIES.GERMANY },
  { value: 'Ghana', text: 'Ghana' },
  { value: 'Gibraltar', text: 'Gibraltar' },
  { value: 'Greece', text: 'Greece' },
  { value: 'Greenland', text: 'Greenland' },
  { value: 'Grenada', text: 'Grenada' },
  { value: 'Guadeloupe', text: 'Guadeloupe' },
  { value: 'Guam', text: 'Guam' },
  { value: 'Guatemala', text: 'Guatemala' },
  { value: 'Guernsey', text: 'Guernsey' },
  { value: 'Guinea', text: 'Guinea' },
  { value: 'Guinea-Bissau', text: 'Guinea-Bissau' },
  { value: 'Guyana', text: 'Guyana' },
  { value: 'Haiti', text: 'Haiti' },
  {
    value: 'Heard Island and McDonald Islands',
    text: 'Heard Island and McDonald Islands',
  },
  {
    value: 'Holy See (Vatican City State)',
    text: 'Holy See (Vatican City State)',
  },
  { value: 'Honduras', text: 'Honduras' },
  { value: 'Hong Kong', text: 'Hong Kong' },
  { value: 'Hungary', text: 'Hungary' },
  { value: 'Iceland', text: 'Iceland' },
  { value: 'India', text: 'India' },
  { value: 'Indonesia', text: 'Indonesia' },
  {
    value: 'Iran (Islamic Republic of)',
    text: 'Iran (Islamic Republic of)',
  },
  { value: 'Iraq', text: 'Iraq' },
  { value: 'Ireland', text: 'Ireland' },
  { value: 'Isle of Man', text: 'Isle of Man' },
  { value: 'Israel', text: 'Israel' },
  { value: LOCALE_COUNTRIES.ITALY, text: LOCALE_COUNTRIES.ITALY },
  { value: 'Jamaica', text: 'Jamaica' },
  { value: LOCALE_COUNTRIES.JAPAN, text: LOCALE_COUNTRIES.JAPAN },
  { value: 'Jersey', text: 'Jersey' },
  { value: 'Jordan', text: 'Jordan' },
  { value: 'Kazakhstan', text: 'Kazakhstan' },
  { value: 'Kenya', text: 'Kenya' },
  { value: 'Kiribati', text: 'Kiribati' },
  {
    value: "Korea (Democratic People's Republic of)",
    text: "Korea (Democratic People's Republic of)",
  },
  { value: 'Korea (Republic of)', text: 'Korea (Republic of)' },
  { value: 'Kuwait', text: 'Kuwait' },
  { value: 'Kyrgyzstan', text: 'Kyrgyzstan' },
  {
    value: "Lao People's Democratic Republic",
    text: "Lao People's Democratic Republic",
  },
  { value: 'Latvia', text: 'Latvia' },
  { value: 'Lebanon', text: 'Lebanon' },
  { value: 'Lesotho', text: 'Lesotho' },
  { value: 'Liberia', text: 'Liberia' },
  { value: 'Libya', text: 'Libya' },
  { value: 'Liechtenstein', text: 'Liechtenstein' },
  { value: 'Lithuania', text: 'Lithuania' },
  { value: 'Luxembourg', text: 'Luxembourg' },
  { value: 'Macao', text: 'Macao' },
  { value: 'Macedonia', text: 'Macedonia' },
  { value: 'Madagascar', text: 'Madagascar' },
  { value: 'Malawi', text: 'Malawi' },
  { value: 'Malaysia', text: 'Malaysia' },
  { value: 'Maldives', text: 'Maldives' },
  { value: 'Mali', text: 'Mali' },
  { value: 'Malta', text: 'Malta' },
  { value: 'Marshall Islands', text: 'Marshall Islands' },
  { value: 'Martinique', text: 'Martinique' },
  { value: 'Mauritania', text: 'Mauritania' },
  { value: 'Mauritius', text: 'Mauritius' },
  { value: 'Mayotte', text: 'Mayotte' },
  { value: LOCALE_COUNTRIES.MEXICO, text: LOCALE_COUNTRIES.MEXICO },
  {
    value: 'Micronesia (Federal State of)',
    text: 'Micronesia (Federal State of)',
  },
  { value: 'Moldova (Republic of)', text: 'Moldova (Republic of)' },
  { value: 'Monaco', text: 'Monaco' },
  { value: 'Mongolia', text: 'Mongolia' },
  { value: 'Montenegro', text: 'Montenegro' },
  { value: 'Montserrat', text: 'Montserrat' },
  { value: 'Morocco', text: 'Morocco' },
  { value: 'Mozambique', text: 'Mozambique' },
  { value: 'Myanmar (Burma)', text: 'Myanmar (Burma)' },
  { value: 'Namibia', text: 'Namibia' },
  { value: 'Nauru', text: 'Nauru' },
  { value: 'Nepal', text: 'Nepal' },
  { value: LOCALE_COUNTRIES.NETHERLANDS, text: LOCALE_COUNTRIES.NETHERLANDS },
  {
    value: 'Netherlands Antilles (Deprecated)',
    text: 'Netherlands Antilles (Deprecated)',
  },
  { value: 'New Caledonia', text: 'New Caledonia' },
  { value: 'New Zealand', text: 'New Zealand' },
  { value: 'Nicaragua', text: 'Nicaragua' },
  { value: 'Niger', text: 'Niger' },
  { value: 'Nigeria', text: 'Nigeria' },
  { value: 'Niue', text: 'Niue' },
  { value: 'Norfolk Island', text: 'Norfolk Island' },
  {
    value: 'Northern Mariana Islands',
    text: 'Northern Mariana Islands',
  },
  { value: 'Norway', text: 'Norway' },
  { value: 'Oman', text: 'Oman' },
  { value: 'Pakistan', text: 'Pakistan' },
  { value: 'Palau', text: 'Palau' },
  { value: 'Panama', text: 'Panama' },
  { value: 'Papua New Guinea', text: 'Papua New Guinea' },
  { value: 'Paraguay', text: 'Paraguay' },
  { value: 'Peru', text: 'Peru' },
  { value: 'Philippines', text: 'Philippines' },
  { value: 'Pitcairn Island', text: 'Pitcairn Island' },
  { value: 'Poland', text: 'Poland' },
  { value: 'Portugal', text: 'Portugal' },
  { value: 'Puerto Rico', text: 'Puerto Rico' },
  { value: 'Qatar', text: 'Qatar' },
  { value: 'Reunion Island', text: 'Reunion Island' },
  { value: 'Romania', text: 'Romania' },
  { value: 'Russian Federation', text: 'Russian Federation' },
  { value: 'Rwanda', text: 'Rwanda' },
  { value: 'Saint Barthelemy', text: 'Saint Barthelemy' },
  { value: 'Saint Helena', text: 'Saint Helena' },
  { value: 'Saint Kitts and Nevis', text: 'Saint Kitts and Nevis' },
  { value: 'Saint Lucia', text: 'Saint Lucia' },
  { value: 'Saint Martin', text: 'Saint Martin' },
  {
    value: 'Saint Pierre and Miquelon',
    text: 'Saint Pierre and Miquelon',
  },
  {
    value: 'Saint Vincent and the Grenadines',
    text: 'Saint Vincent and the Grenadines',
  },
  { value: 'Samoa', text: 'Samoa' },
  { value: 'San Marino', text: 'San Marino' },
  { value: 'Sao Tome and Principe', text: 'Sao Tome and Principe' },
  { value: 'Saudi Arabia', text: 'Saudi Arabia' },
  { value: 'Senegal', text: 'Senegal' },
  { value: 'Serbia', text: 'Serbia' },
  { value: 'Seychelles', text: 'Seychelles' },
  { value: 'Sierra Leone', text: 'Sierra Leone' },
  { value: 'Sint Maarten', text: 'Sint Maarten' },
  { value: 'Slovakia', text: 'Slovakia' },
  { value: 'Slovenia', text: 'Slovenia' },
  { value: 'Solomon Islands', text: 'Solomon Islands' },
  { value: 'Somalia', text: 'Somalia' },
  { value: 'South Africa', text: 'South Africa' },
  { value: 'South Georgia', text: 'South Georgia' },
  { value: 'South Sudan', text: 'South Sudan' },
  {
    value: LOCALE_COUNTRIES.SINGAPORE,
    text: LOCALE_COUNTRIES.SINGAPORE,
  },
  { value: LOCALE_COUNTRIES.SPAIN, text: LOCALE_COUNTRIES.SPAIN },
  { value: 'Sri Lanka', text: 'Sri Lanka' },
  { value: 'State of Palestine', text: 'State of Palestine' },
  { value: 'Sudan', text: 'Sudan' },
  { value: 'Suriname', text: 'Suriname' },
  {
    value: 'Svalbard and Jan Mayen Islands',
    text: 'Svalbard and Jan Mayen Islands',
  },
  { value: 'Swaziland', text: 'Swaziland' },
  { value: LOCALE_COUNTRIES.SWEDEN, text: LOCALE_COUNTRIES.SWEDEN },
  { value: 'Switzerland', text: 'Switzerland' },
  { value: 'Syrian Arab Republic', text: 'Syrian Arab Republic' },
  { value: 'Taiwan', text: 'Taiwan' },
  { value: 'Tajikistan', text: 'Tajikistan' },
  { value: 'Tanzania', text: 'Tanzania' },
  { value: 'Thailand', text: 'Thailand' },
  { value: 'Timor-Leste', text: 'Timor-Leste' },
  { value: 'Togo', text: 'Togo' },
  { value: 'Tokelau', text: 'Tokelau' },
  { value: 'Tonga', text: 'Tonga' },
  { value: 'Trinidad and Tobago', text: 'Trinidad and Tobago' },
  { value: 'Tunisia', text: 'Tunisia' },
  { value: 'Turkey', text: 'Turkey' },
  { value: 'Turkmenistan', text: 'Turkmenistan' },
  {
    value: 'Turks and Caicos Islands',
    text: 'Turks and Caicos Islands',
  },
  { value: 'Tuvalu', text: 'Tuvalu' },
  { value: 'Uganda', text: 'Uganda' },
  { value: 'Ukraine', text: 'Ukraine' },
  { value: 'United Arab Emirates', text: 'United Arab Emirates' },
  {
    value: LOCALE_COUNTRIES.UNITED_KINGDOM,
    text: LOCALE_COUNTRIES.UNITED_KINGDOM,
  },
  {
    value: LOCALE_COUNTRIES.UNITED_STATES,
    text: LOCALE_COUNTRIES.UNITED_STATES,
  },
  { value: 'Uruguay', text: 'Uruguay' },
  {
    value: 'US Minor Outlying Islands',
    text: 'US Minor Outlying Islands',
  },
  { value: 'Uzbekistan', text: 'Uzbekistan' },
  { value: 'Vanuatu', text: 'Vanuatu' },
  { value: 'Venezuela', text: 'Venezuela' },
  { value: 'Vietnam', text: 'Vietnam' },
  {
    value: 'Virgin Islands (British)',
    text: 'Virgin Islands (British)',
  },
  { value: 'Virgin Islands (USA)', text: 'Virgin Islands (USA)' },
  { value: 'Wallis and Futuna', text: 'Wallis and Futuna' },
  { value: 'Western Sahara', text: 'Western Sahara' },
  { value: 'Yemen', text: 'Yemen' },
  { value: 'Zambia', text: 'Zambia' },
  { value: 'Zimbabwe', text: 'Zimbabwe' },
];

export const states = [
  { value: '', text: 'contactForm.state' },
  { value: 'AL', text: 'AL' },
  { value: 'AK', text: 'AK' },
  { value: 'AZ', text: 'AZ' },
  { value: 'AR', text: 'AR' },
  { value: 'CA', text: 'CA' },
  { value: 'CO', text: 'CO' },
  { value: 'CT', text: 'CT' },
  { value: 'DE', text: 'DE' },
  { value: 'FL', text: 'FL' },
  { value: 'GA', text: 'GA' },
  { value: 'HI', text: 'HI' },
  { value: 'ID', text: 'ID' },
  { value: 'IL', text: 'IL' },
  { value: 'IN', text: 'IN' },
  { value: 'IA', text: 'IA' },
  { value: 'KS', text: 'KS' },
  { value: 'KY', text: 'KY' },
  { value: 'LA', text: 'LA' },
  { value: 'ME', text: 'ME' },
  { value: 'MD', text: 'MD' },
  { value: 'MA', text: 'MA' },
  { value: 'MI', text: 'MI' },
  { value: 'MN', text: 'MN' },
  { value: 'MS', text: 'MS' },
  { value: 'MO', text: 'MO' },
  { value: 'MT', text: 'MT' },
  { value: 'NE', text: 'NE' },
  { value: 'NV', text: 'NV' },
  { value: 'NH', text: 'NH' },
  { value: 'NJ', text: 'NJ' },
  { value: 'NM', text: 'NM' },
  { value: 'NY', text: 'NY' },
  { value: 'NC', text: 'NC' },
  { value: 'ND', text: 'ND' },
  { value: 'OH', text: 'OH' },
  { value: 'OK', text: 'OK' },
  { value: 'OR', text: 'OR' },
  { value: 'PA', text: 'PA' },
  { value: 'RI', text: 'RI' },
  { value: 'SC', text: 'SC' },
  { value: 'SD', text: 'SD' },
  { value: 'TN', text: 'TN' },
  { value: 'TX', text: 'TX' },
  { value: 'UT', text: 'UT' },
  { value: 'VT', text: 'VT' },
  { value: 'VA', text: 'VA' },
  { value: 'WA', text: 'WA' },
  { value: 'WV', text: 'WV' },
  { value: 'WI', text: 'WI' },
  { value: 'WY', text: 'WY' },
  { value: 'AS', text: 'AS' },
  { value: 'DC', text: 'DC' },
  { value: 'FM', text: 'FM' },
  { value: 'GU', text: 'GU' },
  { value: 'MH', text: 'MH' },
  { value: 'MP', text: 'MP' },
  { value: 'PW', text: 'PW' },
  { value: 'PR', text: 'PR' },
  { value: 'VI', text: 'VI' },
];

export const indiaStates = [
  { value: '', text: 'contactForm.state' },
  { value: 'Haryana', text: 'Haryana' },
  { value: 'Himachal Pradesh', text: 'Himachal Pradesh' },
  { value: 'Rajasthan', text: 'Rajasthan' },
  { value: 'Uttar Pradesh', text: 'Uttar Pradesh' },
  { value: 'Punjab', text: 'Punjab' },
  { value: 'Delhi', text: 'Delhi' },
  { value: 'Ladakh', text: 'Ladakh' },
  { value: 'Uttarakhand', text: 'Uttarakhand' },
  { value: 'Chandigarh', text: 'Chandigarh' },
  { value: 'Bihar', text: 'Bihar' },
  { value: 'Jharkhand', text: 'Jharkhand' },
  { value: 'Odisha', text: 'Odisha' },
  { value: 'West Bengal', text: 'West Bengal' },
  { value: 'Andhra Pradesh', text: 'Andhra Pradesh' },
  { value: 'Telangana', text: 'Telangana' },
  { value: 'Karnataka', text: 'Karnataka' },
  { value: 'Kerala', text: 'Kerala' },
  { value: 'Tamil Nadu', text: 'Tamil Nadu' },
  { value: 'Gujarat', text: 'Gujarat' },
  { value: 'Madhya Pradesh', text: 'Madhya Pradesh' },
  { value: 'Maharashtra', text: 'Maharashtra' },
];
