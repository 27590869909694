import styled from 'styled-components';

import { Headline, Paragraph } from 'components/atoms';
import { minWidth } from 'styles/media-queries';
import { columnsGutter, container } from 'styles/grid';
import { levels } from 'styles/z-index';
import { SocialIcons } from 'components/molecules';
import { black, darkGray, white, white20OpacityOnBlack } from 'styles/colors';
import {
  sectionMargins,
  spacer16,
  spacer24,
  spacer80,
  spacer64,
} from 'styles/spacers';

export const Container = styled.section`
  background-color: ${black};
  position: relative;
  z-index: ${levels.base};
  ${sectionMargins}
`;

export const Content = styled.div`
  ${container}
`;

export const ContentBackground = styled.div`
  background-color: ${darkGray};
  border-radius: 8px;
  padding: ${spacer24} ${spacer16};

  ${minWidth.lg`
    ${columnsGutter}
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: ${spacer80} ${spacer64};
  `}
`;

export const Description = styled(Paragraph)`
  margin-top: 1.6rem;
`;
export const TitleContainer = styled.div`
  color: ${white};

  ${minWidth.lg`
    grid-column: span 5;
  `}
`;

export const FormContainer = styled.div`
  margin-top: 4.8rem;
  button {
    width: unset;
  }

  ${minWidth.lg`
    margin-top: 0;
    grid-column: 7 / span 6;
  `}
`;

export const SocialLink = styled(SocialIcons)`
  grid-row: 2;
  float: left;

  div {
    color: ${white};
  }
`;

export const FollowUsTitle = styled(Headline)`
  color: ${white};
  float: left;
  margin: 0px 16px;

  ${minWidth.lg`
    grid-column: span 6;
  `}
`;

export const FollowUsContainer = styled.div`
  width: 100%;
  border-top: 1px solid ${white20OpacityOnBlack};
  margin-top: 4.8rem;
  padding-top: 2.4rem;
  display: flex;
  align-items: center;

  ${minWidth.lg`
  grid-column: span 12;
  `}
`;
