import styled, { keyframes } from 'styled-components';

import { opacityBlack90 } from '@spotify-internal/encore-web/core';
import { Button, Icon } from 'components/atoms';
import { black, white } from 'styles/colors';
import { container } from 'styles/grid';
import {
  spacer8,
  spacer16,
  spacer24,
  spacer32,
  spacer48,
  spacer80,
} from 'styles/spacers';
import { maxWidth, minWidth } from 'styles/media-queries';

export const Container = styled.div`
  ${container}
  display: flex;
  justify-content: flex-start;
  padding-top: 0;

  margin-top: -10rem;
  padding-bottom: ${spacer48};
  ${minWidth.lg`
    margin-top: -12rem;
    justify-content: center;
    padding-bottom: ${spacer80};
  `}
`;

export const EntryButton = styled(Button)`
  z-index: 2;
`;

export const OverlayBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${opacityBlack90};
  overflow: hidden;
  z-index: 20;
`;

export const OverlayContainer = styled.div`
  height: 95dvh;
  width: 95dvw;
  margin: 2.5dvh 2.5dvw;
  border-radius: 24px;
  background-color: ${black};
  display: flex;
  flex-direction: column;
  position: fixed;
  overflow: hidden;
`;

export const OverlayContents = styled.div`
  ${container}
  position: static;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  /* hide scrollbar */
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TopContainer = styled.div`
  margin: ${spacer16};
  margin-bottom: 0;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TopRightContainer = styled.div`
  max-height: 5rem;
  display: flex;
  justify-content: right;
  align-items: center;
  gap: ${spacer16};
  ${maxWidth.sm`
    .close-button {
      width: ${spacer24};
      height: ${spacer24};
    }
    h4 {
      font-size: 1.6rem;
    }
  `}
`;

export const TopImageContainer = styled.div`
  position: relative;
  height: 10rem;
  width: 10rem;
  ${minWidth.lg`
    height: 15rem;
    width: 15rem;
  `}
`;

export const Logo = styled(Icon)`
  ${maxWidth.sm`
    div,
    svg {
      height: 24px;
      width: 110.25px;
    }
  `}
`;

export const IntroLogo = styled(Logo)`
  position: absolute;
  top: 5%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacer16};
`;

export const Landing = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${props => props.$center && `justify-content: center;`}
  align-items: center;
  text-align: center;
  gap: ${spacer32};
  ${minWidth.md`
    width: 70%;
    margin: auto;
  `}
  ${minWidth.lg`
    width: 40%;
    gap: ${spacer48};
  `}
`;

const fadeOut = keyframes`
  0% { 
    opacity: 1; 
    visibility: visible;
    z-index: 2;
  }
  100% { 
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  height: 40%;
  width: 80%;
  ${minWidth.lg`
    height: 20%;
    width: 50%;
  `}
`;

export const LoadingBarContainer = styled.div`
  display: flex;
  justify-content: left;
  margin-top: ${spacer24};
  width: 95%;
  position: absolute;
  bottom: 5%;
`;

export const LoadingBar = styled.div`
  position: relative;
  width: ${props => props.$progress}%;
  height: 4px;
  background-color: ${white};
  border-radius: 1rem;
`;

export const IntroScreen = styled(OverlayContainer)`
  position: absolute;
  z-index: 2;
  opacity: 1;
  top: 0;
  left: 0;
  margin: 0;
  animation: ${fadeOut} 1s forwards;
  animation-delay: 2s;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TrendLanding = styled.div`
  height: 100%;
  width: 100%;
  display: block;
  ${props =>
    props.$center &&
    `
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
  `}
`;

export const TrendTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin: 0 auto;
  height: 80%;
  width: 80%;
  gap: ${spacer24};

  ${minWidth.lg`
    height: unset;
  `}
`;

export const TakeawayTextContainer = styled.div`
  margin-top: ${spacer32};
`;

export const TakeawayLeftText = styled.div`
  grid-column: 1;
`;

export const TakeawayRightText = styled.div`
  grid-column: 2;
`;

// ERROR STYLES
const slideUp = keyframes`
  0% { 
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    transform: translateY(10rem);
  }
  100% { 
    opacity: 1; 
    visibility: visible;
    z-index: 2;
    transform: translateY(0);
  }
`;

export const ErrorCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: center / contain no-repeat url('/culture-next/cardoutline.png');
  animation: ${slideUp} 2s forwards;
  max-width: 90%;
  width: 30rem;
  height: 40rem;
  position: absolute;

  ${minWidth.md`
    width: 40rem;
    height: 53.33rem;
  `}
`;

export const ErrorContents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: ${spacer16};
  width: 85%;

  ${maxWidth.sm`
    h4 {
      font-size: 1.6rem;
      line-height: 1.8rem;
    }
    .error-button {
      padding: ${spacer8} ${spacer16};
    }
  `}
`;

export const ErrorImageContainer = styled.div`
  position: relative;
  height: 5rem;
  width: 5rem;
  ${minWidth.md`
    height: 10rem;
    width: 10rem;
  `}
  ${minWidth.lg`
    height: 15rem;
    width: 15rem;
  `}
`;

// BACKGROUND IMAGES
export const BGImage = styled.div`
  position: absolute;
  height: 20%;
  width: 20%;
`;

export const BGImage1 = styled(BGImage)`
  left: 2%;
  top: 5%;
`;

export const BGImage2 = styled(BGImage)`
  left: -2%;
  bottom: 5%;
`;

export const BGImage3 = styled(BGImage)`
  top: 10%;
  right: 25%;
`;

export const BGImage4 = styled(BGImage)`
  right: -10%;
  bottom: 10%;
`;

export const BGImage5 = styled(BGImage)`
  right: 0%;
  bottom: -10%;
`;
