import { css } from 'styled-components';

import { gray50 } from '@spotify-internal/encore-web';
import { minWidth } from './media-queries';
import { black } from './colors';

export const caption = css`
  color: ${black};
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 1.6rem;

  ${minWidth.lg`
    font-size: 1.6rem;
    line-height: 2.4rem;
  `}
`;

const globalTypographyStyles = css`
  body {
    color: ${black};
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: normal;

    ${minWidth.lg`
      font-size: 2rem;
      line-height: 3.2rem;
    `}
  }

  hr {
    border-top: 0.1rem solid ${gray50};
  }

  caption {
    ${caption}
  }
`;

export default globalTypographyStyles;
