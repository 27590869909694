import styled from 'styled-components';

import { animations, fontWeights, navHeight } from 'styles/variables';
import { levels } from 'styles/z-index';
import { maxWidth, minWidth } from 'styles/media-queries';
import { container } from 'styles/grid';
import { gray70, solidWhite200 } from '@spotify-internal/encore-web';
import { Cta, Icon } from 'components/atoms';
import { Modal } from 'components/molecules';
import { black, darkGray, spotifyGreen, white } from 'styles/colors';
import { spacer16, spacer24 } from '../../../styles/spacers';

/* Transitions, Constants */
const opacityTransition = 'opacity 0.7s ease-in-out';

/* Styled Components */
export const Header = styled.header`
  align-items: center;
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 3;
  -webkit-transition: opacity 500ms, visibility 500ms, margin-top 500ms;
  transition: opacity 500ms, visibility 500ms, margin-top 500ms;
  margin-top: ${({ theme }) => (theme.showNav ? '7rem' : '2rem')};

  ${({ theme }) =>
    theme.showLocaleSelector &&
    `
      border-bottom: none;
    `}
`;

export const Container = styled.div`
  ${container}

  align-items: center;
  background-color: ${props =>
    props.theme.backgroundColor ? props.theme.backgroundColor : black};
  display: flex;
  justify-content: space-between;
  z-index: ${levels.highlight};
  height: 6rem;
  ${({ theme }) =>
    !theme.isBreadcrumb &&
    `
      background-color: inherit;
    `}

  ${maxWidth.sm`
    padding: 0;
    margin-left: 0;
  `}

  ${maxWidth.md`
    padding: 0;
  `}
`;

export const Content = styled.div`
  align-items: center;
  background-color: ${props =>
    props.theme.backgroundColor ? props.theme.backgroundColor : black};
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: ${levels.highlight};

  ${minWidth.sm`
    height: ${navHeight.mlUp}rem;
  `}

  ${minWidth.md`
    height: ${navHeight.mlUp}rem;
  `}

  ${minWidth.lg`
    width: auto;
  `}

  ${({ theme }) =>
    theme.isMobile &&
    `

      ${minWidth.md`
         & .create-button {
      margin-right: -80rem;
      }
  `}
    `}
`;

export const NavContainer = styled.div`
  ${container}

  font-size: 1.4rem;
  left: 0;
  padding-bottom: 4rem;
  position: absolute;
  top: ${`${navHeight.smToLg}rem`};
  width: 100%;
  z-index: ${levels.persistent};
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-x: scroll;
  overflow-y: clip;
  box-shadow: 1px 4px 16px 1px rgb(0 0 0 / 30%);

  &::-webkit-scrollbar {
    display: none;
  }

  ${props =>
    props.scrollable &&
    `
    flex: 1;
    overflow-y: scroll;
  `}

  ${({ theme }) =>
    !theme.isBreadcrumb &&
    theme.hasNavigationItems &&
    `
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 6rem;
      background-color: ${darkGray};
      border-radius: 6rem;
    `}

  ${maxWidth.sm`
    top: 0;
    padding-bottom: 0;
    font-size: 1rem;
    position: relative;

     ${({ theme }) =>
       !theme.isBreadcrumb &&
       theme.hasNavigationItems &&
       `
      background-color: ${darkGray};
      margin-top: 0;
      height: 5.5rem;
      padding-left: ${spacer24};
      margin-left: 2.5rem;
      display: flex;
      width: 100%;
    `}

       ${props =>
         props.isOverflowing &&
         `
        border-radius: 6rem 0rem 0rem 6rem;
    `}
  `}

  ${minWidth.md`
    top: 0;
    padding-bottom: 0;
    font-size: 1rem;
    position: relative;
    margin: 0 4rem;
  `}

  ${minWidth.lg`
    padding: 0;
    position: relative;
    transition: none;


     ${({ theme }) =>
       !theme.isBreadcrumb &&
       theme.hasNavigationItems &&
       `
      background-color: ${darkGray};
      border-radius: 6rem;
      margin: unset;
      padding-bottom: 0;
      padding-left: 2rem;
    `}
  `}
`;

export const Nav = styled.nav`
  display: flex;
  opacity: 0;
  transition: ${opacityTransition};

  ${maxWidth.sm`
    opacity: 1;
    transition: none;
  `}

  ${minWidth.md`
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    opacity: 1;
    transition: none;
  `}

  ${minWidth.lg`
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    opacity: 1;
    transition: none;
  `}
`;

export const NavElement = styled.div`
  display: flex;
  flex-direction: row;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-x: scroll;
  overflow-y: clip;

  &::-webkit-scrollbar {
    display: none;
  }

  ${props =>
    props.scrollable &&
    `
    flex: 1;
    overflow-y: scroll;
  `}

${maxWidth.sm`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: start;

  ${({ theme }) =>
    !theme.showLocaleSelector &&
    theme.hasNavigationItems &&
    `
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem auto;
    `}

    ${({ theme }) =>
      theme.showLocaleSelector &&
      `
      height: 5rem;
      top: 0;
      align-items: center;
      margin: 1rem auto;
        div:last-of-type {
        margin-left: auto;
      }
   `}
`}


${minWidth.md`
  display: flex;
  height: 10rem;
  flex-direction: row;
  align-items: start;

  ${({ theme }) =>
    !theme.showLocaleSelector &&
    theme.hasNavigationItems &&
    `
      justify-content: center;
      height: 5rem;
      top: 0;
      align-items: center;
      margin: 1rem auto;
    `}

   ${({ theme }) =>
     theme.showLocaleSelector &&
     `
      height: 5rem;
      top: 0;
      align-items: center;
      margin: 1rem auto;

        div:last-of-type {
        margin-left: auto;
      }
   `}
`}

${minWidth.lg`
  align-items: center;
  flex-direction: row;
  height: 50%;
  justify-content: flex-start;
  overflow: inherit;
  font-weight: bolder;

  ${({ theme }) =>
    !theme.showLocaleSelector &&
    theme.hasNavigationItems &&
    `
      display: flex;
      justify-content: center;
      width: 100%;
    `}

     ${({ theme }) =>
       theme.showLocaleSelector &&
       `
       width: calc(100vw - 35rem);
        div:last-of-type {
        margin-left: auto;
      }
   `}
`}
`;

export const ExploreContainer = styled.div`
  color: ${spotifyGreen};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: ${spacer16};

  p {
    font-weight: ${fontWeights.extra_bold};
    font-size: 1.7rem;
  }
`;

export const LocaleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  margin-right: 1rem;

  ${maxWidth.md`
    top: 0;
  `}

  ${minWidth.lg`
    flex-direction: row;
    align-items: center;
  `}
`;

export const CtaLocaleSelector = styled(Cta).attrs({
  tag: 'button',
  type: 'textLink',
})`
  align-items: center;
  color: ${solidWhite200};
  display: flex;
  flex-direction: row;
  text-decoration: none;
  border-bottom: ${({ theme }) =>
    theme.showLocaleSelector &&
    !theme.hasNavigationItems &&
    `1px solid ${white}`};

  &:hover {
    color: ${gray70};
  }
`;

export const ModalLocales = styled(Modal)`
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 2rem;

  ${minWidth.sm`
    padding-left: 4rem;
    padding-right: 4rem;
  `}

  ${minWidth.md`
    width: 80%;
    max-width: 84.9rem;
    padding-left: 8rem;
    padding-right: 8rem;
  `}
`;

export const Caret = styled(Icon)`
  display: inline-block;
  font-size: 0;
  height: 1rem;
  transition: transform ${animations.defaultTransition};
  width: 1rem;

  div,
  svg {
    height: inherit;
    width: inherit;
    color: ${props => (props.theme.theme === 'Dark' ? solidWhite200 : black)};
  }

  ${maxWidth.sm`
    margin-left: 0.4rem;
    height: 1.15rem;
    margin-top: 0.45rem;
    margin-right: 3rem;
  `}

  ${minWidth.lg`
    display: block;
    margin-left: 0.8rem;
  `}
`;

export const Arrow = styled(Icon)`
  display: inline-block;
  margin-left: 0.5rem;

  ${maxWidth.md`
    margin-top: 1.15rem;
  `}

  ${minWidth.lg`
    margin-top: 1rem;
  `}

  svg {
    height: 1.6rem;
    width: 1.6rem;
  }

  path {
    align-items: center;
    justify-content: center;
    fill: ${white};
  }
`;

export const LocaleText = styled.p`
  color: ${white};
  font-weight: ${fontWeights.bold};
  font-size: 1.6rem;
  line-height: 4.8rem;
  margin-right: 0.5rem;
`;

export const LocaleGlobe = styled(Icon)`
  display: inline-block;

  height: 2.25rem;
  margin-left: 2rem;
  transition: transform ${animations.defaultTransition};
  width: 2.25rem;

  div,
  svg {
    height: inherit;
    width: inherit;
    color: ${props => (props.theme.theme === 'Dark' ? solidWhite200 : black)};
  }

  ${maxWidth.sm`
    margin-left: 0.4rem;
    height: 1.5rem;
    margin-top: 0.45rem;
  `}

  ${minWidth.lg`
    display: block;
    margin-left: 0.8rem;
  `}
`;
