import lowerCase from 'lodash/lowerCase';
import { solidBlack0, solidWhite200 } from '@spotify-internal/encore-web';

/**
 * App themes definition
 */
export const THEMES = {
  DARK: 'dark',
  LIGHT: 'light',
};

/**
 * Feature Card themes definition
 */
export const THEME_SELECTOR_TYPES = {
  AD_FORMAT: 'adformats',
  GET_STARTED: 'getstarted',
  HERO: 'hero',
};

/**
 * Theme main color
 */
const THEME_COLOR = {
  [THEMES.DARK]: solidWhite200,
  [THEMES.LIGHT]: solidBlack0,
};

/**
 * Theme inversed color
 */
const THEME_INVERSED_COLOR = {
  [THEMES.DARK]: solidBlack0,
  [THEMES.LIGHT]: solidWhite200,
};

/**
 * Returns the theme key value providing the truthy value get from the CMS
 * @param {boolean} contentValue Truthy value get from the CMS
 * @returns {string}
 */
export const getThemeKey = contentValue =>
  contentValue ? THEMES.LIGHT : THEMES.DARK;

/**
 * Returns the corresponding main color value of a given theme
 * @param {string} theme Theme key
 * @returns {string}
 */
export const getThemeColor = theme => THEME_COLOR[lowerCase(theme)];

/**
 * Returns the corresponding inversed color value of a given theme
 * @param {string} theme Theme key
 * @returns {string}
 */
export const getInversedThemeColor = theme =>
  THEME_INVERSED_COLOR[lowerCase(theme)];

/**
 * Returns a boolean value indicating if the theme is dark or not
 * @param {string} theme Theme key
 * @returns {boolean}
 */
export const getIsDarkTheme = theme => lowerCase(theme) === THEMES.DARK;
