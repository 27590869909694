import React, { useState } from 'react';
import PropTypes, { any } from 'prop-types';
import { ThemeProvider } from 'styled-components';

import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';

import { getLinkProps } from 'utils/get-link-props';
import { useImageSupport } from 'utils/use-image-support';

import { Eyebrow, Headline } from 'components/atoms';
import {
  INLINE_CARD_TILE_THEMES,
  getInlineCardTheme,
} from 'utils/get-theme-selector';
import * as Styled from './InlineCard.styled';
import { ICONS } from '../../../constants/icons';

/**
 * Inline Card Component
 * @param {string|null} className The component class name.
 * @param {string} description The component description.
 * @param {string} eyebrow The component eyebrow.
 * @param {object} image The component image, composed by the url and description.
 * @param {string} title The component title.
 * @param {string} subtitle the component subtitle
 * @param ctasCollection
 * @param {string} textColor hex code for the text color
 * @param {string} backgroundColor hex code for the background color
 * @param {boolean} fullWidthImage if image should take up entire left half
 * @param {string} themeSelector The component theme
 * @param {boolean} isArticleMargin style selector for article page
 * @param {Array} ctasCollection.items - A list of CTAs
 * @returns {ReactElement}
 */
const InlineCard = ({
  className = null,
  description,
  eyebrow = '',
  image = null,
  title,
  subtitle,
  ctasCollection,
  textColor,
  backgroundColor,
  fullWidthImage,
  isArticleMargin = false,
  themeSelector,
}) => {
  const { queryUrl } = useImageSupport();
  const hasImage = !!image;
  const themeSelectorType = getInlineCardTheme(themeSelector?.toLowerCase());
  const ctaItems = get(ctasCollection, 'items', []);
  const hasEyebrow = !!eyebrow;
  const [sharedData] = useState({ hasImage });

  const CtaContainer = (
    <Styled.CtasContainer>
      {ctaItems.map(
        ({ title: ctaTitle, url, type, overrideFunctionality }, index) => {
          const { href, asLink } = getLinkProps(url);

          return (
            <Styled.Cta
              key={kebabCase(`${ctaTitle}-${index}`)}
              type={type}
              overrideFunctionality={overrideFunctionality}
              href={href}
              asLink={asLink}
              isDarkTheme
            >
              {ctaTitle}
              {themeSelectorType ===
                INLINE_CARD_TILE_THEMES.INDUSTRY_SELECTOR && (
                <Styled.Arrow
                  name={ICONS.ARROW_RIGHT_NO_CONTAINER}
                  className="arrow"
                />
              )}
            </Styled.Cta>
          );
        },
      )}
    </Styled.CtasContainer>
  );

  return (
    <ThemeProvider
      theme={{
        sharedData,
        textColor,
        backgroundColor,
        fullWidthImage,
        isArticleMargin,
        themeSelectorType,
      }}
    >
      <Styled.Container>
        <Styled.InlineCard className={className}>
          {hasImage && (
            <Styled.Image data-src={image[queryUrl]} alt={image.description} />
          )}
          <Styled.Content>
            <Styled.HeadlineContainer tabIndex={0} hasEyebrow={hasEyebrow}>
              {themeSelectorType === 'headline' ? (
                <Headline tag="h2" styling="h1" text={title} />
              ) : (
                <>
                  {eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
                  <Headline tag="h2" styling="h1" text={title} />
                  <Headline tag="h2" styling="h1" text={subtitle} />
                </>
              )}
            </Styled.HeadlineContainer>
            <Styled.DescriptionContainer tabIndex={0}>
              {themeSelectorType !== 'headline' && description && (
                <Styled.Markdown body={description} />
              )}
              {themeSelectorType !==
                INLINE_CARD_TILE_THEMES.INDUSTRY_SELECTOR && CtaContainer}
            </Styled.DescriptionContainer>
            {ctaItems.length > 0 &&
              themeSelectorType === INLINE_CARD_TILE_THEMES.INDUSTRY_SELECTOR &&
              CtaContainer}
          </Styled.Content>
        </Styled.InlineCard>
      </Styled.Container>
    </ThemeProvider>
  );
};

InlineCard.propTypes = {
  /**
   * Default className prop
   */
  className: PropTypes.string,
  /**
   * The component description.
   */
  description: PropTypes.string,
  /**
   * The component eyebrow.
   */
  eyebrow: PropTypes.string,
  /**
   * The component image, composed by the url and description.
   */
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    webpUrl: PropTypes.string.isRequired,
    optimizedUrl: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
  /**
   * The component title.
   */
  title: PropTypes.string.isRequired,
  /**
   * The component subtitle.
   */
  subtitle: PropTypes.string,
  /**
   * The component text color.
   */
  textColor: PropTypes.string,
  /**
   * The component background color.
   */
  backgroundColor: PropTypes.string,
  /**
   * Style selector for article margin
   */
  isArticleMargin: PropTypes.bool,
  /*
   * A list of CTAs
   */
  ctasCollection: PropTypes.shape({
    items: PropTypes.arrayOf(any),
  }),
};

export default InlineCard;
