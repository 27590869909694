// @ts-nocheck
// this is not preferred when using TS but as this is just an example, we are
// using it to avoid need to convert styles/media-queries to TS
import styled from 'styled-components';
import { container } from 'styles/grid';
import { white } from 'styles/colors';
import { spacer48 } from 'styles/spacers';

export const Container = styled.div`
  color: ${white};
  ${container}
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.925rem;
  min-height: 52.7rem;
`;

export const NoResultsText = styled.p`
  font-weight: 700;
  font-size: 2.4rem;
  margin-bottom: 1.6rem;
`;

export const NoResultsSubText = styled.p`
  font-size: 1.4rem;
  line-height: 1.9rem;
`;

export const LoadingIndicatorContainerStyled = styled.div`
  padding-top: 28rem;
  padding-bottom: 28rem;
  text-align: center;
  ${container}
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${props => props.filterOpen && `padding-bottom: ${spacer48};`}
`;

export const ResultsContainer = styled.div`
  padding-bottom: ${spacer48};
  position: relative;
  opacity: ${props => (props.filterOpen ? `0.5` : `1`)};
`;
