import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { ICONS } from 'constants/icons';
import { ACTIVATE_ANIMATION_CLASS } from 'styles/animations';
import * as Styled from './Footnotes.styled';

/**
 * Foonotes module
 * @param {string|null} className Defines the CSS class value.
 * @param {String} footnotes footnotes
 * @param {boolean} dropdown dropdown style
 * @param {String} textColor color of text
 * @param {boolean} fullWidth full width style
 * @returns {ReactElement}
 */
const Footnotes = ({
  className = '',
  footnotes = '',
  dropdown = false,
  textColor = '',
  fullWidth = false,
}) => {
  const [isOpen, setIsOpen] = useState(!dropdown);

  return (
    <ThemeProvider
      theme={{
        textColor,
        fullWidth,
        open: isOpen,
      }}
    >
      <Styled.Container>
        {dropdown && (
          <Styled.DropdownContainer onClick={() => setIsOpen(!isOpen)}>
            <Styled.ArrowDown name={ICONS.CARET_DOWN_SMALL} />
            <Styled.Footnotes
              className="footnotes-dropdown-title"
              styling="P1_CAPTION"
            >
              Sources & References
            </Styled.Footnotes>
          </Styled.DropdownContainer>
        )}
        {isOpen && (
          <Styled.FootnotesDropdown
            className={`${className}-p ${ACTIVATE_ANIMATION_CLASS}`}
            styling="P1_CAPTION"
          >
            {footnotes}
          </Styled.FootnotesDropdown>
        )}
      </Styled.Container>
    </ThemeProvider>
  );
};

Footnotes.propTypes = {
  /**
   * classname for CSS
   */
  className: PropTypes.string,
  /**
   * full footnotes text
   */
  footnotes: PropTypes.string,
  /**
   * dropdown styling
   */
  dropdown: PropTypes.bool,
  /**
   * color of text
   */
  textColor: PropTypes.string,
  /**
   * full width styling
   */
  fullWidth: PropTypes.bool,
};

export default Footnotes;
