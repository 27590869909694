import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ICONS } from 'constants/icons';
import * as Styled from './FilterBarDropdown.styled.js';
import { DropDownOptions } from './FilterDropdown';

export const FilterBarDropdown = ({
  category,
  selectedFilters,
  onFilterChange,
  onClearDropdown,
  onAllOptionClicked,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Styled.Container>
      <Styled.CategoryGroupTitle onClick={() => setIsOpen(!isOpen)}>
        <Styled.CategoryTitle>{category.name}</Styled.CategoryTitle>
        <Styled.Caret name={ICONS.CARET_DOWN} isOpen={isOpen} />
      </Styled.CategoryGroupTitle>
      {isOpen && category && (
        <Styled.Subcategories>
          <DropDownOptions
            categoryName={category.name}
            categoryId={category.id}
            subcategories={category.subCategories}
            selectedFilters={selectedFilters[category.id] || []}
            isAllOptionEnabled={category.isAllOptionEnabled}
            onFilterChange={onFilterChange}
            onClearDropdown={onClearDropdown}
            onAllOptionClicked={onAllOptionClicked}
            onMobile
          />
        </Styled.Subcategories>
      )}
    </Styled.Container>
  );
};

FilterBarDropdown.propTypes = {
  /**
   * Links in the link group
   */
  category: PropTypes.object,
};
