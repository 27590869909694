import styled from 'styled-components';

import { container } from 'styles/grid';
import { minWidth } from 'styles/media-queries';

export const Root = styled.div`
  ${props =>
    props.hasVerticalVideo &&
    `
    display: flex;
    flex-direction: row;
    justify-content: center;
  `}

  ${props =>
    !props.hasVerticalViideo &&
    `
   ${container}
  margin: 4rem auto;

  ${minWidth.lg`
    margin: 8rem auto;
  `}
  `}
`;
