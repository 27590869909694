import React, { useState, useEffect } from 'react';

import kebabCase from 'lodash/kebabCase';
import get from 'lodash/get';

import { Card } from 'components/molecules/card';
import { useImageSupport } from 'utils/use-image-support';

import { ForegroundImageType } from 'constants/common-proptypes';
import CuratedList from './CuratedList';

import * as Styled from './CuratedList.styled';

const ContentCuratedList = ({ data }: ContentCuratedListProps) => {
  const { cardsCollection } = data;
  const cards = get(cardsCollection, 'items', []);

  if (!cards.length) return null;
  const [currentCards, setCurrentCards] = useState<DisplayCard[]>([]);
  const { queryUrl, fileExt } = useImageSupport();

  useEffect(() => {
    const cardsWithDefaultImage = cards.map(card => {
      const cardImage = {
        description: card.image.description,
        url: card.image[queryUrl],
      };

      return { ...card, cardImage };
    });

    setCurrentCards(cardsWithDefaultImage);
  }, [fileExt]);

  return (
    <CuratedList title="" removeTopPadding withAnimation>
      {currentCards.map(card => {
        return (
          <Styled.CardContainer key={kebabCase(`${card.title}-wrapper`)}>
            <Card
              image={card.cardImage}
              title={card.title}
              paragraph={card.paragraph}
              tags={card.tags}
              ctaText={card.ctaText}
              tag=""
              slug=""
            />
          </Styled.CardContainer>
        );
      })}
    </CuratedList>
  );
};

interface ContentCuratedListProps {
  data: ContentCardData;
}
export interface ContentCardData {
  cardsCollection?: {
    items: ContentCardProps[];
  };
  name: string;
}
interface ContentCardProps {
  title: string;
  image: ForegroundImageType;
  paragraph: string;
  tags: string[];
  ctaText: string;
}
interface DisplayCard {
  cardImage: {
    url: string;
    description: string;
  };
  title: string;
  image: ForegroundImageType;
  paragraph: string;
  tags: string[];
  ctaText: string;
}

export default ContentCuratedList;
