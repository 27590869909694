import styled from 'styled-components';

import { Cta as CtaComponent } from 'components/atoms';
import { container, columnsGutter } from 'styles/grid';
import { minWidth } from 'styles/media-queries';
import { spotifyGreen, white, white20OpacityOnBlack } from 'styles/colors';
import { animations, fontWeights } from 'styles/variables';
import {
  ACTIVATE_ANIMATION_CLASS,
  fadeContentSlideUp,
} from 'styles/animations';

export const JumpLinkGroup = styled.section`
  ${container}
  ${fadeContentSlideUp.setup}

  &.${ACTIVATE_ANIMATION_CLASS} {
    ${fadeContentSlideUp.play}
    animation-delay: 700ms;
  }

  ${minWidth.lg`
    ${columnsGutter}

    display: grid;
    grid-template-columns: repeat(12, 1fr);
  `}
`;

export const Content = styled.div`
  overflow-x: scroll;
  white-space: nowrap;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  padding: 1.5rem 0;

  &::-webkit-scrollbar {
    display: none;
  }

  ${minWidth.lg`
    place-self: center;
    display: flex;
    grid-column: 3 / span 8;
  `}
`;

export const Cta = styled(CtaComponent).attrs({
  type: 'textLink',
})`
  border-bottom: 4px solid ${white20OpacityOnBlack};
  color: ${white20OpacityOnBlack};
  padding: 1rem 2rem;
  position: relative;
  height: unset;
  text-decoration: none;
  font-size: 1.4rem;
  line-height: 1.54rem;
  font-weight: ${fontWeights.extra_bold};
  transition: color ${animations.defaultTransition},
    border-color ${animations.defaultTransition};

  &.active {
    border-color: ${white};
    color: ${white};
  }

  &:hover {
    border-color: ${spotifyGreen};
    color: ${spotifyGreen};
  }

  ${minWidth.lg`
    font-size: 1.6rem;
    line-height: 1.76rem;
  `}
`;
