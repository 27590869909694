import styled from 'styled-components';

import { azure135, gray50 } from '@spotify-internal/encore-web';

export const Container = styled.div`
  line-height: 2.4rem;

  & span,
  & a {
    color: ${gray50};
    font-size: 1.6rem;
  }

  & a {
    color: ${azure135};
    text-decoration: none;

    &:hover {
      color: ${azure135};
      text-decoration: underline;
    }
  }
`;
