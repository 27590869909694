import React, { useState } from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase';
import { Button } from 'components/atoms';
import { STAT_CARD_THEMES } from 'utils/get-theme-selector';
import debounce from 'lodash/debounce';
import * as Styled from './StatSlideshow.styled';

const DEBOUNCE_DELAY = 250;

/**
 * Stat Slideshow Module
 * @param {Array} statCards - the cards to be displayed on the slideshow
 * @returns {ReactElement}
 */
const StatSlideshow = ({ statCards }) => {
  const [cards, setCards] = useState(statCards);
  const [rotateLeft, setRotateLeft] = useState(false);
  const [rotateRight, setRotateRight] = useState(false);

  const getStatCard = elem => {
    return React.isValidElement(elem) ? (
      <Styled.StatCardContainer
        title={elem.props.title}
        subtitle={elem.props.subtitle}
        caption={elem.props.caption}
        textColor={elem.props.textColor}
        backgroundColor={elem.props.backgroundColor}
        themeSelector={STAT_CARD_THEMES.HOMEPAGE_CAROUSEL}
      />
    ) : (
      <Styled.StatCardContainer
        title={elem.title}
        textColor={elem.textColor}
        backgroundColor={elem.backgroundColor}
        subtitle={elem.subtitle}
        caption={elem.caption}
        themeSelector={STAT_CARD_THEMES.HOMEPAGE_CAROUSEL}
      />
    );
  };

  const rotateCardsLeft = () => {
    const rotatedCards = cards.slice();
    const firstItem = rotatedCards[0];
    const lastItem = getStatCard(firstItem);

    rotatedCards.push(lastItem);
    rotatedCards.shift();

    setTimeout(() => {
      setCards(rotatedCards);

      setRotateLeft(true);
      setRotateRight(false);
    }, 10);

    setRotateLeft(false);
  };

  const rotateCardsRight = () => {
    const rotatedCards = cards.slice();
    const lastItem = rotatedCards[rotatedCards.length - 1];
    const firstItem = getStatCard(lastItem);

    rotatedCards.unshift(firstItem);
    rotatedCards.pop();

    setTimeout(() => {
      setCards(rotatedCards);

      setRotateRight(true);
      setRotateLeft(false);
    }, 10);

    setRotateRight(false);
  };

  const debounceRotateRight = debounce(rotateCardsRight, DEBOUNCE_DELAY);
  const debounceRotateLeft = debounce(rotateCardsLeft, DEBOUNCE_DELAY);

  const getPositionClassName = position => {
    if (rotateLeft) return `rotate-left positionAt${position}`;
    if (rotateRight) return `rotate-right positionAt${position}`;
    return `positionAt${position}`;
  };

  return (
    <Styled.StatSlideshow>
      <Styled.Container className="slider">
        {cards.map((card, index) => {
          const position = index + 1;
          return React.isValidElement(card) ? (
            <Styled.StatCardContainer
              key={kebabCase(`${card.title} ${index}`)}
              title={card.props.title}
              subtitle={card.props.subtitle}
              caption={card.props.caption}
              textColor={card.props.textColor}
              backgroundColor={card.props.backgroundColor}
              themeSelector={STAT_CARD_THEMES.HOMEPAGE_CAROUSEL}
              className={`slide ${getPositionClassName(position)}`}
            />
          ) : (
            <Styled.StatCardContainer
              key={kebabCase(`${card.title} ${index}`)}
              title={card.title}
              subtitle={card.subtitle}
              caption={card.caption}
              textColor={card.textColor}
              backgroundColor={card.backgroundColor}
              themeSelector={STAT_CARD_THEMES.HOMEPAGE_CAROUSEL}
              className={`slide ${getPositionClassName(position)}`}
            />
          );
        })}
      </Styled.Container>
      <Styled.ArrowContainer>
        <Button
          className="arrow"
          type="roundLeftArrow"
          onClick={debounceRotateRight}
        />
        <Button
          className="arrow"
          type="roundRightArrow"
          onClick={debounceRotateLeft}
        />
      </Styled.ArrowContainer>
    </Styled.StatSlideshow>
  );
};

StatSlideshow.propTypes = {
  /**
   * The stat slideshow cards
   */
  statCards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      caption: PropTypes.string,
      backgroundColor: PropTypes.string.isRequired,
      textColor: PropTypes.string,
    }),
  ).isRequired,
};

export default StatSlideshow;
