import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import kebabCase from 'lodash/kebabCase';
import { getLinkProps } from 'utils/get-link-props';
import { eventTrack, LINK_LIST_CTA_CLICK } from 'utils/google-tag-manager';
import classNames from 'classnames';
import { ACTIVATE_ANIMATION_CLASS } from 'styles/animations';
import { useAppContext } from 'contexts/app-context';
import * as Styled from './JumpLinkGroup.styled';

const onClickCta = event => eventTrack(LINK_LIST_CTA_CLICK, { event });

/**
 * Creates a list of links.
 * @param {Array} links The component links. Composed by title and url.
 * @returns {ReactElement}
 */
const JumpLinkGroup = ({ links }) => {
  const [startAnimation, setStartAnimation] = useState(false);
  const [{ isAntiFlickerActive }] = useAppContext();
  useEffect(() => {
    if (!isAntiFlickerActive) {
      setStartAnimation(true);
    }
  }, [isAntiFlickerActive]);

  return (
    <Styled.JumpLinkGroup
      className={classNames({
        [ACTIVATE_ANIMATION_CLASS]: startAnimation,
      })}
    >
      <Styled.Content>
        {links.map(({ sys, title, url }, index) => {
          const ctaProps = getLinkProps(url);
          return (
            <Styled.Cta
              className={index === 0 ? 'active' : ''}
              key={kebabCase(`${sys.id} ${title}`)}
              isDark={false}
              {...ctaProps}
              onClick={onClickCta}
            >
              {`${index + 1}. ${title}`}
            </Styled.Cta>
          );
        })}
      </Styled.Content>
    </Styled.JumpLinkGroup>
  );
};

JumpLinkGroup.propTypes = {
  /**
   * The component links. Composed by title and url.
   */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      sys: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default JumpLinkGroup;
