import {
  blue135,
  red155,
  spotifyGreen157,
  solidWhite200,
  yellowGreen155,
  blueViolet135,
  brownYellow172,
} from '@spotify-internal/encore-web';

export const white = solidWhite200;
export const black = '#111111';
export const white20OpacityOnBlack = '#414141';
export const spotifyGreen = spotifyGreen157;
export const lightGray = '#E2E2E2';
export const gray = '#8D8D8D';
export const darkGray = '#222222';
export const blue = blue135;
export const red = red155;
export const yellowGreen = yellowGreen155;
export const blueViolet = blueViolet135;
export const redOrange = '#FD8A7E';
export const brownYellow = brownYellow172;
