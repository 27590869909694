// @ts-nocheck
// this is not preferred when using TS but as this is just an example, we are
// using it to avoid need to convert styles/media-queries to TS

import styled from 'styled-components';
import { minWidth, minMax, maxWidth } from 'styles/media-queries';
import { safariOnly, firefoxOnly } from 'styles/browser-media-queries';
import { minBreakpoints, fontWeights, navHeight } from 'styles/variables';

import { Headline, Paragraph, Cta as CtaComponent } from 'components/atoms';
import { container, columnsGutter } from 'styles/grid';
import { multiple } from 'components/atoms/arrow-cta/ArrowCta.styled';
import { ResponsiveImage } from 'components/atoms/responsive-image';
import { solidBlack0 } from '@spotify-internal/encore-web';

export const Root = styled.div`
  ${container}

  position: relative;
  overflow: hidden;
  margin-bottom: 4.6rem;

  background-image: url('/svg/gradient-bg_default.svg');
  background-repeat: no-repeat;
  background-position: right 0rem top 0rem;
  background-size: cover;

  ${minWidth.md`
    background-position: left 0rem top 0rem;
    background-size: auto;
  `}

  ${minWidth.lg`
    margin-bottom: 9.6rem;
  `}

  // SVG backgrounds do not render properly on safari and firefox. Use PNG instead.
  ${safariOnly`
    background-image: url('/svg/gradient-bg_default.png');
  `}
  ${firefoxOnly`
    background-image: url('/svg/gradient-bg_default.png');
  `}
`;

export const Container = styled.div`
  ${columnsGutter}
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  height: calc(100vh - ${navHeight.smToLg}rem);
  min-height: 44.5rem;
  max-height: 75.3rem;
  position: relative;

  ${minWidth.lg`
    height: 75.3rem;
  `}
`;

export const Content = styled.div<{ imageType?: boolean }>`
  grid-column: 1 / 13;
  margin-top: auto;
  margin-bottom: auto;
  order: 2;
  position: relative;

  ${minWidth.lg`
    grid-column: ${props => (props.imageType ? '1/8' : '1/7')};
    order: 1;
  `}
`;

export const TitleIntro = styled(Headline)`
  ${minMax(minBreakpoints.lg, minBreakpoints.xl)`
    font-size: 6.4rem;
    line-height: 6.4rem;
  `}
  color: ${solidBlack0};
  margin-bottom: 3rem;
  margin-right: 3rem;
`;

export const SubHeader = styled(Paragraph)`
  font-weight: ${fontWeights.bold};
  font-size: 1.8rem;
  color: ${solidBlack0};
  margin-bottom: 1rem;
  margin-right: 3.4rem;

  ${minWidth.lg`
    font-size: 2.4rem;
  `}
`;

export const Description = styled(Paragraph)`
  color: ${solidBlack0};
`;

export const Cta = styled(CtaComponent)`
  ${multiple};
  margin-top: 2.4rem;
`;

export const MobileChromeImage = styled.div.attrs({
  role: 'img',
})`
  display: block;
  margin-top: 4rem;
  overflow: hidden;
  padding-top: 70.8rem;
  position: relative;

  &::before {
    background: no-repeat center left / contain
      ${props => `url(${props['data-src']})`};
    ${maxWidth.xl`
      background: no-repeat center right / contain ${props =>
        `url(${props['data-src']})`};
      `}
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

export const DesktopChromeImage = styled(ResponsiveImage)`
  height: 100%;
  object-fit: cover;
  object-position: left 50%;

  ${minWidth.xxl`
    object-fit: contain;
    object-position: center;
  `}
`;

export const HeroImage = styled.div<{ imageType?: boolean }>`
  display: none;
  position: relative;
  ${minWidth.lg`
    display: block;
    grid-column: ${props => (props.imageType ? '8/13' : '7/13')};
    order: 2;
  `};
`;

export const DownArrowWrapper = styled.div`
  position: absolute;
  bottom: 2.4rem;
  left: 0;

  ${minWidth.lg`
    bottom: -0.2rem;
  `}
`;
