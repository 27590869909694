import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import lowerCase from 'lodash/lowerCase';
import { solidBlack0, solidWhite200 } from '@spotify-internal/encore-web';
import { AudioWithTranscript } from 'components/molecules';
import * as Styled from './Topic.styled';

const THEME = {
  dark: solidWhite200,
  light: solidBlack0,
};

/**
 * Renders a Topic module
 * @param {string} backgroundColor The component background color.
 * @param {string} headlineColor The component headline color.
 * @param {boolean} theme True if the theme is light, false if it is dark.
 * @param {string} title The component title.
 * @param {Object} audioWithTranscript
 * @returns {ReactElement}
 */
const Topic = ({
  backgroundColor = '',
  headlineColor = '',
  theme,
  title = '',
  audioWithTranscript,
}) => {
  const textColor = useMemo(() => THEME[lowerCase(theme)], [theme]);

  return (
    audioWithTranscript && (
      <Styled.Root backgroundColor={backgroundColor}>
        <Styled.Container>
          <Styled.Wrapper tabIndex={0}>
            {title && (
              <Styled.Title textColor={textColor}>{title}</Styled.Title>
            )}
          </Styled.Wrapper>
        </Styled.Container>
        {audioWithTranscript && (
          <AudioWithTranscript
            eyebrow={audioWithTranscript.eyebrow}
            title={audioWithTranscript.title}
            description={audioWithTranscript.description}
            audio={audioWithTranscript.audio}
            transcript={audioWithTranscript.transcript}
            cta={audioWithTranscript.cta}
            backgroundColor={backgroundColor}
            textColor={textColor}
            headlineColor={headlineColor}
            image={audioWithTranscript.image}
            imageTitle={audioWithTranscript.imageTitle}
            imageSubtitle={audioWithTranscript.imageSubtitle}
          />
        )}
      </Styled.Root>
    )
  );
};

Topic.propTypes = {
  /**
   * The component background color.
   */
  backgroundColor: PropTypes.string,
  /**
   * The component headline color.
   */
  headlineColor: PropTypes.string,
  /**
   * True if the theme is light, false if it is dark.
   */
  theme: PropTypes.string.isRequired,
  /**
   * The component title.
   */
  title: PropTypes.string,
};

export default Topic;
