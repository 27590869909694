import React from 'react';
import PropTypes, { any } from 'prop-types';

import { getLinkProps } from 'utils/get-link-props';
import { eventTrack, LINK_LIST_CTA_CLICK } from 'utils/google-tag-manager';

import { ThemeProvider } from 'styled-components';
import { Headline, Paragraph } from 'components/atoms';
import { ModuleRenderer } from 'utils/module-renderer';
import * as Styled from './SplitView.styled';

const onClickCta = event => eventTrack(LINK_LIST_CTA_CLICK, { event });

/**
 * Creates a list of links.
 * @param {string} title
 * @param {string} eyebrow
 * @param {string} description
 * @param {object} cta
 * @param backgroundColor
 * @param fullWidth
 * @param {Array} leftContent
 * @param {Array} rightContent
 * @returns {ReactElement}
 */
const SplitView = ({
  title = '',
  eyebrow = '',
  description = '',
  cta = null,
  backgroundColor = '',
  fullWidth = false,
  leftContent = [],
  rightContent = [],
}) => {
  const hasCta = !!cta;
  const hasText = !!title;

  const renderHeader = () => {
    return (
      <Styled.HeaderContainer>
        <Styled.TitleContainer>
          <Styled.Eyebrow>{eyebrow}</Styled.Eyebrow>
          <Headline tag="h1" styling="h1" text={title} />
        </Styled.TitleContainer>
        <Styled.DescriptionContainer>
          <Paragraph>{description}</Paragraph>
          {cta && (
            <Styled.Cta
              type="primary"
              isDarkTheme
              overrideFunctionality={cta.overrideFunctionality}
              {...getLinkProps(cta.url)}
              onClick={onClickCta}
            >
              {cta.title}
            </Styled.Cta>
          )}
        </Styled.DescriptionContainer>
      </Styled.HeaderContainer>
    );
  };

  return (
    <ThemeProvider
      theme={{
        hasCta,
        backgroundColor,
        hasText,
        fullWidth,
      }}
    >
      <Styled.Container>
        {!hasCta && hasText && renderHeader()}
        <Styled.ModuleContainer>
          <Styled.LeftContent>
            {hasCta && hasText && renderHeader()}
            <ModuleRenderer components={leftContent} />
          </Styled.LeftContent>
          <Styled.RightContent>
            <ModuleRenderer components={rightContent} />
          </Styled.RightContent>
        </Styled.ModuleContainer>
      </Styled.Container>
    </ThemeProvider>
  );
};

SplitView.propTypes = {
  /**
   * The title of the component
   */
  title: PropTypes.string,
  /**
   * The eyebrow of the component
   */
  eyebrow: PropTypes.string,
  /**
   * The description of the component
   */
  description: PropTypes.string,
  /**
   * The cta
   */
  cta: PropTypes.objectOf(any),
  /**
   * The left content array
   */
  leftContent: PropTypes.arrayOf(any),
  /**
   * The right content array
   */
  rightContent: PropTypes.arrayOf(any),
};

export default SplitView;
