import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';

import { Eyebrow, Button } from 'components/atoms';
import { getPageEyebrow } from 'utils/get-page-eyebrow';
import { useImageSupport } from 'utils/use-image-support';
import { PAGES } from 'constants/content-types';
import * as CommonTypes from 'constants/common-proptypes';
import { ACTIVATE_ANIMATION_CLASS } from 'styles/animations';
import { useAppContext } from 'contexts/app-context';

import { CTA_TYPES } from 'constants/cta-types';
import { getLinkProps } from 'utils/get-link-props';
import Link from 'next/link';
import { MODULES_MULTI_CTA } from 'utils/google-tag-manager';
import { useRouter } from 'next/router';
import { ModuleRenderer } from 'utils/module-renderer';
import { black, white } from 'styles/colors';
import mParticle from '@mparticle/web-sdk';
import { Video } from '../../atoms';
import { Byline } from '../byline';
import * as Styled from './Hero.styled';

const PAGE_VARIATIONS = {
  withImage: {
    [PAGES.HOME]: Styled.MODIFIERS.useA,
    [PAGES.LANDING]: Styled.MODIFIERS.useE,
    [PAGES.DETAIL]: Styled.MODIFIERS.useB,
  },
};

/**
 * getPageVariation - Gets the page variation modifier based on certain rules
 * @param {string} pageContext - The page content type
 * @param themeSelector - selecting the theme of the hero
 * @returns {string}
 */
function getPageVariation(pageContext, themeSelector) {
  if (themeSelector === null) return PAGE_VARIATIONS.withImage[pageContext];
  if (themeSelector === 'image right') {
    return Styled.MODIFIERS.useA;
  }
  if (themeSelector === 'image left') {
    return Styled.MODIFIERS.useB;
  }
  if (themeSelector === 'text left module') {
    return Styled.MODIFIERS.useC;
  }
  if (themeSelector === 'text center module') {
    return Styled.MODIFIERS.useD;
  }
  if (themeSelector === 'full bleed image top') {
    return Styled.MODIFIERS.useE;
  }
  if (themeSelector === 'full bleed image bottom') {
    return Styled.MODIFIERS.useG;
  }
  if (themeSelector === 'video') {
    return Styled.MODIFIERS.useF;
  }
  if (themeSelector === 'text only center') {
    return Styled.MODIFIERS.useH;
  }

  return Styled.MODIFIERS.useA;
}

/**
 * FloatingBox Component
 * @param {object} props
 * @param {object} props.children - React children reference
 * @param {string} props.pageContext - The page content type
 */
const VariationWrapper = ({ children, pageVariation }) => {
  const [Content, FigureContainer, ModuleContainer, VideoContainer] = children;

  if (
    pageVariation === Styled.MODIFIERS.useA ||
    pageVariation === Styled.MODIFIERS.useG
  ) {
    return (
      <>
        {Content}
        {FigureContainer}
      </>
    );
  }

  if (
    pageVariation === Styled.MODIFIERS.useB ||
    pageVariation === Styled.MODIFIERS.useE
  ) {
    return (
      <>
        {FigureContainer}
        {Content}
      </>
    );
  }

  if (
    pageVariation === Styled.MODIFIERS.useC ||
    pageVariation === Styled.MODIFIERS.useD
  ) {
    return (
      <>
        {Content}
        {ModuleContainer}
      </>
    );
  }

  if (pageVariation === Styled.MODIFIERS.useF) {
    return (
      <>
        {Content}
        {VideoContainer}
      </>
    );
  }

  return <>{Content}</>;
};

function hexToRgbA(hex) {
  let color;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    color = hex.substring(1).split('');
    if (color.length === 3) {
      color = [color[0], color[0], color[1], color[1], color[2], color[2]];
    }
    color = `0x${color.join('')}`;
    // eslint-disable-next-line no-bitwise
    return `rgba(${[(color >> 16) & 255, (color >> 8) & 255, color & 255].join(
      ',',
    )},1)`;
  }
  throw new Error('Bad Hex');
}

function getTagButtons(tags) {
  const primaryTag = tags?.filter(
    tag => tag.id.includes('articles') || tag.id.includes('successStories'),
  );

  let filteredTags = tags?.filter(topic => topic.id.startsWith('topic'));
  filteredTags = filteredTags.map(topic =>
    topic.id.startsWith('topic') ? topic.name.split(':')[1] : topic.name,
  );

  primaryTag.forEach(tag => filteredTags.unshift(tag.name));

  return filteredTags;
}

function getRoute(tagName, locale) {
  let tagRoute;
  switch (tagName.toLowerCase()) {
    case 'articles':
      tagRoute = 'news-and-insights';
      break;
    case 'success stories':
      tagRoute = 'inspiration';
      break;
    default:
      tagRoute = tagName
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-');
  }
  return ` /${locale}/${tagRoute}`;
}

/**
 * Hero Component
 * @param {object} props
 * @param {object} props.data - Page data
 * @param {string} props.pageContext - The page content type
 * @returns {ReactComponent}
 */
const Hero = ({ data, pageContext, author = null, publishDate, tags }) => {
  const { hero } = data;
  const [appState] = useAppContext();
  const { locale } = appState;
  const isDarkTheme = hero.theme === 'Dark';
  const noTextColor = !hero.textColor && isDarkTheme ? white : black;
  const textColor = hero.textColor ? hero.textColor : noTextColor;
  const hasLowerNav = !!data.lowerNavigation;
  const ctaItems = get(hero, 'ctasCollection.items', []);
  const hasModule = hero.moduleCollection?.items.length === 1;
  const tag = getPageEyebrow(data);
  const imageLink = hero.imageLink && getLinkProps(hero.imageLink);
  const router = useRouter();
  const videoRef = useRef(null);
  const [lowPowerMode, setLowPowerMode] = useState(false);
  const filteredTags = tags && getTagButtons(tags);
  const pageVariation = getPageVariation(
    pageContext,
    hero.themeSelector?.toLowerCase(),
  );

  // eslint-disable-next-line no-nested-ternary
  const containerBackgroundColor = hero.backgroundColor
    ? hexToRgbA(hero.backgroundColor)
    : isDarkTheme
    ? hexToRgbA(black)
    : hexToRgbA(white);
  // eslint-disable-next-line no-nested-ternary
  const defaultBackgroundColor = hero.backgroundColor
    ? hero.backgroundColor
    : isDarkTheme
    ? black
    : white;
  const { queryUrl, fileExt } = useImageSupport();
  const [startAnimation, setStartAnimation] = useState(false);
  const [{ isAntiFlickerActive }] = useAppContext();
  useEffect(() => {
    if (!isAntiFlickerActive) {
      setStartAnimation(true);
    }
  }, [isAntiFlickerActive]);

  useEffect(() => {
    (async () => {
      if ('getBattery' in navigator) {
        const battery = await navigator.getBattery();
        if (battery.level <= 0.2) {
          setLowPowerMode(true);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (lowPowerMode && videoRef.current) {
      videoRef.current.pause();
    }
  }, [lowPowerMode]);

  return (
    <Styled.Header
      textColor={textColor}
      modifier={pageVariation}
      backgroundColor={defaultBackgroundColor}
      foregroundImage={hero.foregroundImage && hero.foregroundImage.url}
    >
      <Styled.Container
        modifier={pageVariation}
        backgroundColor={containerBackgroundColor}
        foregroundImage={hero.foregroundImage && hero.foregroundImage.url}
        lowerNav={hasLowerNav}
        isHomePage={pageContext === 'PageHome'}
        className="container"
        hasModule={hasModule}
      >
        <VariationWrapper pageVariation={pageVariation}>
          <Styled.Content
            modifier={pageVariation}
            backgroundColor={hero.backgroundColor}
            textColor={textColor}
            className={classNames({
              [ACTIVATE_ANIMATION_CLASS]: startAnimation,
            })}
          >
            <Styled.ContentContainer
              className={classNames('content-container', {
                [ACTIVATE_ANIMATION_CLASS]: startAnimation,
              })}
            >
              {filteredTags && (
                <Styled.TagContainer
                  className={classNames('tag-container', {
                    [ACTIVATE_ANIMATION_CLASS]: startAnimation,
                  })}
                >
                  {filteredTags.map(tagName => (
                    <Button
                      key={`Button-${tagName}`}
                      text={tagName}
                      type="articleTag"
                      className="tag-button"
                      onClick={() => router.push(getRoute(tagName, locale))}
                    />
                  ))}
                </Styled.TagContainer>
              )}
              {hero.title &&
                (tag || hero.eyebrow ? (
                  <Eyebrow
                    css={Styled.eyebrowStyles}
                    className={classNames('eyebrow', {
                      [ACTIVATE_ANIMATION_CLASS]: startAnimation,
                    })}
                  >
                    {tag || hero.eyebrow}
                  </Eyebrow>
                ) : (
                  ' '
                ))}
              <Styled.Title
                styling="h1"
                className={classNames('title', {
                  [ACTIVATE_ANIMATION_CLASS]: startAnimation,
                })}
                text={hero.title}
              />
              <Styled.Subtitle
                styling="h1"
                className={classNames('subtitle', {
                  [ACTIVATE_ANIMATION_CLASS]: startAnimation,
                })}
                text={hero.subtitle ? hero.subtitle : ''}
              />
              {[
                Styled.MODIFIERS.useA,
                Styled.MODIFIERS.useB,
                Styled.MODIFIERS.useD,
                Styled.MODIFIERS.useC,
                Styled.MODIFIERS.useF,
                Styled.MODIFIERS.useH,
              ].includes(pageVariation) &&
                hero.description && (
                  <Styled.Description
                    className={classNames('description', {
                      [ACTIVATE_ANIMATION_CLASS]: startAnimation,
                    })}
                  >
                    {hero.description}
                  </Styled.Description>
                )}
              {[
                Styled.MODIFIERS.useA,
                Styled.MODIFIERS.useB,
                Styled.MODIFIERS.useE,
                Styled.MODIFIERS.useC,
                Styled.MODIFIERS.useH,
                Styled.MODIFIERS.useF,
              ].includes(pageVariation) &&
                ctaItems && (
                  <Styled.CtaWrapper
                    className={classNames('cta-wrapper', {
                      [ACTIVATE_ANIMATION_CLASS]: startAnimation,
                    })}
                  >
                    {ctaItems.map(
                      (
                        { title, url, type, overrideFunctionality },
                        indexCta,
                      ) => {
                        const { href, asLink } = getLinkProps(url);

                        const onCtaClick = () => {
                          mParticle.logEvent(
                            'action',
                            mParticle.EventType.Other,
                            {
                              action_text: 'hero button click',
                              action_location: MODULES_MULTI_CTA.heroDynamic,
                            },
                          );
                        };

                        return (
                          <Styled.Cta
                            key={kebabCase(`${title}-${indexCta}`)}
                            type={
                              type === CTA_TYPES.SECONDARY
                                ? CTA_TYPES.TERTIARY
                                : type
                            }
                            overrideFunctionality={overrideFunctionality}
                            href={href}
                            asLink={asLink}
                            onClick={onCtaClick}
                            isDarkTheme={hero.theme === 'Dark'}
                          >
                            {title}
                          </Styled.Cta>
                        );
                      },
                    )}
                  </Styled.CtaWrapper>
                )}
              {author && (
                <Styled.BylineContainer
                  className={classNames('bylineContainer', {
                    [ACTIVATE_ANIMATION_CLASS]: startAnimation,
                  })}
                >
                  <Byline
                    author={author}
                    publishDate={publishDate}
                    isDarkTheme={isDarkTheme}
                  />
                </Styled.BylineContainer>
              )}
            </Styled.ContentContainer>
          </Styled.Content>
          {hero.foregroundImage && fileExt && (
            <Link href={(hero.imageLink && imageLink.href) || '#'} passHref>
              <Styled.FigureContainer
                url={hero.foregroundImage[queryUrl]}
                pageVariation={pageVariation}
                className={classNames({
                  [ACTIVATE_ANIMATION_CLASS]: startAnimation,
                })}
              />
            </Link>
          )}
          {hasModule && (
            <Styled.ModuleContainer
              className={classNames({
                [ACTIVATE_ANIMATION_CLASS]: startAnimation,
              })}
            >
              <ModuleRenderer components={hero.moduleCollection?.items} />
            </Styled.ModuleContainer>
          )}
          {hero.video && (
            <Styled.VideoContainer
              className={classNames('videoContainer', {
                [ACTIVATE_ANIMATION_CLASS]: startAnimation,
              })}
              isHeroVideo={!!hero.video.heroVideo}
            >
              {hero.video.heroVideo ? (
                <Styled.HeroVideoContainer>
                  {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                  <video
                    ref={videoRef}
                    autoPlay={!lowPowerMode}
                    loop={!lowPowerMode}
                    muted
                    playsInline
                    controls={false}
                  >
                    <source src={hero.video.heroVideo.url} type="video/mp4" />
                  </video>
                </Styled.HeroVideoContainer>
              ) : (
                <Video
                  className="video"
                  videoId={hero.video.videoId}
                  type={hero.video.type}
                  videoName={hero.video.name}
                  playing={false}
                  controls
                  muted={false}
                  loop
                  showCaptions
                />
              )}
            </Styled.VideoContainer>
          )}
        </VariationWrapper>
      </Styled.Container>
    </Styled.Header>
  );
};

Hero.propTypes = {
  /**
   * Page data
   */
  data: PropTypes.shape({
    /**
     * Hero data
     */
    hero: PropTypes.shape({
      /**
       * The heading title
       */
      title: PropTypes.string.isRequired,
      /**
       * The color of the title
       */
      titleColor: PropTypes.string,
      /**
       * The heading subtitle
       */
      subtitle: PropTypes.string,
      /**
       * The heading eyebrow
       */
      eyebrow: PropTypes.string,
      /**
       * The foreground image
       */
      foregroundImage: CommonTypes.Image,
      /**
       * The color of the text
       */
      textColor: PropTypes.string,
      /**
       * The color of the theme
       */
      theme: PropTypes.string,
      /**
       * Color used as background behind text
       */
      backgroundColor: PropTypes.string,
      /**
       * override functionality for AdStudio Login and Lead Gen Form
       */
      overrideFunctionality: PropTypes.string,
      /**
       * Url of the link or dynamic definition in case of dynamic link
       */
      imageLink: PropTypes.string,
      /**
       * Url of the link or dynamic definition in case of dynamic link
       */
      videoLink: PropTypes.string,
      /**
       * The component video, composed by the url and description.
       */
      video: PropTypes.shape({
        videoId: PropTypes.string,
        videoName: PropTypes.string, // The video name set by author
        type: PropTypes.string, // The video player <Youtube | Vimeo>
        caption: PropTypes.string, // The video caption set by author
        controls: PropTypes.bool, // Shows or hide video player controls
      }),
    }),
  }).isRequired,
  /**
   * The page content type
   */
  pageContext: PropTypes.string,
};

export default Hero;
