import React from 'react';
import PropTypes, { any } from 'prop-types';
import { get, kebabCase } from 'lodash';
import { CtaCard, Figure } from 'components/molecules';
import * as Styled from './Gallery.styled';

/**
 * Renders the Gallery
 * @param {number} columns
 * @param {number} rows
 * @param {Array} items
 * @param {boolean} fullWidth full width style
 * @returns {ReactElement}
 */
const Gallery = ({ columns = 1, rows = 1, items = [], fullWidth = false }) => {
  const renderItem = (item, index) => {
    // eslint-disable-next-line no-underscore-dangle
    switch (item.__typename) {
      case 'CtaCard':
        return (
          <CtaCard
            key={kebabCase(`${item.title}-${index}`)}
            title={item.title}
            description={item.description}
            image={item.image}
            ctas={get(item, 'ctasCollection.items', [])}
            themeSelector={item.themeSelector}
            className="gallery-card"
          />
        );
      case 'ModuleImage':
        return <Figure data={item} type="image" />;
      default:
        return <></>;
    }
  };

  return (
    <Styled.Gallery>
      <Styled.GridContainer fullWidth={fullWidth} columns={columns} rows={rows}>
        {items.map((item, index) => renderItem(item, index))}
      </Styled.GridContainer>
    </Styled.Gallery>
  );
};

Gallery.propTypes = {
  /**
   * The number of columns in the gallery
   */
  columns: PropTypes.string,
  /**
   * The number of rows in the gallery
   */
  rows: PropTypes.string,
  /**
   * List of gallery items
   */
  items: PropTypes.arrayOf(any),
  /**
   * full width styling
   */
  fullWidth: PropTypes.bool,
};

export default Gallery;
