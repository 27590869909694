import styled, { css } from 'styled-components';

import { minWidth } from 'styles/media-queries';
import { columnsGutter } from 'styles/grid';
import { Modal } from 'components/molecules/modal';
import { levels } from 'styles/z-index';
import { animations } from 'styles/variables';

import { Cta, Icon, DropdownMenu, Headline } from 'components/atoms';
import { darkGray, white } from 'styles/colors';
import {
  spacer8,
  spacer32,
  spacer64,
  spacer80,
  spacer16,
} from 'styles/spacers';

export const Footer = styled.footer`
  background-color: ${darkGray};
  color: ${white};
  margin-top: auto;
  padding: ${spacer16} 0;
  z-index: ${levels.base};

  ${minWidth.lg`
    padding: ${spacer80} 0;
  `}
`;

export const Content = styled.div`
  ${columnsGutter}

  ${minWidth.lg`
    margin-bottom: ${props => !props.hideFooterLinks && `5rem`};
  `}
`;

export const LogoWrapper = styled.div`
  line-height: 0; // prevents extra whitespace around svg
`;

export const Logo = styled(Cta).attrs({ type: 'textlink' })`
  display: inline-block;

  /* ReactSVG adds extra div wrapper */
  div,
  svg {
    height: 3rem;
    width: 19.3rem;

    ${minWidth.lg`
      height: 4rem;
      width: 25.8rem;
  `}
  }

  svg {
    width: auto;
    color: ${white};
  }
`;

export const LocaleContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${minWidth.lg`
    flex-direction: row;
    align-items: center;
  `}
`;

export const CtaLocaleSelector = styled(Cta).attrs({
  tag: 'button',
  type: 'textLink',
})`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 1.6rem;
  text-decoration: none;

  ${minWidth.lg`
    margin-right: 3.2rem;
    margin-bottom: 0;
  `}

  &:hover {
    text-decoration: underline;
  }
`;

export const LocaleGlobe = styled(Icon)`
  line-height: 1.6rem;
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 0.4rem;
  transition: transform ${animations.defaultTransition};

  div,
  svg {
    height: inherit;
    width: inherit;
    color: ${white};
  }

  ${minWidth.lg`
    display: block;
    margin-right: 0.8rem;
  `}
`;

export const ModalLocales = styled(Modal)`
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 2rem;

  ${minWidth.sm`
    padding-left: 4rem;
    padding-right: 4rem;
  `}

  ${minWidth.md`
    width: 80%;
    max-width: 84.9rem;
    padding-left: 8rem;
    padding-right: 8rem;
  `}
`;

export const LinkCollectionContainer = styled.div`
  ${columnsGutter}

  margin: 4.5rem 0;

  ${minWidth.lg`
    -ms-grid-column: 2;
    -ms-grid-columns: 1fr 1fr 2fr 1fr;
    display: -ms-grid;
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1fr;
    margin: ${spacer64} 0;
  `}
`;

export const FooterLinkColumn = styled.div`
  ${minWidth.lg`
    -ms-grid-column: ${props => (props.column ? props.column : 1)};
  `}
`;

export const LinkCollectionItem = styled.div`
  padding: ${spacer8} 0;
  ${minWidth.lg`
    margin-bottom: ${spacer32};
    padding: 0;
  `}
`;

export const LinkCollectionTitle = styled(Headline)`
  display: block;

  ${minWidth.lg`
    padding: 0.5rem 0rem;
  `}
`;

export const LinkCollection = styled.ul`
  margin-bottom: ${spacer8};

  ${minWidth.lg`
    margin-bottom: 0;
  `}
`;

export const LinkContainer = styled.li`
  padding: ${spacer8} 0;
`;

export const Link = styled(Cta).attrs({
  type: 'TextLink',
})`
  color: ${white};
  text-decoration: none;

  &:hover {
    color: ${white};
    text-decoration: underline;
  }
`;

export const LinkDrop = styled(LinkCollectionTitle).attrs({
  tag: 'button',
})`
  color: ${white};
`;

export const FooterLinkGroup = styled(DropdownMenu)`
  transition: transform ${animations.defaultTransition};
`;

export const Caret = styled(Icon)`
  display: inline-block;
  font-size: 0;
  height: 2rem;
  margin-left: auto;
  transform: ${({ isOpen }) => `${isOpen ? 'rotate(-180deg)' : 'unset'}`};
  transition: transform ${animations.defaultTransition};
  width: 2rem;
  color: ${white};

  div,
  svg {
    height: inherit;
    width: inherit;
  }

  ${minWidth.lg`
    display: block;
    margin-left: 0.8rem;
  `}
`;

export const FooterLinkGroupTitle = styled(LinkCollectionItem)`
  display: flex;
`;

export const SocialLinks = styled.div`
  display: flex;
  margin-bottom: 3.6rem;

  svg {
    color: ${white};

    .social-icon {
      color: ${white};
    }
  }

  ${minWidth.lg`
    -ms-grid-column: 3;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
  `}
`;

export const SocialLink = styled.a`
  margin-right: 2.4rem;
  max-height: 4rem;

  &:last-child {
    margin-right: 0;
  }
`;

export const Legal = styled.div`
  display: flex;
  flex-direction: column;

  ${minWidth.lg`
    -ms-grid-row: 2;
    flex-direction: row;
    grid-column: 1 / 4;
    justify-content: space-between;
  `}
`;

export const LegalLinksList = styled.ul`
  margin-bottom: 4.8rem;

  ${minWidth.lg`
    display: flex;
    margin-bottom: 0;
  `}
`;

export const LegalLinkItem = styled.li`
  margin-bottom: ${spacer16};
  &:last-child {
    margin-bottom: 0;
  }

  ${minWidth.lg`
    margin-bottom: 0;
    margin-right: ${spacer16};

    &:last-child {
      margin-right: 0;
    }
  `}
`;

const legalStyles = css`
  font-size: 1.2rem;
  line-height: 110%;
  text-decoration: none;
`;

export const LegalLink = styled(Cta).attrs({
  type: 'TextLink',
})`
  ${legalStyles}

  &:hover {
    text-decoration: underline;
  }
`;

export const Copy = styled.span`
  ${legalStyles}
`;
