import styled from 'styled-components';

import { black, white20OpacityOnBlack, white, darkGray } from 'styles/colors';
import { animations, fontWeights } from 'styles/variables';
import { opacityWhite30 } from '@spotify-internal/encore-web';
import { minWidth } from 'styles/media-queries';

export const RoundButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${white};
  path {
    fill: ${black};
    transition: fill ${animations.defaultTransition};
  }
  transition: background-color ${animations.defaultTransition};

  &:hover {
    background-color: ${white20OpacityOnBlack};
    path {
      fill: ${white};
    }
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const RoundClose = styled(RoundButton)`
  width: 3rem;
  height: 3rem;
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const RoundArrow = styled(RoundButton)`
  width: 2.4rem;
  height: 2.4rem;
  transition: all ${animations.defaultTransition};

  svg {
    width: 1.5rem;
    height: 1.5rem;
    transition: all ${animations.defaultTransition};
  }

  ${minWidth.lg`
    width: 3.2rem;
    height: 3.2rem;

    svg {
      width: 2rem;
      height: 2rem;
    }
  `}
`;

export const ArticleTag = styled.button`
  font-size: 1.25rem;
  font-weight: ${fontWeights.bold};
  height: 3rem;
  border-radius: 5rem;
  background-color: ${opacityWhite30};
  width: max-content;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  transition: background-color ${animations.defaultTransition};

  &:hover {
    background-color: ${darkGray};
  }
`;

export const FilterButton = styled.button`
  border: 1px solid ${white};
  color: ${white};
  border-radius: 5rem;
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
  height: 5rem;
  font-weight: ${fontWeights.bold};
  transition: background-color ${animations.defaultTransition};

  svg {
    margin-left: 1rem;
    margin-top: 1rem;
  }

  &:hover {
    background-color: ${white20OpacityOnBlack};
  }
`;

export const SecondaryButton = styled.button`
  font-weight: ${fontWeights.bold};
  color: ${white};
  border: 1px solid ${white};
  background-color: ${black};
  border-radius: 5rem;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  transition: background-color ${animations.defaultTransition},
    border-color ${animations.defaultTransition};

  &:hover {
    border-color: ${white20OpacityOnBlack};
    background-color: ${white20OpacityOnBlack};
  }
`;

export const TertiaryButton = styled.button`
  font-weight: ${fontWeights.bold};
  color: ${black};
  border: 1px solid ${black};
  background-color: ${white};
  border-radius: 5rem;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  transition: background-color ${animations.defaultTransition},
    border-color ${animations.defaultTransition},
    color ${animations.defaultTransition};

  &:hover {
    border-color: ${white20OpacityOnBlack};
    background-color: ${white20OpacityOnBlack};
    color: ${white};
  }
`;

export const CultureNextButton = styled.button`
  font-size: 1.6rem;
  font-weight: ${fontWeights.normal};
  color: ${white};
  border: 1px solid ${white};
  border-radius: 2.5rem;
  padding: 1.2rem 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color ${animations.defaultTransition},
    border-color ${animations.defaultTransition};

  &:hover {
    border-color: ${white20OpacityOnBlack};
    background-color: ${white20OpacityOnBlack};
  }

  &.no-outline {
    font-weight: ${fontWeights.bold};
    border: none;
  }
`;
