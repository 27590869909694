import React, { useEffect, useState } from 'react';
import PropTypes, { any } from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { ICONS } from 'constants/icons';
import { Button, Headline } from 'components/atoms';
import { get } from 'lodash';
import debounce from 'lodash/debounce';
import { useTranslation } from 'i18n/nexti18n';
import { isXSmallToMedium } from 'styles/media-queries';
import { Quote } from 'components/molecules';
import { gray20 } from '@spotify-internal/encore-web';
import * as Styled from './TrendDetails.styled';

const RESIZE_DEBOUNCE_DELAY = 250;

/**
 * Renders trend details overlay
 * @param {Object} trend
 * @param {number|undefined} index
 * @param {string|undefined} previousTitle
 * @param {Function} handlePrevious
 * @param {string|undefined} nextTitle
 * @param {Function} handleNext
 * @param {Function} handleClose
 * @returns {ReactElement}
 */
const TrendDetails = ({
  index,
  trend,
  previousTitle,
  handlePrevious,
  nextTitle,
  handleNext,
  handleClose,
}) => {
  const {
    title,
    image,
    accentColor,
    description,
    insightsTitle,
    insightsQuote,
    insightsImage,
    insightsImageCaption,
    footnotes,
  } = trend;

  const [isMobile, setIsMobile] = useState(isXSmallToMedium());
  const { t } = useTranslation();

  useEffect(() => {
    const onResize = debounce(() => {
      setIsMobile(isXSmallToMedium());
    }, RESIZE_DEBOUNCE_DELAY);
    window.addEventListener('resize', onResize);
  }, []);

  const insights = get(trend, 'trendInsightCollection.items', []);
  const ctas = get(trend, 'ctasCollection.items', []);

  // start indexes at 1 for user purposes
  const indexDisplay = index + 1 < 10 ? `0${index + 1}` : index + 1;
  const prevIndexDisplay = index < 10 ? `0${index}` : index;
  const nextIndexDisplay = index + 2 < 10 ? `0${index + 2}` : index + 2;

  return (
    <ThemeProvider
      theme={{
        accentColor,
      }}
    >
      <Styled.TrendDetailsOverlay>
        <Styled.Container>
          <Styled.ButtonContainer>
            <Button type="roundClose" onClick={handleClose} />
          </Styled.ButtonContainer>
          <Styled.DetailsContainer>
            <Styled.NavigationContainer>
              <Styled.Navigation>
                <Styled.NavItem
                  className={`previous ${previousTitle ? '' : 'noshow'}`}
                  onClick={previousTitle && handlePrevious}
                >
                  <Styled.Arrow name={ICONS.ARROW} className="left" />
                  {!isMobile &&
                    previousTitle &&
                    `${prevIndexDisplay} ${previousTitle.split('__').join('')}`}
                </Styled.NavItem>
                {nextTitle && (
                  <Styled.NavItem className="next" onClick={handleNext}>
                    {!isMobile &&
                      nextTitle &&
                      `${nextIndexDisplay} ${nextTitle.split('__').join('')}`}
                    <Styled.Arrow name={ICONS.ARROW} />
                  </Styled.NavItem>
                )}
              </Styled.Navigation>
            </Styled.NavigationContainer>
            <Styled.TitleContainer>
              <Headline styling="h5">
                <strong>{indexDisplay}</strong>
              </Headline>
              <Headline styling="h1" text={title} />
            </Styled.TitleContainer>
            {image && image.url && (
              <Styled.SectionContainer>
                <Styled.Image data-src={image.url} />
              </Styled.SectionContainer>
            )}
            <Styled.DescriptionContainer>
              <Headline styling="h2">
                <strong>{description}</strong>
              </Headline>
            </Styled.DescriptionContainer>
            {insights && insights.length > 0 && (
              <section>
                <Styled.SectionLine />
                {insightsTitle && (
                  <Styled.InsightTitle>{insightsTitle}</Styled.InsightTitle>
                )}
                <Styled.InsightsGrid length={insights.length}>
                  {insights.map(insight => (
                    <Styled.InsightsStatCard
                      key={get(insight, 'sys.id')}
                      className="insight-card"
                      title={insight.title}
                      subtitle={insight.subtitle}
                      captionTitle={insight.captionTitle}
                      caption={insight.caption}
                      textColor={insight.textColor}
                      backgroundColor={insight.backgroundColor}
                      image={insight.image}
                      themeSelector={insight.themeSelector}
                    />
                  ))}
                </Styled.InsightsGrid>
                {insightsQuote && (
                  <Quote
                    quote={`__${insightsQuote.quote}__`}
                    speaker={insightsQuote.speaker}
                    companyOrRole={insightsQuote.companyOrRole}
                    backgroundColor={insightsQuote.backgroundColor}
                    quoteColor={insightsQuote.quoteColor}
                    attributionTextColor={insightsQuote.attributionTextColor}
                    cta={insightsQuote.cta}
                    displaySplitView={insightsQuote.displaySplitView}
                    centered={insightsQuote.centered}
                  />
                )}
                {insightsImage && (
                  <Styled.SectionContainer>
                    <Styled.Image data-src={insightsImage.url} />
                  </Styled.SectionContainer>
                )}
                {insightsImageCaption && (
                  <Styled.DescriptionContainer>
                    <Headline styling="h6" text={insightsImageCaption} />
                  </Styled.DescriptionContainer>
                )}
              </section>
            )}
            {ctas && ctas.length > 0 && (
              <section>
                <Styled.SectionLine />
                <Styled.CtaTitle
                  styling="h2"
                  text={`__${t('tryThisTrend')}__`}
                />
                <Styled.CtaGridContainer
                  length={ctas.length}
                  backgroundColor={ctas[0].backgroundColor}
                >
                  {ctas.map(cta => (
                    <Styled.CtaBanner
                      key={get(cta, 'sys.id')}
                      title={cta.title}
                      description={cta.description}
                      backgroundColor={cta.backgroundColor}
                      textColor={cta.textColor}
                      centered
                      fullWidth
                      cta={cta.cta}
                    />
                  ))}
                </Styled.CtaGridContainer>
              </section>
            )}
            {footnotes && (
              <Styled.SectionContainer>
                <Styled.Footnotes
                  className="trend-details-footnotes"
                  footnotes={footnotes.footnotes}
                  dropdown
                  textColor={gray20}
                  fullWidth
                />
              </Styled.SectionContainer>
            )}
          </Styled.DetailsContainer>
        </Styled.Container>
      </Styled.TrendDetailsOverlay>
    </ThemeProvider>
  );
};

TrendDetails.propTypes = {
  /**
   * The trend data
   */
  trend: PropTypes.objectOf(any),
  /**
   * index of the trend in trend array
   */
  index: PropTypes.number,
  /**
   * title of previous trend or undefined
   */
  previousTitle: PropTypes.string,
  /**
   * handler for navigating to previous trend
   */
  handlePrevious: PropTypes.func,
  /**
   * title of next trend or undefined
   */
  nextTitle: PropTypes.string,
  /**
   * handler for navigating to next trend
   */
  handleNext: PropTypes.func,
  /**
   * handler for closing trend details overlay
   */
  handleClose: PropTypes.func,
};

export default TrendDetails;
