import styled from 'styled-components';
import { Button as ButtonComponent, Headline } from 'components/atoms';
import { black, gray, white, white20OpacityOnBlack } from 'styles/colors';
import { spacer16, spacer24, spacer32, spacer48 } from 'styles/spacers';
import { maxWidth, minWidth } from 'styles/media-queries';
import { animations, fontWeights } from 'styles/variables';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${minWidth.lg`
    height: 90%;
  `}
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 16rem;
  min-height: 20rem;
`;

export const MainCardContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${spacer24};
  transform: translateX(0) translateY(0);
  z-index: 2;
  ${props =>
    !props.theme.isMain &&
    `transition: transform 0.5s ease-in-out, z-index 0.75s ease-in-out;`}

  ${props =>
    props.$isRight &&
    `
    transform: translateX(4rem) translateY(2rem);
    z-index: 1;
  `}

  ${props =>
    props.$isLeft &&
    `
    transform: translateX(-4rem) translateY(2rem);
    z-index: 1;
  `}

  ${minWidth.lg`
    ${props =>
      props.$isRight &&
      `
      transform: translateX(${
        props.theme.isMain ? `30rem` : `8rem`
      }) translateY(2.5rem);
    `}

    ${props =>
      props.$isLeft &&
      `
      transform: translateX(-${
        props.theme.isMain ? `30rem` : `8rem`
      }) translateY(2.5rem);
    `}
  `}
`;

export const Card = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16rem;
  height: 20rem;
  background: center / contain no-repeat url('/culture-next/cardoutline.png');
  background-color: ${black};
  transform: rotate(0);
  ${props => !props.theme.isMain && `transition: transform 0.5s ease-in-out;`}

  ${props =>
    props.$isRight &&
    `
    transform: rotate(10deg);
    ${props.theme.isMain && `margin-left: 5rem;`}
  `}
  ${props =>
    props.$isLeft &&
    `
    transform: rotate(-10deg);
    ${props.theme.isMain && `margin-right: 5rem;`}
  `}

  ${minWidth.lg`
    ${props => props.$isRight && `transform: rotate(10deg);`}
    ${props => props.$isLeft && `transform: rotate(-10deg);`}
  `}
`;

export const SideCardTitle = styled(Headline)`
  position: absolute;
  top: 13rem;
  white-space: nowrap;
`;

export const ImageContainer = styled.div`
  position: relative;
  height: 100%;
  width: 80%;
`;

export const DotsContainer = styled.div`
  margin-top: ${spacer32};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

export const ArrowIcon = styled.button`
  visibility: visible;
  cursor: pointer;
  margin: 0 1rem;
  ${minWidth.lg`
    visibility: hidden;
  `}
`;

export const Dot = styled.div`
  border-radius: 50%;
  background-color: ${props => (props.$isFilled ? white : gray)};
  width: 0.8rem;
  height: 0.8rem;
`;

export const TextContainer = styled.div`
  ${props =>
    props.theme.isMain &&
    minWidth.lg`
    width: 60%;
  `}
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: ${spacer16};
  margin-top: ${spacer16};
`;

export const MainTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  ${minWidth.md`
    width: 70%;
  `}
`;

export const SelectButton = styled(ButtonComponent)`
  &.select-button {
    width: 20rem;
    margin-right: 0;
    margin-top: ${spacer24};
    ${minWidth.lg`
      width: 30rem;
      margin-top: ${spacer48};
    `}
  }
`;

// ADVERTISER TAKEAWAY SECTION
export const TakeawayTitleContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  position: relative;
  justify-content: center;
  margin-top: ${spacer48};
  ${minWidth.lg`
    margin-top: 0;
    height: 10%;
  `}
  h3 {
    display: flex;
    align-items: center;
  }
`;

export const ArrowContainer = styled.div`
  position: relative;
  width: 5rem;
  height: 5rem;
  margin-left: -1rem;
  img {
    transform: rotate(75deg);
  }
  ${minWidth.lg`
    width: 10rem;
    height: 10rem;
  `}
`;

export const TakeawayTextContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const TakeawayText = styled(Headline)`
  padding: ${spacer16} 0;
  text-align: center;
  width: 90%;
  ${minWidth.md`
    width: 70%;
    padding: 0;
    padding-bottom: ${spacer32};
  `}
`;

export const DownloadButton = styled.a`
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: ${fontWeights.bold};
  color: ${black};
  border: 1px solid ${black};
  background-color: ${white};
  border-radius: 2.5rem;
  padding: 1.2rem 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color ${animations.defaultTransition},
    border-color ${animations.defaultTransition},
    color ${animations.defaultTransition};

  &:hover {
    border-color: ${white20OpacityOnBlack};
    background-color: ${white20OpacityOnBlack};
    color: ${white};
  }
`;

// BACKGROUND IMAGES
export const BGImage = styled.div`
  position: absolute;
  height: 15%;
  width: 15%;
  ${maxWidth.sm`
    ${props =>
      props.theme.isMain &&
      `
      visibility: hidden;
    `}
  `}
`;

export const BGImage1 = styled(BGImage)`
  left: -3%;
  top: 7%;
`;

export const BGImage2 = styled(BGImage)`
  left: 5%;
  bottom: -3%;
`;
export const BGImage3 = styled(BGImage)`
  top: 40%;
  right: -5%;
`;
