const COOKIE_SPLIT_KEY = ';';

/**
 * Converts a cookie string into a cookie object.
 * @param {string} cookie A cookie string.
 * @returns {Object} A cookie object.
 */
const getCookieObject = cookie => {
  const cookieObj = cookie.split(COOKIE_SPLIT_KEY).reduce((acc, current) => {
    const [key, value] = current.trim().split('=');

    return {
      ...acc,
      [key]: value,
    };
  }, {});

  if (cookieObj.sp_dc) {
    // Don't expose the cookie value
    cookieObj.sp_dc = true;
  }
  return cookieObj;
};

export default getCookieObject;
