import { css } from 'styled-components';
import { minWidth } from './media-queries';

export const spacer1 = '0.5rem';
export const spacer2 = '1rem';
export const spacer3 = '1.5rem';
export const spacer4 = '4rem';
export const spacer5 = '5rem';
export const spacer6 = '7.5rem';
export const spacer8 = '0.8rem';
export const spacer16 = '1.6rem';
export const spacer24 = '2.4rem';
export const spacer32 = '3.2rem';
export const spacer48 = '4.8rem';
export const spacer64 = '6.4rem';
export const spacer80 = '8rem';
export const spacer120 = '12rem';

/* Section definition */
export const sectionMargins = css`
  padding-top: ${spacer48};
  padding-bottom: ${spacer48};
  ${minWidth.lg`
    padding-top: ${spacer80};
    padding-bottom: ${spacer80};
  `}
`;

/* Article page margin definition */
export const articleMargins = css`
  padding-top: 0;
  padding-bottom: 0;
  margin-top: ${spacer48};
  margin-bottom: ${spacer48};
  ${minWidth.lg`
    padding-top: 0;
    padding-bottom: 0;
    margin-top: ${spacer80};
    margin-bottom: ${spacer80};
  `}
`;
