import React, { useState, useEffect, useRef, useCallback } from 'react';

import FocusTrap from 'focus-trap-react';
import { ThemeProvider } from 'styled-components';
import { FilterBar } from 'components/molecules';
import { isMLargeToXLarge } from 'styles/media-queries';

import PropTypes, { any } from 'prop-types';
import classNames from 'classnames';
import * as Styled from './DynamicFilter.styled';
import { ACTIVATE_ANIMATION_CLASS } from '../../../styles/animations';
import { useAppContext } from '../../../contexts/app-context';

// This component hold the filter bar component and adds styling so that it resizes on mobile/small screens
export const DynamicFilter = ({
  categories = [],
  onFilterBarChange = () => {},
  totalContentCount,
  filterSectionOpen,
  closeOverlay = () => {},
  filterState = {},
}) => {
  const filterOverlayElement = useRef(null);
  const [resizing, setResizing] = useState(false);
  const [navHidden, setNavHidden] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);
  const [{ isAntiFlickerActive }] = useAppContext();
  useEffect(() => {
    if (!isAntiFlickerActive) {
      setStartAnimation(true);
    }
  }, [isAntiFlickerActive]);

  const closeOnDesktop = () => {
    if (isMLargeToXLarge()) closeOverlay();
  };

  /**
   * Debounce implementation for navigation:
   * Implements a 100ms debounce
   * Sets the resizing property to true while the resize is happening
   * Sets the resizing property to false when the timer ends
   * Calls a closeOnDesktop function when the timer ends
   */
  let resizeTimer = null;

  const timeOutFunction = useCallback(() => {
    closeOnDesktop();
    setResizing(false);
  }, []);

  const debouncedCloseOnDesktop = useCallback(() => {
    setResizing(true);
    clearTimeout(resizeTimer);

    resizeTimer = setTimeout(timeOutFunction, 100);
  }, []);

  // Change open state on resize.
  useEffect(() => {
    window.addEventListener('resize', debouncedCloseOnDesktop);

    return () => {
      window.removeEventListener('resize', debouncedCloseOnDesktop);
    };
  }, []);

  // Set aria-hidden for nav
  useEffect(() => {
    setNavHidden(!(filterSectionOpen || isMLargeToXLarge()));
  }, [filterSectionOpen, resizing]);

  return (
    <ThemeProvider theme={{ open: filterSectionOpen, resizing }}>
      <FocusTrap
        active={filterSectionOpen}
        focusTrapOptions={{
          onDeactivate: closeOverlay, // This function is being triggered on ESC key press.
          allowOutsideClick: () => true,
        }}
      >
        <Styled.Container>
          <Styled.ExpandedFilterBar
            className={classNames({
              [ACTIVATE_ANIMATION_CLASS]: startAnimation,
            })}
          >
            <Styled.Nav aria-hidden={navHidden}>
              <Styled.FilterElement ref={filterOverlayElement}>
                <FilterBar
                  initialFilterState={filterState}
                  closeOverlay={closeOverlay}
                  onFilterBarChange={onFilterBarChange}
                  categories={categories}
                  totalContentCount={totalContentCount}
                />
              </Styled.FilterElement>
            </Styled.Nav>
          </Styled.ExpandedFilterBar>
        </Styled.Container>
      </FocusTrap>
    </ThemeProvider>
  );
};

DynamicFilter.propTypes = {
  /**
   * list of categories
   */
  categories: PropTypes.arrayOf(any),
  /**
   * function to use for when dropdown value changes
   */
  onFilterBarChange: PropTypes.func,
  /**
   * total content count
   */
  totalContentCount: PropTypes.number,
  /**
   * if the filter bar is open or not
   */
  filterSectionOpen: PropTypes.bool,
  /**
   * filter state for initial filters
   */
  filterState: PropTypes.objectOf(any),
};
