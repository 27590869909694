/**
 *  typename modules
 */

export const MODULES = {
  RICH_TEXT: 'ModuleRichText',
  IMAGE: 'ModuleImage',
  ACCORDION: 'ModuleAccordion',
  AUDIO: 'ModuleAudio',
  VIDEO: 'ModuleVideo',
  QUOTE: 'ModuleQuote',
  STATS: 'ModuleStats',
  STATEMENT: 'ModuleStatement',
  CATALOG: 'ModuleCatalog',
  CURATED_LIST: 'ModuleCuratedList',
  SPOTIFY_PLAYER: 'ModuleSpotifyPlayer',
  MARKDOWN_TEXT: 'ModuleMarkdownText',
  FEATURE_CARD_CONTAINER: 'ModuleFeatureCardContainer',
  FEATURE_CARD: 'ModuleFeatureCard',
  CHROME_FEATURE_CARD: 'ModuleChromeFeatureCard',
  VIDEO_FEATURE_CARD: 'ModuleVideoFeatureCard',
  TOPIC_CAROUSEL: 'ModuleTopicCarousel',
  TOPIC: 'ModuleTopic',
  INLINE_FORM: 'ModuleInlineForm',
  JUMP_LINK_GROUP: 'ModuleJumpLinkGroup',
  CURATED_LIST_GROUP: 'ModuleCuratedListGroup',
  DYNAMIC_TEXT_INTRO: 'ModuleDynamicTextIntro',
  DYNAMIC_SECTION: 'ModuleDynamicSection',
  INLINE_CARD: 'ModuleInlineCard',
  AD_EXPERIENCES: 'ModuleAdExperiences',
  TAGGED_CONTENT_GROUP: 'ModuleTaggedContentGroup',
  TABBED_CURATED_LIST: 'ModuleTabbedCuratedList',
  TREND_GRID: 'ModuleTrendGrid',
  SLIDER_TAB: 'ModuleSliderTab',
  STAT_SLIDESHOW: 'ModuleStatSlideshow',
  SPLIT_VIEW: 'ModuleSplitView',
  TILE_CAROUSEL: 'ModuleTileCarousel',
  LIST: 'ModuleList',
  FOOTNOTES: 'ModuleFootnotes',
  GALLERY: 'ModuleGallery',
  SECTION_DIVIDER: 'ModuleSectionDivider',
  CTA_BANNER: 'CtaBanner',
};

export const FULL_BLEED_COMPONENTS = [
  MODULES.QUOTE,
  MODULES.FEATURE_CARD,
  MODULES.TOPIC_CAROUSEL,
  MODULES.TOPIC,
  MODULES.VIDEO,
  MODULES.CATALOG,
  MODULES.CURATED_LIST_GROUP,
  MODULES.STATEMENT,
  MODULES.STATS,
  MODULES.INLINE_CARD,
];
