export const TYPES = {
  trackEvent: 'trackEvent',
  pageView: 'pageviewCustomEvent',
  videoEvent: 'trackVideo',
  optimizeActivate: 'optimize.activate',
};

export const CATEGORY = {
  footer: 'footer',
  links: 'links',
  audio: 'audio',
  audioWithTranscript: 'audio with transcript',
  pageBody: 'page body',
  forms: 'forms',
  cookie: 'cookie policy',
  video: 'video',
  social: 'social',
  navigation: 'navigation',
  authorization: 'authorization',
  topicScoll: 'topic scroll',
  autoScroll: 'auto scroll',
};

export const MODULES_MULTI_CTA = {
  keyPoints: 'key points',
  featureCard: 'feature card',
  heroDynamic: 'hero',
  navigationBar: 'navigation bar',
  lowerNavigationBar: 'lower navigation bar',
  dropdown: 'dropdown',
};

export const ACTIONS_MULTI_CTA = {
  internalUrl: 'clicked internal URL',
  externalUrl: 'clicked external URL',
  adStudio: 'started AdStudio signup',
  openModalForm: 'opened form',
  closeModalForm: 'closed form',
};
