import styled from 'styled-components';

import { animations } from 'styles/variables';
import { minWidth } from 'styles/media-queries';
import { darkGray, white } from 'styles/colors';

// Constants
const DROPDOWN_MENU_WIDTH = 24; // value in rem

// Styled components
export const DropdownMenu = styled.div`
  font-size: 1.4rem;
  line-height: 2.4rem;

  ${minWidth.md`
    font-size: 1.6rem;
  `}

  ${minWidth.lg`
    background-color: ${darkGray};
    color: ${white};
    border-radius: 1rem;
    position: absolute;
    right: calc(50% - ${DROPDOWN_MENU_WIDTH / 2}rem);
    transition: opacity ${animations.defaultTransition};
    width: ${DROPDOWN_MENU_WIDTH}rem;
  `}
`;

export const Container = styled.div`
  display: inline-block;
  position: relative;
`;
