import React from 'react';

import get from 'lodash/get';
import { black } from 'styles/colors';

import * as Styled from './CuratedListGroup.styled';

interface CuratedListGroupPropsType {
  items: any[];
}

/**
 * CuratedListGroup Component
 * @param {Array} items - The list of items to be rendered
 * @returns {ReactElement}
 */
export const CuratedListGroup = ({ items = [] }: CuratedListGroupPropsType) => {
  return (
    <Styled.Container>
      {items.map((data, index) => (
        <Styled.CuratedList
          key={get(data, 'sys.id', `cl-${index}`)}
          data={data?.pagesCollection?.items}
          title={data?.title}
          backgroundColor={black}
        />
      ))}
    </Styled.Container>
  );
};
