import styled, { css } from 'styled-components';
import { animations, fontWeights } from 'styles/variables';
import { minWidth } from 'styles/media-queries';
import {
  gray20,
  gray30,
  gray50,
  gray85,
  gray90,
  solidBlack0,
  solidWhite200,
} from '@spotify-internal/encore-web';
import {
  black,
  darkGray,
  gray,
  white,
  white20OpacityOnBlack,
} from 'styles/colors';

// Basic states
const cta = css`
  border-radius: 5rem;
  border-style: solid;
  border-width: 0.1rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-size: 1.4rem;
  height: 4rem;
  line-height: 1.54rem;
  padding: 1.2rem 2.4rem;
  font-weight: ${fontWeights.extra_bold};
  text-decoration: none;
  svg {
    width: 1.4rem;
    height: 1.4rem;
    margin-left: 0.5rem;
  }
  path {
    transition: fill ${animations.defaultTransition};
  }
  transition: background-color ${animations.defaultTransition},
    border-color ${animations.defaultTransition},
    color ${animations.defaultTransition};

  ${props => (props.minWidth ? `min-width: ${props.minWidth}rem;` : '')}
  ${minWidth.lg`
    font-size: 1.6rem;
    line-height: 1.76rem;
  `}
`;

const ctaActiveState = css`
  border-color: ${white20OpacityOnBlack};
  background-color: ${white20OpacityOnBlack};
  color: ${solidWhite200};
  path {
    fill: ${solidWhite200};
  }
`;

export const ctaHoverState = css`
  background-color: ${white20OpacityOnBlack};
  border-color: ${white20OpacityOnBlack};
  color: ${solidWhite200};
  path {
    fill: ${solidWhite200};
  }
`;

export const textLinkHoverState = css`
  color: ${white20OpacityOnBlack};
  text-decoration: none;
`;

// Styled Components
// Primary Cta gets the inverse treatment for dark theme
export const PrimaryCta = styled.a`
  ${cta}
  background-color: ${({ isDarkTheme }) =>
    isDarkTheme ? white : white20OpacityOnBlack};
  border-color: ${({ isDarkTheme }) =>
    isDarkTheme ? white : white20OpacityOnBlack};
  color: ${({ isDarkTheme }) => (isDarkTheme ? black : white)};
  path {
    fill: ${black};
  }

  &:active {
    ${ctaActiveState}
  }

  &:hover {
    ${ctaHoverState}
  }

  &[disabled],
  &[aria-disabled='true'] {
    background-color: ${({ isDarkTheme }) => (isDarkTheme ? gray : gray90)};
    border-color: ${({ isDarkTheme }) => (isDarkTheme ? gray : gray90)};
    color: ${({ isDarkTheme }) => (isDarkTheme ? darkGray : gray30)};
    pointer-events: none;
  }
`;

export const SecondaryCta = styled.a`
  ${cta}

  background-color: ${({ isDarkTheme }) =>
    isDarkTheme ? `transparent` : black};
  border-color: ${white};
  color: ${white};

  &:active {
    ${ctaActiveState}
  }

  &:hover {
    ${ctaHoverState}
  }

  &[disabled],
  &[aria-disabled='true'] {
    background-color: ${solidWhite200};
    border-color: ${gray50};
    color: ${gray50};
    pointer-events: none;
  }
`;

export const Tertiary = styled.a`
  ${cta}

  background-color: transparent;
  border-color: ${props => (props.isDarkTheme ? solidWhite200 : solidBlack0)};
  color: ${props => (props.isDarkTheme ? solidWhite200 : solidBlack0)};

  &:active {
    background-color: ${props =>
      props.isDarkTheme ? solidWhite200 : solidBlack0};
    border-color: ${props => (props.isDarkTheme ? solidWhite200 : solidBlack0)};
    color: ${props => (props.isDarkTheme ? gray20 : gray85)};
  }

  &:hover {
    background-color: ${props =>
      props.isDarkTheme ? solidWhite200 : solidBlack0};
    border-color: ${props => (props.isDarkTheme ? solidWhite200 : solidBlack0)};
    color: ${props => (props.isDarkTheme ? solidBlack0 : solidWhite200)};
  }

  &[disabled],
  &[aria-disabled='true'] {
    background-color: transparent;
    border-color: ${props => (props.isDarkTheme ? solidWhite200 : solidBlack0)};
    color: ${props => (props.isDarkTheme ? solidWhite200 : solidBlack0)};
    opacity: 0.7;
  }
`;

export const TextLink = styled.a`
  color: inherit;
  transition: color ${animations.defaultTransition},
    text-decoration ${animations.defaultTransition};
  text-decoration: underline;

  &:hover {
    ${textLinkHoverState}
  }

  &:active {
    color: ${white20OpacityOnBlack};
    text-decoration: none;
  }

  &[disabled],
  &[aria-disabled='true'] {
    pointer-events: none;
  }
`;

export const WrapperLink = styled.a`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

export const multiple = css`
  margin-bottom: 1.6rem;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  vertical-align: top;
  white-space: nowrap;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  ${minWidth.md`
    margin-right: 1.6rem;
    max-width: 40.8rem;
    width: auto;
  `}
`;
