import PropTypes from 'prop-types';

// In TS files, use foregroundImageType instead.
export const Image = PropTypes.shape({
  url: PropTypes.string,
  webpUrl: PropTypes.string,
  optimizedUrl: PropTypes.string,
  description: PropTypes.string,
});

export const Cta = PropTypes.shape({
  url: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  overrideFunctionality: PropTypes.string,
});

export interface ForegroundImageType {
  url: string;
  webpUrl: string;
  optimizedUrl: string;
  description: string;
}
