import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Paragraph } from 'components/atoms';
import Image from 'next/image';
import * as Styled from './FlipCard.styled';

/**
 * Renders the card stack for the ad analyzer
 * @param {String} imageSrc
 * @param {String} descriptionText
 * @param {String} flipText
 * @param {String} cardBackSrc
 * @returns {ReactElement}
 */
export const FlipCard = ({
  imageSrc = '',
  descriptionText = '',
  flipText = '',
  cardBackSrc = '',
}) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleCardClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <Styled.Container>
      <Styled.CardContainer onClick={handleCardClick}>
        <Styled.FlipCard $isFlipped={isFlipped}>
          <Styled.CardFront>
            <Styled.BorderImageContainer />
            <Styled.ImageContainer>
              <Image
                src={imageSrc}
                layout="fill"
                objectFit="contain"
                unoptimized
              />
            </Styled.ImageContainer>
          </Styled.CardFront>
          <Styled.CardBack>
            <Styled.BorderImageContainer />
            <Styled.BackText
              styling="p1_bold"
              text={descriptionText}
              className="back-text"
            />
            <Styled.BackImageContainer>
              <Image
                src={cardBackSrc}
                layout="fill"
                objectFit="contain"
                unoptimized
              />
            </Styled.BackImageContainer>
          </Styled.CardBack>
        </Styled.FlipCard>
      </Styled.CardContainer>
      <Styled.FlipTextButton onClick={handleCardClick}>
        <Paragraph styling="P1_CAPTION" text={flipText} />
      </Styled.FlipTextButton>
    </Styled.Container>
  );
};

FlipCard.propTypes = {
  /**
   * image
   */
  imageSrc: PropTypes.string,
  /**
   * description text
   */
  descriptionText: PropTypes.string,
  /**
   * translated tap to flip text
   */
  flipText: PropTypes.string,
  /**
   * back card image
   */
  cardBackSrc: PropTypes.string,
};
