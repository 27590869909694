import styled from 'styled-components';

import {
  Cta as CtaComponent,
  Headline,
  Button,
  Paragraph,
} from 'components/atoms';
import { container, columnsGutter } from 'styles/grid';
import { minWidth } from 'styles/media-queries';
import { ICONS } from 'constants/icons';
import { animations, cssFragments } from 'styles/variables';
import { spacer24 } from 'styles/spacers';
import { black, white, white20OpacityOnBlack } from 'styles/colors';

export const Root = styled.div`
  margin: 1.6rem auto;
  ${({ theme }) => !theme.isFullWidthTheme && container}

  ${minWidth.lg`
    ${({ theme }) =>
      theme.isFullWidthTheme
        ? `
        position: relative;
        width: 100%;
        height: 100%;
      `
        : `
      ${columnsGutter}
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      margin: 4rem auto;
    `}
  `}
`;

export const Container = styled.div`
  ${minWidth.lg`
    ${({ theme }) =>
      theme.isFullWidthTheme
        ? `
        width: 100%;
      `
        : `
      grid-column: 4 / span 6;
    `}
  `}
`;

export const Entries = styled.div`
  width: 100%;
`;

export const Entry = styled.div`
  border-top: 1px solid ${white20OpacityOnBlack};
  padding: ${spacer24} 0;
`;

export const OpenButton = styled.button`
  position: relative;
  text-align: left;
  width: 100%;
  ${({ theme }) => theme.isDark && `color: ${white};`}

  ${cssFragments.defaultFocusState}
`;

export const ButtonAll = styled.button`
  font-size: 14px;
  height: 4rem;
  line-height: 24px;
  text-align: right;
  width: 100%;
  ${({ theme }) => theme.isDark && `color: ${white};`}

  ${minWidth.lg`
    font-size: 16px;
    line-height: 24px;
  `}

  ${cssFragments.defaultFocusState}
`;

export const Title = styled(Headline).attrs({
  tag: 'h3',
  styling: 'h3',
})`
  padding-right: 2rem;

  &:after {
    background-color: ${({ theme }) => (theme.isDark ? white : black)};

    mask-image: url(/svg/${({ active }) =>
        active ? ICONS.MINUS : ICONS.PLUS}.svg);
    mask-size: ${({ active }) => (active ? '0.2' : '2')}rem;
    content: '';
    height: ${({ active }) => (active ? '0.2' : '2')}rem;
    position: absolute;
    right: 0;
    top: calc(50% - ${({ active }) => (active ? '0.1' : '0.6')}rem);
    width: 2rem;
  }
`;

export const Description = styled(Paragraph)`
  box-sizing: content-box;
  margin-bottom: ${({ active }) => (active ? '0.8rem' : 0)};
  max-height: ${({ active, maxHeight }) => (active ? `${maxHeight}px` : 0)};
  opacity: ${({ active }) => (active ? 1 : 0)};
  overflow: hidden;
  padding-top: ${({ active }) => (active ? '0.8rem' : 0)};
  padding-right: 3.6rem;
  pointer-events: all;
  transition: all ${animations.defaultTransition};

  ${minWidth.lg`
    margin-bottom: ${({ active }) => (active ? '1.6rem' : 0)};
    padding-top: ${({ active }) => (active ? '1.6rem' : 0)};
  `}
`;

export const ButtonCta = styled(Button)`
  &.button-arrow {
    background-color: ${white};
    position: absolute;
    margin-left: -2rem;

    svg {
      margin-left: -0.1rem;
    }
    path {
      fill: ${black};
    }
    transition: background-color ${animations.defaultTransition};

    path {
      transition: color ${animations.defaultTransition};
    }
    &:hover {
      background-color: ${white20OpacityOnBlack};
      path {
        fill: ${white};
      }
    }
  }
`;

export const Cta = styled(CtaComponent)`
  border: none;
  background-color: inherit;
  border-radius: 75%;
  height: 2rem;
  position: relative;

  &:hover {
    background-color: inherit;
  }
`;
