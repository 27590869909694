import styled from 'styled-components';
import { minWidth } from 'styles/media-queries';
import { fontWeights } from 'styles/variables';

const Eyebrow = styled.span`
  display: inline-block;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 110%;
  font-weight: ${fontWeights.extra_bold};
  ${minWidth.lg`
    font-size: 1.6rem;
  `}
`;

export default Eyebrow;
