import React from 'react';
import PropTypes, { any } from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { ICONS } from 'constants/icons';
import { STAT_CARD_THEMES, getStatCardTheme } from 'utils/get-theme-selector';
import { Headline, Paragraph } from 'components/atoms';
import * as Styled from './StatCard.styled';

/**
 * Stat Card Component
 * @param {String} title - title to be displayed on card
 * @param {String} subtitle - subtitle text to accompany the title
 * @param {String} captionTitle - title text for the description of the text
 * @param {String} caption - text to add for the description of the stat
 * @param {String} textColor - hexcode to decide the text color of the card
 * @param {String} backgroundColor - hexcode to decide the background color of the card
 * @param {Object} image - Component image, object with url and description of the image
 * @param {String} themeSelector - theme selector of component
 * @param {String} className - to define the stat card to be used for styling
 * @returns {React.JSX.Element}
 */
const StatCard = ({
  title,
  subtitle,
  captionTitle,
  caption,
  textColor,
  backgroundColor,
  image = null,
  themeSelector,
  className,
}) => {
  const themeType = getStatCardTheme(themeSelector?.toLowerCase());

  const getStatCardContent = () => {
    switch (themeType) {
      case STAT_CARD_THEMES.HOMEPAGE_CAROUSEL:
        return (
          <Styled.StatCard className={className}>
            <Styled.TitleContainer>
              <Styled.Logo name={ICONS.SPOTIFY_LOGO} />
              <Headline tag="h3" styling="h2" text={`${title}`} />
              <Headline tag="h3" styling="h3" text={subtitle} />
            </Styled.TitleContainer>
            <Styled.Caption styling="P1_CAPTION">{caption}</Styled.Caption>
          </Styled.StatCard>
        );
      case STAT_CARD_THEMES.TEXT_WITH_BOLD_STAT:
        return (
          <Styled.StatCard className={className} url={image?.url}>
            <Styled.TitleContainer>
              <Headline
                tag="h3"
                styling="stat"
                className="bold-stat"
                text={title}
              />
              <Paragraph styling="P1_CAPTION">{subtitle}</Paragraph>
            </Styled.TitleContainer>
            <Styled.CaptionContainer>
              {captionTitle && (
                <Styled.Caption styling="P1_CAPTION">
                  <strong>{captionTitle}</strong>
                </Styled.Caption>
              )}
              {caption && (
                <Styled.Caption styling="P1_CAPTION">{caption}</Styled.Caption>
              )}
            </Styled.CaptionContainer>
          </Styled.StatCard>
        );
      case STAT_CARD_THEMES.TEXT_ONLY:
        return (
          <Styled.StatCard className={className}>
            <Styled.TitleContainer>
              {captionTitle && (
                <Headline tag="h3" styling="h3" text={captionTitle} />
              )}
            </Styled.TitleContainer>
          </Styled.StatCard>
        );
      case STAT_CARD_THEMES.IMAGE:
        return (
          <Styled.StatCard className={className} url={image?.url}>
            {(caption || captionTitle) && (
              <Styled.CaptionContainer>
                <Styled.CaptionTitle
                  tag="h4"
                  styling="h4"
                  text={`__${captionTitle}__`}
                />
                <Styled.Caption styling="P1_CAPTION">{caption}</Styled.Caption>
              </Styled.CaptionContainer>
            )}
          </Styled.StatCard>
        );
      default:
        return null;
    }
  };

  return (
    <ThemeProvider
      theme={{
        backgroundColor,
        textColor,
        themeType,
        hasImage: image,
      }}
    >
      {getStatCardContent()}
    </ThemeProvider>
  );
};

StatCard.propTypes = {
  /**
   * title of the stat card
   */
  title: PropTypes.string,
  /**
   * subtitle of the state card
   */
  subtitle: PropTypes.string,
  /**
   * caption title for the bottom of the stat card
   */
  captionTitle: PropTypes.string,
  /**
   * caption details for the bottom of the stat card
   */
  caption: PropTypes.string,
  /**
   * background color of the stat card
   */
  backgroundColor: PropTypes.string,
  /**
   * text color of the stat card
   */
  textColor: PropTypes.string,
  /**
   * image for image type of the stat card
   */
  image: PropTypes.shape(any),
  /**
   * themeSelector of the stat card
   */
  themeSelector: PropTypes.string,
  /**
   * classname to select styles
   */
  className: PropTypes.string,
};

export default StatCard;
