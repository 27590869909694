/* eslint-disable global-require */
export const allCountries = [
  {
    value: 'Andorra',
    text: 'Andorra',
    code2: 'AD',
    code3: 'AND',
    flag: require('assets/country-flags/ad.svg').default,
    languageCode: 'ca',
    localeCode: 'ad',
    enabled: false,
  },
  {
    value: 'Argentina',
    text: 'Argentina',
    code2: 'AR',
    code3: 'ARG',
    flag: require('assets/country-flags/ar.svg').default,
    languageCode: 'es',
    localeCode: 'es-AR',
    enabled: false,
  },
  {
    value: 'Australia',
    text: 'Australia',
    code2: 'AU',
    code3: 'AUS',
    flag: require('assets/country-flags/au.svg').default,
    languageCode: 'en',
    localeCode: 'en-AU',
    enabled: true,
  },
  {
    value: 'Belgium',
    text: 'Belgium (Francais)',
    code2: 'BE',
    code3: 'BEL',
    flag: require('assets/country-flags/be.svg').default,
    languageCode: 'nl',
    localeCode: 'fr-BE',
    enabled: false,
  },
  {
    value: 'Belgium',
    text: 'België (English)',
    code2: 'BE',
    code3: 'BEL',
    flag: require('assets/country-flags/be.svg').default,
    languageCode: 'nl',
    localeCode: 'nl-BE',
    enabled: false,
  },
  {
    value: 'Brazil',
    text: 'Brasil',
    code2: 'BR',
    code3: 'BRA',
    flag: require('assets/country-flags/br.svg').default,
    languageCode: 'pt',
    localeCode: 'pt-BR',
    enabled: true,
  },
  {
    value: 'Canada',
    text: 'Canada (English)',
    code2: 'CA',
    code3: 'CAN',
    flag: require('assets/country-flags/ca.svg').default,
    languageCode: 'en',
    localeCode: 'en-CA',
    enabled: true,
  },
  {
    value: 'Chile',
    text: 'Chile',
    code2: 'CL',
    code3: 'CHL',
    flag: require('assets/country-flags/cl.svg').default,
    languageCode: 'es',
    localeCode: 'es-CL',
    enabled: false,
  },
  {
    value: 'Colombia',
    text: 'Colombia',
    code2: 'CO',
    code3: 'COL',
    flag: require('assets/country-flags/co.svg').default,
    languageCode: 'es',
    localeCode: 'es-CO',
    enabled: false,
  },
  {
    value: 'Denmark',
    text: 'Denmark',
    code2: 'DK',
    code3: 'DNK',
    flag: require('assets/country-flags/dk.svg').default,
    languageCode: 'da',
    localeCode: 'da-DK',
    enabled: true,
  },
  {
    value: 'Germany',
    text: 'Deutschland',
    code2: 'DE',
    code3: 'DEU',
    flag: require('assets/country-flags/de.svg').default,
    languageCode: 'de',
    localeCode: 'de-DE',
    enabled: true,
  },
  {
    value: 'Spain',
    text: 'España',
    code2: 'ES',
    code3: 'ESP',
    flag: require('assets/country-flags/es.svg').default,
    languageCode: 'es',
    localeCode: 'es',
    enabled: true,
  },
  {
    value: 'Estonia',
    text: 'Estonia',
    code2: 'EE',
    code3: 'EST',
    flag: require('assets/country-flags/ee.svg').default,
    languageCode: 'et',
    localeCode: 'et',
    enabled: false,
  },
  {
    value: 'Finland',
    text: 'Finland',
    code2: 'FI',
    code3: 'FIN',
    flag: require('assets/country-flags/fi.svg').default,
    languageCode: 'sv',
    localeCode: 'fi',
    enabled: true,
  },
  {
    value: 'France',
    text: 'France',
    code2: 'FR',
    code3: 'FRA',
    flag: require('assets/country-flags/fr.svg').default,
    languageCode: 'fr',
    localeCode: 'fr',
    enabled: true,
  },
  {
    value: 'Hong Kong',
    text: 'Hong Kong',
    code2: 'HK',
    code3: 'HKG',
    flag: require('assets/country-flags/hk.svg').default,
    languageCode: 'zh',
    localeCode: 'zh-Hant-HK',
    enabled: true,
  },
  {
    value: 'India',
    text: 'India',
    code2: 'IN',
    code3: 'IND',
    flag: require('assets/country-flags/in.svg').default,
    languageCode: 'in',
    localeCode: 'en-IN',
    enabled: true,
  },
  {
    value: 'Indonesia',
    text: 'Indonesia',
    code2: 'ID',
    code3: 'IDN',
    flag: require('assets/country-flags/id.svg').default,
    languageCode: 'id',
    localeCode: 'id-ID',
    enabled: true,
  },
  {
    value: 'Ireland',
    text: 'Ireland',
    code2: 'IE',
    code3: 'IRL',
    flag: require('assets/country-flags/ie.svg').default,
    languageCode: 'en',
    localeCode: 'en-IE',
    enabled: false,
  },
  {
    value: 'Italy',
    text: 'Italia',
    code2: 'IT',
    code3: 'ITA',
    flag: require('assets/country-flags/it.svg').default,
    languageCode: 'it',
    localeCode: 'it',
    enabled: true,
  },
  {
    value: 'Malaysia',
    text: 'Malaysia',
    code2: 'MY',
    code3: 'MYS',
    flag: require('assets/country-flags/my.svg').default,
    languageCode: 'ms',
    localeCode: 'ms-MY',
    enabled: true,
  },
  {
    value: 'Mexico',
    text: 'México',
    code2: 'MX',
    code3: 'MEX',
    flag: require('assets/country-flags/mx.svg').default,
    languageCode: 'es',
    localeCode: 'es-MX',
    enabled: true,
  },
  {
    value: 'Netherlands',
    text: 'Nederland',
    code2: 'NL',
    code3: 'NLD',
    flag: require('assets/country-flags/nl.svg').default,
    languageCode: 'nl',
    localeCode: 'nl-NL',
    enabled: true,
  },
  {
    value: 'New Zealand',
    text: 'New Zealand',
    code2: 'NZ',
    code3: 'NZL',
    flag: require('assets/country-flags/nz.svg').default,
    languageCode: 'en',
    localeCode: 'en-NZ',
    enabled: false,
  },
  {
    value: 'Norway',
    text: 'Norway',
    code2: 'NO',
    code3: 'NOR',
    flag: require('assets/country-flags/no.svg').default,
    languageCode: 'nb',
    localeCode: 'nn-NO',
    enabled: true,
  },
  {
    value: 'Peru',
    text: 'Perú',
    code2: 'PE',
    code3: 'PER',
    flag: require('assets/country-flags/pe.svg').default,
    languageCode: 'es',
    localeCode: 'es-PE',
    enabled: false,
  },
  {
    value: 'Philippines',
    text: 'Philippines',
    code2: 'PH',
    code3: 'PHL',
    flag: require('assets/country-flags/ph.svg').default,
    languageCode: 'fil',
    localeCode: 'fil-PH',
    enabled: true,
  },
  {
    value: 'Poland',
    text: 'Polska',
    code2: 'PL',
    code3: 'POL',
    flag: require('assets/country-flags/pl.svg').default,
    languageCode: 'pl',
    localeCode: 'pl-PL',
    enabled: false,
  },
  {
    value: 'Portugal',
    text: 'Portugal',
    code2: 'PT',
    code3: 'PRT',
    flag: require('assets/country-flags/pt.svg').default,
    languageCode: 'pt',
    localeCode: 'pt',
    enabled: false,
  },
  {
    value: 'Switzerland',
    text: 'Schweiz (Deutsch)',
    code2: 'CH',
    code3: 'CHE',
    flag: require('assets/country-flags/ch.svg').default,
    languageCode: 'de',
    localeCode: 'de-CH',
    enabled: false,
  },
  {
    value: 'Singapore',
    text: 'Singapore',
    code2: 'SG',
    code3: 'SGP',
    flag: require('assets/country-flags/sg.svg').default,
    languageCode: 'en',
    localeCode: 'en-SG',
    enabled: true,
  },
  {
    value: 'Switzerland',
    text: 'Suisse (français)',
    code2: 'CH',
    code3: 'CHE',
    flag: require('assets/country-flags/ch.svg').default,
    languageCode: 'fr',
    localeCode: 'fr-CH',
    enabled: false,
  },
  {
    value: 'Sweden',
    text: 'Sverige',
    code2: 'SE',
    code3: 'SWE',
    flag: require('assets/country-flags/se.svg').default,
    languageCode: 'sv',
    localeCode: 'sv-SE',
    enabled: true,
  },
  {
    value: 'Thailand',
    text: 'Thailand',
    code2: 'TH',
    code3: 'THA',
    flag: require('assets/country-flags/th.svg').default,
    languageCode: 'th',
    localeCode: 'th-TH',
    enabled: true,
  },
  {
    value: 'Cyprus',
    text: 'Türkiye',
    code2: 'CY',
    code3: 'CYP',
    flag: require('assets/country-flags/tr.svg').default,
    languageCode: 'tr',
    localeCode: 'tr',
    enabled: false,
  },
  {
    value: 'United States',
    text: 'USA',
    code2: 'US',
    code3: 'USA',
    flag: require('assets/country-flags/us.svg').default,
    languageCode: 'en',
    localeCode: 'en-US',
    enabled: true,
  },
  {
    value: 'United Kingdom',
    text: 'United Kingdom',
    code2: 'GB',
    code3: 'GBR',
    flag: require('assets/country-flags/gb.svg').default,
    languageCode: 'en',
    localeCode: 'en-GB',
    enabled: true,
  },
  {
    value: 'Uruguay',
    text: 'Uruguay',
    code2: 'UY',
    code3: 'URY',
    flag: require('assets/country-flags/uy.svg').default,
    languageCode: 'es',
    localeCode: 'es-UY',
    enabled: false,
  },
  {
    value: 'Austria',
    text: 'Österreich',
    code2: 'AT',
    code3: 'AUT',
    flag: require('assets/country-flags/at.svg').default,
    languageCode: 'de',
    localeCode: 'de-AT',
    enabled: false,
  },
  {
    value: 'Greece',
    text: 'Ελλάδα',
    code2: 'GR',
    code3: 'GRC',
    flag: require('assets/country-flags/gr.svg').default,
    languageCode: 'el',
    localeCode: 'el',
    enabled: false,
  },
  {
    value: 'Taiwan',
    text: '台灣',
    code2: 'TW',
    code3: 'TWN',
    flag: require('assets/country-flags/tw.svg').default,
    languageCode: 'zh',
    localeCode: 'zh-Hant-TW',
    enabled: true,
  },
  {
    value: 'Japan',
    text: '日本',
    code2: 'JP',
    code3: 'JPN',
    flag: require('assets/country-flags/jp.svg').default,
    languageCode: 'ja',
    localeCode: 'ja',
    enabled: true,
  },
  {
    value: 'Vietnam',
    text: 'Việt Nam',
    code2: 'VN',
    code3: 'VNM',
    flag: require('assets/country-flags/vn.svg').default,
    languageCode: 'vi',
    localeCode: 'vi',
    enabled: true,
  },
  {
    value: 'Israel',
    text: 'ישראל',
    code2: 'IL',
    code3: 'ISR',
    flag: require('assets/country-flags/il.svg').default,
    languageCode: 'he',
    localeCode: 'il',
    enabled: false,
  },
  {
    value: 'United Arab Emirates',
    text: '(الإمارات العربية المتحدة (العربية',
    code2: 'AE',
    code3: 'ARE',
    flag: require('assets/country-flags/ae.svg').default,
    languageCode: 'ar',
    localeCode: 'ar-AE',
    enabled: false,
  },
  {
    value: 'United Arab Emirates',
    text: 'United Arab Emirates (English)',
    code2: 'AE',
    code3: 'ARE',
    flag: require('assets/country-flags/ae.svg').default,
    languageCode: 'ar',
    localeCode: 'en-AE',
    enabled: false,
  },
  {
    value: 'Saudi Arabia',
    text: '(السعودية العربية (العربية',
    code2: 'SA',
    code3: 'SAU',
    flag: require('assets/country-flags/sa.svg').default,
    languageCode: 'ar',
    localeCode: 'ar-SA',
    enabled: false,
  },
  {
    value: 'Saudi Arabia',
    text: 'Saudi Arabia (English)',
    code2: 'SA',
    code3: 'SAU',
    flag: require('assets/country-flags/sa.svg').default,
    languageCode: 'en',
    localeCode: 'en-SA',
    enabled: false,
  },
  {
    value: 'Bahrain',
    text: '(البحرين (العربية',
    code2: 'BH',
    code3: 'BHR',
    flag: require('assets/country-flags/bh.svg').default,
    languageCode: 'ar',
    localeCode: 'ar-BH',
    enabled: false,
  },
  {
    value: 'Bahrain',
    text: 'Bahrain (English)',
    code2: 'BH',
    code3: 'BHR',
    flag: require('assets/country-flags/bh.svg').default,
    languageCode: 'ar',
    localeCode: 'en-BH',
    enabled: false,
  },
  {
    value: 'Oman',
    text: '(عمان (العربية',
    code2: 'OM',
    code3: 'OMN',
    flag: require('assets/country-flags/om.svg').default,
    languageCode: 'ar',
    localeCode: 'ar-OM',
    enabled: false,
  },
  {
    value: 'Oman',
    text: 'Oman (English)',
    code2: 'OM',
    code3: 'OMN',
    flag: require('assets/country-flags/om.svg').default,
    languageCode: 'ar',
    localeCode: 'en-OM',
    enabled: false,
  },
  {
    value: 'Qatar',
    text: '(قطر (العربية',
    code2: 'QA',
    code3: 'QAT',
    flag: require('assets/country-flags/qa.svg').default,
    languageCode: 'ar',
    localeCode: 'ar-QA',
    enabled: false,
  },
  {
    value: 'Qatar',
    text: 'Qatar (English)',
    code2: 'QA',
    code3: 'QAT',
    flag: require('assets/country-flags/qa.svg').default,
    languageCode: 'ar',
    localeCode: 'en-QA',
    enabled: false,
  },
  {
    value: 'Kuwait',
    text: '(الكويت (العربية',
    code2: 'KW',
    code3: 'KWT',
    flag: require('assets/country-flags/kw.svg').default,
    languageCode: 'ar',
    localeCode: 'ar-KW',
    enabled: false,
  },
  {
    value: 'Kuwait',
    text: 'Kuwait (English)',
    code2: 'KW',
    code3: 'KWT',
    flag: require('assets/country-flags/kw.svg').default,
    languageCode: 'en',
    localeCode: 'en-KW',
    enabled: false,
  },
  {
    value: 'Lebanon',
    text: '(لبنان (العربية',
    code2: 'LB',
    code3: 'LBN',
    flag: require('assets/country-flags/lb.svg').default,
    languageCode: 'ar',
    localeCode: 'ar-LB',
    enabled: false,
  },
  {
    value: 'Lebanon',
    text: 'Lebanon (English)',
    code2: 'LB',
    code3: 'LBN',
    flag: require('assets/country-flags/lb.svg').default,
    languageCode: 'en',
    localeCode: 'en-LB',
    enabled: false,
  },
  {
    value: 'Lebanon',
    text: 'Liban (français)',
    code2: 'LB',
    code3: 'LBN',
    flag: require('assets/country-flags/lb.svg').default,
    languageCode: 'fr',
    localeCode: 'fr-LB',
    enabled: false,
  },
  {
    value: 'Jordan',
    text: '(الأردن (العربية',
    code2: 'JO',
    code3: 'JOR',
    flag: require('assets/country-flags/jo.svg').default,
    languageCode: 'ar',
    localeCode: 'ar-JO',
    enabled: false,
  },
  {
    value: 'Jordan',
    text: 'Jordan (English)',
    code2: 'JO',
    code3: 'JOR',
    flag: require('assets/country-flags/jo.svg').default,
    languageCode: 'en',
    localeCode: 'en-JO',
    enabled: false,
  },
  {
    value: 'Palestine',
    text: '(فلسطين (العربية',
    code2: 'PS',
    code3: 'PSE',
    flag: require('assets/country-flags/ps.svg').default,
    languageCode: 'ar',
    localeCode: 'ar-PS',
    enabled: false,
  },
  {
    value: 'Palestine',
    text: 'Palestine (English)',
    code2: 'PS',
    code3: 'PSE',
    flag: require('assets/country-flags/ps.svg').default,
    languageCode: 'ar',
    localeCode: 'en-PS',
    enabled: false,
  },
  {
    value: 'Morocco',
    text: '(المغرب (العربية',
    code2: 'MA',
    code3: 'MAR',
    flag: require('assets/country-flags/ma.svg').default,
    languageCode: 'ar',
    localeCode: 'ar-MA',
    enabled: false,
  },
  {
    value: 'Morocco',
    text: 'Maroc (français)',
    code2: 'MA',
    code3: 'MAR',
    flag: require('assets/country-flags/ma.svg').default,
    languageCode: 'fr',
    localeCode: 'fr-MA',
    enabled: false,
  },
  {
    value: 'Egypt',
    text: '(مصر(العربية',
    code2: 'EG',
    code3: 'EGY',
    flag: require('assets/country-flags/eg.svg').default,
    languageCode: 'ar',
    localeCode: 'ar-EG',
    enabled: false,
  },
  {
    value: 'Egypt',
    text: 'Egypt (English)',
    code2: 'EG',
    code3: 'EGY',
    flag: require('assets/country-flags/eg.svg').default,
    languageCode: 'ar',
    localeCode: 'en-EG',
    enabled: false,
  },
  {
    value: 'Tunisia',
    text: '(تونس (العربية',
    code2: 'TN',
    code3: 'TUN',
    flag: require('assets/country-flags/tn.svg').default,
    languageCode: 'ar',
    localeCode: 'ar-TN',
    enabled: false,
  },
  {
    value: 'Tunisia',
    text: 'Tunisie (français)',
    code2: 'TN',
    code3: 'TUN',
    flag: require('assets/country-flags/tn.svg').default,
    languageCode: 'fr',
    localeCode: 'fr-TN',
    enabled: false,
  },
  {
    value: 'Algeria',
    text: '(الجزائر (العربية',
    code2: 'DZ',
    code3: 'DZA',
    flag: require('assets/country-flags/dz.svg').default,
    languageCode: 'ar',
    localeCode: 'ar-DZ',
    enabled: false,
  },
  {
    value: 'Algeria',
    text: 'Algérie (français)',
    code2: 'DZ',
    code3: 'DZA',
    flag: require('assets/country-flags/dz.svg').default,
    languageCode: 'ar',
    localeCode: 'fr-DZ',
    enabled: false,
  },
];

export const countries = allCountries.filter(country => country.enabled);
