import React from 'react';
import PropTypes, { any } from 'prop-types';
import { kebabCase } from 'lodash';
import { ThemeProvider } from 'styled-components';
import { CTA_CARD_THEMES, getCTACardTheme } from 'utils/get-theme-selector';
import { Headline, Paragraph } from 'components/atoms';
import { getLinkProps } from 'utils/get-link-props';
import * as Styled from './CtaCard.styled';

/**
 * CTA Card component
 * @param {String} title title of cta card
 * @param {String} description description of cta card
 * @param {Object} image optional image
 * @param {Array} ctas list of CTAs
 * @param {String} themeSelector theme selector
 * @param {String} className to define the CTA card to be used for styling
 * @returns {ReactElement}
 */
const CtaCard = ({
  title = '',
  description = '',
  image = null,
  ctas = [],
  themeSelector = '',
  className = '',
}) => {
  const themeType = getCTACardTheme(themeSelector?.toLowerCase());

  const hasImage =
    themeType === CTA_CARD_THEMES.MULTILINK_IMAGE ||
    themeType === CTA_CARD_THEMES.TEXT_IMAGE;
  const hasIcon = themeType === CTA_CARD_THEMES.MULTILINK_ICON;
  const turnArrow =
    themeType === CTA_CARD_THEMES.TEXT_IMAGE ||
    themeType === CTA_CARD_THEMES.TEXT_ONLY;
  const titleOnly = themeType === CTA_CARD_THEMES.TITLE_ONLY;

  return (
    <ThemeProvider
      theme={{
        hasImage,
        turnArrow,
        titleOnly,
      }}
    >
      <Styled.CtaCard className={className}>
        {hasImage && <Styled.ImageContainer data-src={image?.url} />}
        <Styled.TitleContainer>
          {hasIcon && <Styled.IconContainer data-src={image?.url} />}
          {!titleOnly && (
            <Headline styling="h3" tag="h3" text={`__${title}__`} />
          )}
          <Styled.Description styling="P1">{description}</Styled.Description>
          {ctas && ctas.length > 0 && (
            <Styled.CtasContainer>
              {ctas.map((cta, index) => (
                <Styled.CtaContainer
                  {...getLinkProps(cta.url)}
                  key={kebabCase(`${cta.title}-${index}`)}
                  type="textLink"
                >
                  {(!turnArrow || titleOnly) && (
                    <Paragraph styling="P1">
                      {titleOnly ? title : cta.title}
                    </Paragraph>
                  )}
                  <Styled.Button type="roundRightArrow" />
                </Styled.CtaContainer>
              ))}
            </Styled.CtasContainer>
          )}
        </Styled.TitleContainer>
      </Styled.CtaCard>
    </ThemeProvider>
  );
};

CtaCard.propTypes = {
  /**
   * title of cta card
   */
  title: PropTypes.string,
  /**
   * description of cta card
   */
  description: PropTypes.string,
  /**
   * image object
   */
  image: PropTypes.objectOf(any),
  /**
   * list of CTAs
   */
  ctas: PropTypes.arrayOf(PropTypes.objectOf(any)),
  /**
   * theme selector
   */
  themeSelector: PropTypes.string,
  /**
   * classname to select styles
   */
  className: PropTypes.string,
};

export default CtaCard;
