import React, { useState, useRef, useEffect } from 'react';
import PropTypes, { any } from 'prop-types';

import { Markdown } from 'components/molecules';
import debounce from 'lodash/debounce';
import { ThemeProvider } from 'styled-components';
import * as Styled from './Accordion.styled';
import { getLinkProps } from '../../../utils/get-link-props';
import {
  eventTrack,
  LINK_LIST_CTA_CLICK,
} from '../../../utils/google-tag-manager';

const RESIZE_DEBOUNCE_DELAY = 250;
const onClickCta = event => eventTrack(LINK_LIST_CTA_CLICK, { event });

/**
 * Accordion component
 * @param {Array} entries list data
 * @param {string} themeSelector theme data
 * @param {boolean} isDark color selector
 * @returns {ReactElement}
 */
const Accordion = ({ entries, isDark = false, themeSelector }) => {
  const [activeEntry, setActiveEntry] = useState(-1);
  const [maxHeight, setMaxHeight] = useState(0);
  const containerRef = useRef(null);

  const isFullWidthTheme =
    themeSelector && themeSelector.toLowerCase() === 'full width';

  useEffect(() => {
    const onResize = debounce(() => {
      if (containerRef.current) {
        const descriptionElements = containerRef.current.querySelectorAll(
          '.markdown',
        );
        const marginBottom = 75;
        const tallestHeight =
          Math.max(
            ...Array.from(descriptionElements).map(
              el => el.getBoundingClientRect().height,
            ),
          ) + marginBottom;
        setMaxHeight(tallestHeight);
      }
    }, RESIZE_DEBOUNCE_DELAY);

    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      onResize.cancel();
      window.removeEventListener('resize', onResize);
    };
  }, [containerRef]);

  return (
    <ThemeProvider theme={{ isFullWidthTheme, isDark }}>
      <Styled.Root>
        <Styled.Container ref={containerRef}>
          <Styled.Entries>
            {entries.map((entry, index) => (
              <Styled.Entry key={entry.title}>
                <Styled.OpenButton
                  onClick={() =>
                    setActiveEntry(activeEntry === index ? -1 : index)
                  }
                >
                  <Styled.Title
                    active={activeEntry === index}
                    text={entry.title}
                  />
                </Styled.OpenButton>
                <Styled.Description
                  maxHeight={maxHeight}
                  active={activeEntry === index}
                  styling="P1"
                >
                  <div className="markdown">
                    <Markdown body={entry.description} />
                  </div>
                  {entry.cta && (
                    <Styled.Cta
                      type="secondary"
                      isDarkTheme
                      overrideFunctionality={entry.cta.overrideFunctionality}
                      {...getLinkProps(entry.cta.url)}
                      onClick={onClickCta}
                    >
                      <Styled.ButtonCta
                        type="roundRightArrow"
                        className="button-arrow"
                        {...getLinkProps(entry.cta.url)}
                        onClick={onClickCta}
                      />
                    </Styled.Cta>
                  )}
                </Styled.Description>
              </Styled.Entry>
            ))}
          </Styled.Entries>
        </Styled.Container>
      </Styled.Root>
    </ThemeProvider>
  );
};

Accordion.propTypes = {
  /**
   * Accordion list data
   */
  entries: PropTypes.arrayOf(any).isRequired,
  /**
   * Accordion color selector
   */
  isDark: PropTypes.bool,
  /**
   * Accordion theme key
   */
  themeSelector: PropTypes.string,
};

export default Accordion;
